import {FC, useCallback, useEffect, useRef, useState} from "react";
import * as echarts from 'echarts/core';
import {
  BarChart,
  BarSeriesOption
} from 'echarts/charts';
import {
  TooltipComponent,
  TooltipComponentOption,
  TitleComponent,
  TitleComponentOption,
  AxisPointerComponent,
  AxisPointerComponentOption,
  LegendComponent,
  LegendComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  GridComponent,
  GridComponentOption,
  LegendScrollComponent,
  MarkPointComponent,
  MarkPointComponentOption,
  GraphicComponent,
  GraphicComponentOption,
} from 'echarts/components';
import {
  SVGRenderer
} from 'echarts/renderers';

import styles from './reliability.module.less';

import {getOptions} from "./reliability.option";
import {EChartsType} from "echarts/types/dist/shared";
import ResizeObserver from 'resize-observer-polyfill';

export type ECOption = echarts.ComposeOption<TooltipComponentOption | BarSeriesOption | TitleComponentOption | GridComponentOption | AxisPointerComponentOption | LegendComponentOption | ToolboxComponentOption | MarkPointComponentOption | GraphicComponentOption>;

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer, AxisPointerComponent, LegendComponent, ToolboxComponent, LegendScrollComponent, MarkPointComponent, GraphicComponent]
);

export interface ReliabilityData {
  name: string,
  value: number,
}

interface ReliabilityProps {
  data: ReliabilityData[],
}

const Reliability: FC<ReliabilityProps> = (props) => {
  const chart = useRef<HTMLDivElement>(null);
  const resizeTimeout = useRef<NodeJS.Timeout | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const [myChart, setMyChart] = useState<EChartsType>();

  const {data} = props;

  const drawChart = useCallback(() => {
    const $chart = chart.current;
    if ($chart !== null) {
      let myChart = echarts.getInstanceByDom($chart);
      if (myChart === undefined) {
        echarts.dispose($chart);
        myChart = echarts.init($chart, undefined, {
          renderer: 'svg'
        });
      }

      setMyChart(myChart);

      const option = getOptions(data);
      myChart.setOption(option, true);
    }
  }, [JSON.stringify(data)]);

  const chartResize = useCallback(() => {
    if (resizeTimeout?.current !== null) {
      clearTimeout(resizeTimeout?.current);
    }
    resizeTimeout.current = setTimeout(() => {
      drawChart();
      const $chart = chart?.current;
      if (myChart !== undefined && $chart !== null) {
        myChart.resize();
      }
    }, 200);
  }, [drawChart, myChart])

  useEffect(() => {
    drawChart();
    const $chart = chart?.current;
    if ($chart !== null) {
      resizeObserver.current = new ResizeObserver(() => {
        chartResize();
      });
      resizeObserver.current?.observe($chart);
    }
    return () => {
      if (resizeTimeout?.current !== null) {
        clearTimeout(resizeTimeout?.current);
      }
      if ($chart !== null && resizeObserver.current !== null) {
        resizeObserver.current?.unobserve($chart);
      }
    };
  }, [drawChart, chartResize]);

  return <div className={styles.main} id={'bar-chart'} ref={chart}/>;
}

export default Reliability;
