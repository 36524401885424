import {
  BaseStatis,
  DeviceInfo,
  DeviceShowInfo,
  DeviceStateDistribute,
  NetGateInfo,
  NewShowNode,
  ShowDevice,
  ShowNode
} from "./device";

export interface ProjectInfo {
  intProjectId?: string,
  strPjName?: string,
  strCountry?: string,
  strProvince?: string,
  strCity?: string,
  strCollage?: string,
  strPosition?: string,
  strType?: string,
  strOwnerAddress?: string,
  strContacts?: string,
  strContactWay?: string,
  strInfoNote?: string,
  strImg?: string,
  strPositionForShow?: string,
  alarmTotal?: number,
  eventTotal?: number,
  strStatus?: string,
  lstProjectUnit?: ProjectUnitInfo[],
  lstNetGateInfo?: NetGateInfo,
  strActiveTime?: string,
  alarmToday?: number,
  eventToday?: number,
  deviceShowInfo?: DeviceShowInfo[],
  notQuoteDevList?: ShowDevice[],
  notQuoteUnitList?: ShowNode[],
  strDeviceShowInfo?: string,
  strNotQuoteDevInfo?: string,
  strNotQuoteUnitInfo?: string,
  docsList?: string,
  oldDocsList?: string,
  nodes?: NewShowNode[],
  pjImgList?: string[],
  pinyin?: string,
}

export interface ProjectUnitInfo {
  intPjUnitId?: number,
  strUnitName?: string,
  intDBPkPjinfoid?: string,
  strPjName?: string,
  strUnitNote?: string,
  lstNetGate?: NetGateInfo[],
  lstDevice?: DeviceInfo[],
}

export interface UserDistribute extends BaseStatis{
  strConstructorCount?: string,
  strExpertCount?: string,
  strOwnerCount?: string,
  strWokerCount?: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserDistribute = (obj: any): obj is UserDistribute => {
  return 'strConstructorCount' in obj && 'strExpertCount' in obj && 'strOwnerCount' in obj && 'strWokerCount' in obj;
}
