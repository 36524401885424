import {FC, useEffect, useMemo, useRef, useState} from 'react';

import styles from './key-params.module.less';
import {Button, Form, Input, message, Space} from "antd";
// @ts-ignore
import DetailConfig from '@daqo/react-detail-config-panel';
import {useParams} from "react-router-dom";
import {RouterParams} from "../../../pages/app/app.routers";
import Hashids from "hashids";
import {DictDetail} from "../../../common/entity/dict-detail";

import _ from 'lodash';
import Tools from "../../../utils/tools";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {RequestPath, sendRequest} from "../../../api/http";
import {ResultCode} from "../../../common/entity/result";
import {error} from "@daqo/log4js";
import {DynamicParams} from "../../../common/entity/detail";
import {DeviceInfo} from "../../../common/entity/device";

const hashids = new Hashids();

export interface ActiveDetail {
  detailId?: string,
  category?: string,
  deviceId?: string,
}

export interface KeyParamsProps {
  activeDetail: ActiveDetail[],
  setLoading: (showLoading: boolean) => void,
  cancelDraw: () => void,
  exitDraw: () => void,
  current?: DynamicParams,
  dev?: DeviceInfo,
  type: 'pj' | 'dev',
}

const KeyParams: FC<KeyParamsProps> = (props) => {
  const urlParams = useParams<RouterParams>();
  const formRef = useRef<FormInstance>(null);
  const [checkedDetail, setCheckedDetail] = useState<DictDetail>();

  const {type, dev, exitDraw, cancelDraw} = props;

  const stateDetailCheck = (detail: DictDetail) => {
    setCheckedDetail(detail);
  }

  useEffect(() => {
    if (props?.current !== undefined) {
      const {current} = props;
      const detail = {
        strKey: current?.intDetailId,
        deviceInfo: {
          strKey: current?.intSrcId,
        },
        strDetailCategory: current?.strCategory,
      };
      sendRequest<DictDetail>({
        url: `${RequestPath.GET_DETAILS}/${current?.intSrcId}/${current?.strCategory}/${current?.intDetailId}`,
        urlType: 'dict',
      }).then(result => {
        console.log(result);
        if (result?.code === 0 && result?.body !== undefined) {
          console.log(result.body);
          setCheckedDetail(result.body);
        } else {
          setCheckedDetail(detail);
        }
      }).catch(() => {
        setCheckedDetail(detail);
      })
    }
  }, [props.current])

  const detailConfigReady = () => {
    console.log('detail config ready');
  }

  const addDynamicParam = () => {
    if (formRef?.current !== null) {
      if ((type === 'pj' && urlParams?.projectId !== undefined) || (type === 'dev' && urlParams?.deviceId !== undefined)) {
        formRef?.current?.validateFields().then(() => {
          let targetId = hashids.decode(urlParams?.projectId ?? '').toString();
          if (type === 'dev') {
            targetId = hashids.decode(urlParams?.deviceId ?? '').toString();
          }
          if (checkedDetail === undefined) {
            message.warn('请设置一个监测点');
            return;
          }
          const name = formRef?.current?.getFieldValue('name');
          props?.setLoading(true);
          sendRequest<string>({
            url: `${props.current !== undefined ? RequestPath.UPDATE_DYNAMIC_PARAMS : RequestPath.ADD_DYNAMIC_PARAMS}`,
            contentType: 'application/json;charset=utf-8',
            method: 'POST',
            body: JSON.stringify({
              intParamId: props.current?.intParamId,
              intDetailId: checkedDetail?.strKey,
              intSrcId: checkedDetail?.deviceInfo?.strKey,
              intTargetId: targetId,
              strCategory: checkedDetail?.strDetailCategory,
              strTitle: name,
              strType: type === 'pj' ? 'pj' : 'show',
            })
          }).then(result => {
            props?.setLoading(false);
            if (result?.resCode === ResultCode.OK) {
              message.success('关键参数添加成功');
              exitDraw();
            } else {
              message.error('关键参数添加失败');
            }
          }).catch(err => {
            error(err);
            props?.setLoading(false);
          })
        }).catch(() => {});
      }
    }
  }

  const targetDevice = () => {
    if (type === 'dev' && dev !== undefined) {
      return new Promise(resolve => {
        resolve({
          ok: true,
          body: {
            code: 0,
            body: {
              totalCount: 1,
              pageNum: 1,
              pageCount: 10,
              resultData: [{
                strKey: dev?.intDeviceid,
                strNameInfo: dev?.strDeviceName,
              }],
            }
          }
        });
      });
    }
    return new Promise(resolve => {
      resolve({
        ok: true,
        body: {
          code: 0,
          body: {
            totalCount: 0,
            pageNum: 1,
            pageCount: 10,
            resultData: [],
          }
        }
      });
    });
  }

  const currentProjectId = useMemo(() => {
    return hashids.decode(urlParams?.projectId ?? '').toString();
  }, [urlParams.projectId])

  return <div className={styles.main}>
    <Form
      name="basic"
      initialValues={{
        remember: false,
        name: props?.current?.strTitle ?? '',
      }}
      autoComplete="off"
      style={{
        height: '100%',
      }}
      ref={formRef}
    >
      <div className={styles.main_content}>
        <Form.Item
          label="看板名称"
          name="name"
          rules={[{required: true, message: '请输入看板名称'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item>
          <DetailConfig categoryData={[
            {
              key: '10',
              name: '遥信',
            },
            {
              key: '20',
              name: '遥测',
            },
            {
              key: '30',
              name: '遥脉',
            },
            {
              key: '40',
              name: '定值',
            },
            {
              key: '50',
              name: '历史',
            },
            {
              key: '60',
              name: '计算',
            }
          ]} projectId={currentProjectId} onCheck={stateDetailCheck} theme={'light'} width={540} height={400}
                        fontSize={14}
                        cssModules={false} appType={'ORIGIN'}
                        activeDetails={props?.activeDetail?.filter(detail => detail?.detailId !== props?.current?.intDetailId || detail?.deviceId !== props?.current?.intSrcId || props?.current?.strCategory !== detail?.category) ?? []}
                        mounted={detailConfigReady}
                        getProjectDevices={type === 'pj' ? undefined : targetDevice}/>
        </Form.Item>
        <Form.Item>
          <div className={styles.main_monitor_title}>监测项</div>
          {
            checkedDetail !== undefined && <>
              <div className={styles.main_monitor_item}>{`来源设备：${(type === 'dev' && dev !== undefined ? dev?.strDeviceName : checkedDetail?.deviceInfo?.strNameInfo) ?? '--'}`}</div>
              <div
                className={styles.main_monitor_item}>{`数据类型：${_.head(Tools.categories.filter(category => (category.key + '') === checkedDetail?.strDetailCategory))?.name ?? '--'}`}</div>
              <div className={styles.main_monitor_item}>{`监测点：${checkedDetail?.strNameInfo ?? '--'}`}</div>
            </>
          }
        </Form.Item>
      </div>
      <div className={styles.main_btn}>
        <Form.Item noStyle={true}>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={addDynamicParam}>
              保存
            </Button>
            <Button onClick={cancelDraw}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  </div>
}

export default KeyParams;
