import io from 'socket.io-client';

export interface SocketState {
  conn: SocketIOClient.Socket,
  state: boolean,
}

const url = process.env.REACT_APP_WEBSOCKET;

const socket: SocketIOClient.Socket | null = url !== undefined ? io(url) : null;

socket?.on('disconnect', (reason: string) => {
  if (reason === 'io server disconnect') {
    socket?.connect();
  }
})

export default socket;
