import { ECOption } from "./line";
import Tools from "../../../../utils/tools";
import { LineData } from "./line.types";

export const getOption = (lineData: LineData[]): ECOption => (
  {
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#cccccc',
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        margin: Tools.ifMobile() ? Number(Tools.remRadio(12)) : 12,
        fontSize: Tools.ifMobile() ? Number(Tools.remRadio(12, false, 12)) : 12,
        color: '#999',
      },
      data: lineData.length > 0 ? lineData[0].data.map(d => d.time) : []
    },
    legend: {
      show: false,
    },
    grid: {
      containLabel: true,
      left: Number(Tools.remRadio(10)),
      right: Number(Tools.remRadio(10)),
      bottom: Number(Tools.remRadio(5)),
      top: Number(Tools.remRadio(15)),
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#666',
        fontSize: Number(Tools.remRadio(16, false, 12)),
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#999',
        fontSize: Tools.ifMobile() ? Number(Tools.remRadio(12, false, 12)) : 12,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: [5, 10],
          dashOffset: 0
        }
      },
    },
    tooltip: {
      show: false,
    },
    series: lineData.map((data) => ({
      name: data.name,
      data: data.data.map(d => d.value),
      type: 'line',
      areaStyle: {
        color: 'rgba(24, 144, 255, 0.3)'
      },
      smooth: true,
      connectNulls: true,
      showSymbol: false,
      lineStyle: {
        color: '#1890FF',
        width: 2,
      },
      animation: true,
      animationDuration: 1000,
      animationDurationUpdate: 0,
      animationEasing: 'cubicIn',
      animationEasingUpdate: 'quinticIn',
      animationDelayUpdate: 400,
      animationDelay: 400,
    }))
  }
);
