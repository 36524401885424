/* eslint-disable */
class Tools {
  static formatIcon(iconName: string, status: any | undefined) {
    if (status === undefined) {
      return iconName;
    }
    const icon = iconName.substring(0, iconName.lastIndexOf('.'));
    const {off = '0', on = '1'} = {
      off: '0',
      on: '1',
      ...status.desc,
    };
    if (status.value === undefined || status.value === off) {
      return icon + '_off' + iconName.substring(iconName.lastIndexOf('.'));
    }
    if (status.value !== undefined && status.value === on) {
      return icon + '_on' + iconName.substring(iconName.lastIndexOf('.'));
    }
    return icon + '_off' + iconName.substring(iconName.lastIndexOf('.'));
  }

  static getJson(json: string, key: string) {
    if (this.isJson(json) && JSON.parse(json)[key] !== undefined) {
      return JSON.parse(json)[key];
    }
    return key;
  }

  static defaultBackColor() {
    return '#ffffff';
  }

  static timesValue(value: string | undefined, times = 1000) {
    try {
      if (value === undefined || value === '-') {
        return value;
      }
      return parseFloat(value) * times;
    } catch (e) {
      return value;
    }
  }

  static getValue(value: string | undefined, defaultValue = '--') {
    if (value === undefined || value === '') {
      return defaultValue;
    }
    return value;
  }

  static dateFormat(date: Date, pattern: string, locate = 'en') {
    const format = new Map<string, () => string>();
    const defaultDate = `${date.getFullYear()}${locate === 'en' ? '-' : '年'}${((date.getMonth() + 1) + '').padStart(2, '0')}${locate === 'en' ? '-' : '月'}${(date.getDate() + '').padStart(2, '0')}${locate === 'cn' ? '日' : ''}`;
    format.set('yyyy', () => date.getFullYear() + '');
    format.set('yyyy-MM', () => date.getFullYear() + (locate === 'en' ? '-' : '年') + ((date.getMonth() + 1) + '').padStart(2, '0'));
    format.set('yyyy-MM-dd', () => defaultDate);
    format.set('yyyy-MM-dd HH', () => `${date.getFullYear()}${locate === 'en' ? '-' : '年'}${((date.getMonth() + 1) + '').padStart(2, '0')}${locate === 'en' ? '-' : '月'}${(date.getDate() + '').padStart(2, '0')}${locate === 'cn' ? '日' : ''} ${(date.getHours() + '').padStart(2, '0')}`);
    format.set('yyyy-MM-dd HH:mm', () => `${date.getFullYear()}${locate === 'en' ? '-' : '年'}${((date.getMonth() + 1) + '').padStart(2, '0')}${locate === 'en' ? '-' : '月'}${(date.getDate() + '').padStart(2, '0')}${locate === 'cn' ? '日' : ''} ${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}`);
    format.set('yyyy-MM-dd HH:mm:ss', () => `${date.getFullYear()}${locate === 'en' ? '-' : '年'}${((date.getMonth() + 1) + '').padStart(2, '0')}${locate === 'en' ? '-' : '月 '}${(date.getDate() + '').padStart(2, '0')}${locate === 'cn' ? '日' : ''} ${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}:${(date.getSeconds() + '').padStart(2, '0')}`);
    format.set('HH:mm', () => `${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}`);
    const func = format.get(pattern);
    if (func !== undefined) {
      return func();
    }
    return defaultDate;
  }

  static formatFilename(filename: string, filetype = 'zip') {
    if (filename !== undefined && filename !== null && filename.indexOf('.') > -1 && filename.indexOf('_') > -1) {
      return [
        `${filename.substring(0, filename.lastIndexOf('_'))}.${filetype}`,
        Tools.dateFormat(
          Tools.timestamp2Date(filename.substring(filename.lastIndexOf('_') + 1, filename.lastIndexOf('.'))),
          'yyyy-MM-dd HH:mm:ss',
        ),
      ];
    }
    return ['', ''];
  }

  static timestamp2Date(time: string | number) {
    const date = new Date();
    date.setTime(Number(time));
    return date;
  }

  static dateStart(date: Date | undefined, type: string) {
    if (date === undefined) {
      return -1;
    }
    const format = new Map<string, () => Date>();
    format.set('year', () => {
      date.setMonth(0);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    });
    format.set('month', () => {
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    });
    format.set('date', () => {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    });
    const call = format.get(type);
    if (call !== undefined) {
      return call().getTime();
    }
    return undefined;
  }

  static dateEnd(date: number | Date, type: string) {
    const dateStart = this.dateStart(this.addDate(date, 1, type), type);
    if (dateStart === undefined) {
      return undefined;
    }
    return dateStart - 1;
  }

  static addDate(d: number | Date, num: number, type = 'month') {
    const date = new Date(d);
    const format = new Map<string, () => Date>();
    format.set('year', () => {
      date.setFullYear(date.getFullYear() + num);
      return date;
    });
    format.set('month', () => {
      date.setMonth(date.getMonth() + num);
      return date;
    });
    format.set('date', () => {
      date.setDate(date.getDate() + num);
      return date;
    });
    format.set('hour', () => {
      date.setHours(date.getHours() + num);
      return date;
    });
    format.set('minute', () => {
      date.setMinutes(date.getMinutes() + num);
      return date;
    });
    format.set('second', () => {
      date.setSeconds(date.getSeconds() + num);
      return date;
    });
    const call = format.get(type);
    return call !== undefined ? call() : date;
  }

  static getMonthDates = (month: number | Date) => {
    const date = new Date(month);
    let dateStart = Tools.dateStart(date, 'month');
    const dateEnd = Tools.dateEnd(date, 'month');
    if (dateStart === undefined || dateEnd === undefined) {
      return undefined;
    }
    const result = [];
    while (dateStart <= dateEnd) {
      result.push(Tools.dateFormat(new Date(dateStart), 'yyyy-MM-dd'));
      dateStart = Tools.addDate(dateStart, 1, 'date').getTime();
    }
    return result;
  };

  static remRadio(px: string | number, ipad = false, min = 0) {
    let viewport = window.innerWidth;
    if (viewport <= (ipad ? 850 : 1366)) {
      viewport = (ipad ? 850 : 1366);
    }
    try {
      const val = (parseFloat(px + '') * (viewport / 1920));
      return (val < min ? min : val).toFixed(2);
    } catch (e) {
      return px;
    }
  }

  static pxRadio(rem: string) {
    let viewport = window.innerWidth;
    if (viewport <= 1280) {
      viewport = 1280;
    }
    try {
      return Math.ceil(viewport * 100 * parseFloat(rem) / 1920);
    } catch (e) {
      return rem;
    }
  }

  static colorList() {
    return ['#faad14', '#52c41a', '#fa541c', '#eb2f96', '#13c2c2', '#f5222d'];
  }

  static gradualColors() {
    return [
      ['#ffd666', '#ad6800'],
      ['#95de64', '#237804'],
      ['#ff9c6e', '#ad2102'],
      ['#ff85c0', '#9e1068'],
      ['#5cdbd3', '#006d75'],
      ['#ff7875', '#a8071a'],
    ];
  }

  static opacityColors() {
    return [
      ['#faad14', 'rgba(210,144,18,0.2)'],
      ['#52c41a', 'rgba(72,173,24,0.2)'],
      ['#fa541c', 'rgba(239,80,27,0.2)'],
      ['#eb2f96', 'rgba(219,44,140,0.2)'],
      ['#13c2c2', 'rgba(18,180,180,0.2)'],
      ['#f5222d', 'rgba(229,32,42,0.2)'],
    ];
  }

  static formatJson(text: string, defaultObj?: any) {
    try {
      return JSON.parse(text);
    } catch (e) {
      return defaultObj ?? text;
    }
  }

  static formatDetailTip(id: string | number | undefined, name: string | undefined) {
    if (id === undefined || name === undefined) {
      return '';
    }
    return `${id}:${name}`;
  }

  static emptyNumber(t: undefined | string, value = 0) {
    if (t === undefined || t === null || t === '' || t === 'NaN') {
      return value;
    }
    return parseFloat(t);
  }

  static fixNumber(text: undefined | string, fixed = 2) {
    if (text !== undefined && text !== null && text !== '' && /^\d+(\.\d*)?$/.test(text)) {
      return parseFloat(text).toFixed(fixed);
    }
    return text;
  }

  static numberAccuracy(text: string | number | undefined, accuracy = 2) {
    if (text === undefined || text === null || text === '') {
      return '--';
    }
    if (/^(-)?\d+(\.\d*)?$/.test(text + '')) {
      return (Math.floor(Number(text) * Math.pow(10, accuracy)) / (Math.pow(10, accuracy))).toString().replace(/(\.0+)$/, '');
    }
    return text;
  }

  static ifMobile() {
    const {
      userAgent: ua,
    } = navigator;
    return ua.toLowerCase().indexOf('ipad') > -1 || !!ua.toLowerCase().match(/applewebkit.*mobile.*/) || !!ua.toLowerCase().match(/\(i[^;]+;( u;)? cpu.+mac os x/) || ua.toLowerCase().indexOf('android') > -1 || ua.toLowerCase().indexOf('adr') > -1 || ua.toLowerCase().indexOf('iphone') > -1;
  }

  static first(array: undefined | Array<any>, defaultValue: any = {}) {
    return array && array.length > 0 ? array[0] : defaultValue;
  }

  static emptyObj(obj: any, defaultValue = {}) {
    return obj !== undefined && obj !== null ? obj : defaultValue;
  }

  static randomString(L: number) {
    let s = '';
    const randomchar = () => {
      const n = Math.floor(Math.random() * 62);
      if (n < 10) return n;
      if (n < 36) return String.fromCharCode(n + 55);
      return String.fromCharCode(n + 61);
    };
    while (s.length < L) s += randomchar();
    return s;
  }

  static addSalt(code: string) {
    const random = this.randomString(10);
    const mapping = {
      0: [1, 4, 5, 8],
      1: [2, 6, 7, 9],
    };
    const filter = mapping[random.substring(0, 1).toLowerCase() === random.substring(1, 2) ? 0 : 1];
    return random.split('').map((r, index) => {
      const ind = filter.indexOf(index);
      if (ind > -1) {
        return code.substring(ind, ind + 1);
      }
      return r;
    }).join('');
  }

  static removeSalt(random: string) {
    const mapping = {
      0: [1, 4, 5, 8],
      1: [2, 6, 7, 9],
    };
    const filter = mapping[random.substring(0, 1).toLowerCase() === random.substring(1, 2) ? 0 : 1];
    return random.split('').filter((r, index) => filter.indexOf(index) > -1).join('');
  }

  static isJson(text: string) {
    try {
      JSON.parse(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  static isObject(value: any) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
  }

  static isNumber(value: any) {
    return /^(-)?\d+(\.\d*)?$/.test(value);
  }

  static categoryMapper(category: any) {
    return this.categories.filter(item => item.key === category)[0].name;
  }

  static renderReportValue = (value?: string | number | null, defaultVal = '--') => {
    if (value === undefined || value === null) {
      return defaultVal;
    }
    if (/^(-)?\d+(\.\d*)?$/.test(value + '')) {
      return (Math.floor(Number(value) * Math.pow(10, 2)) / (Math.pow(10, 2))).toString().replace(/(\.0+)$/, '');
    }
    return value;
  }

  static dom2string (dom: HTMLElement | null) {
    if (dom === null) {
      return '';
    }
    let tmpNode: null | HTMLDivElement = document.createElement( "div" );
    tmpNode.appendChild( dom.cloneNode( true ) );
    const str = tmpNode.innerHTML;
    tmpNode = dom = null;
    return str;
  }

  static abs(value: number | string | undefined) {
    if (value !== undefined && /^(-)?\d+(\.\d*)?$/.test(value + '')) {
      return Math.abs(Number(value));
    }
    return value;
  }

  static categories = [
    {
      key: '10',
      name: '遥信',
    },
    {
      key: '20',
      name: '遥测',
    },
    {
      key: '30',
      name: '遥脉',
    },
    {
      key: '40',
      name: '定值',
    },
    {
      key: '50',
      name: '历史',
    },
    {
      key: '60',
      name: '计算',
    },
    {
      key: '70',
      name: '遥控',
    },
    {
      key: '80',
      name: '遥调',
    },
    {
      key: '100',
      name: '报警',
    },
    {
      key: '110',
      name: '事件',
    },
  ];

  static positionNumberPattern = /^\d+$/;

  static idPattern = /^(-)?\d+$/;

  static renderConstValue(value: string | number | undefined | null, unit?: string) {
    if (value === undefined || value === null || value === 'null' || Number.isNaN(value) || value === 'NaN' || value === '') {
      return '--'
    }
    return this.numberAccuracy(value + '') + (unit ?? '');
  }
}

export default Tools;
