import {FC} from 'react';

import styles from './scroll-num.module.less';

interface ScrollNumProps {
  number: string,
}

const ScrollNum: FC<ScrollNumProps> = (props) => {
  const {number} = props;

  // @ts-ignore
  const { nums } = { nums: [...number] };

  const dom = nums.map((item, index) => {
    const i = index;
    const subDom = Array.of(...(item.padStart(Number.parseInt(item, 10) + 1, '0123456789'))).map((it, ind) => <div className={styles.numValue} key={it}>{ind}</div>);
    return <div className={styles[`numItem${item}`]} key={i}>{subDom}</div>;
  });

  return (
    <div className={styles.main}>
      {dom}
    </div>
  );
}

export default ScrollNum;
