import React from "react";
import {enc, MD5} from "crypto-js";
import {Button, Form, FormInstance, Input, message, Select, Tabs} from 'antd';

import styles from './login.module.less';
import areaList from './area.json';
import {Area} from "../../common/entity/area";
import {RequestPath, sendRequest} from "../../api/http";
import {LoginParamInfo} from "../../common/entity/user";
import {Result} from "../../common/entity/result";
import logger from "@daqo/log4js";
import {ConstantKey, phonePattern} from "../../utils/constant";
import {ResultCode} from "../../common/entity/result";

import socket from "../socket/socket";

const {TabPane} = Tabs;

interface LoginProps {
  setLoading: (showLoading: boolean) => void,
}

interface LoginState {
  tab: '1' | '2',
  codeSendTimeout: number,
  loginValue: LoginValue,
}

export interface LoginValue {
  phone: string,
  code: string,
  account: string,
  password: string,
}

class Login extends React.Component<LoginProps, LoginState> {
  codeTimeoutInterval?: NodeJS.Timeout;
  formRef = React.createRef<FormInstance<LoginValue>>();

  constructor(props: LoginProps) {
    super(props);
    this.state = {
      tab: '2',
      codeSendTimeout: 0,
      loginValue: {
        phone: '',
        code: '',
        account: '',
        password: '',
      },
    };
  }

  componentDidMount() {
    const preCodeSendTime = localStorage.getItem(ConstantKey.RE_LOGIN_TIMEOUT_NAME);
    const now = new Date().getTime();
    if (preCodeSendTime !== null && /^\d+$/.test(preCodeSendTime) && (now - Number(preCodeSendTime)) < 120 * 1000) {
      this.setState({
        codeSendTimeout: parseInt((120 - ((now - Number(preCodeSendTime)) / 1000)) + ''),
      }, () => {
        this.codeSending();
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<LoginProps>, prevState: Readonly<LoginState>) {
    if (prevState.codeSendTimeout !== this.state.codeSendTimeout) {
      const {codeSendTimeout} = this.state;
      if (codeSendTimeout === 0 && this.codeTimeoutInterval !== undefined) {
        clearInterval(this.codeTimeoutInterval);
      }
    }
  }

  componentWillUnmount() {
    if (this.codeTimeoutInterval !== undefined) {
      clearInterval(this.codeTimeoutInterval);
    }
  }

  sendCode = () => {
    const {setLoading} = this.props;
    const form = this.formRef.current;
    if (form === null) {
      message.error('form 表单未初始化');
      return;
    }
    const loginValue = form.getFieldsValue();
    if (loginValue.phone === '' || !phonePattern.test(loginValue.phone)) {
      message.warn('请输入正确格式的手机号');
      return;
    }
    const now = new Date().getTime();
    localStorage.setItem(ConstantKey.RE_LOGIN_TIMEOUT_NAME, now + '');
    setLoading(true);
    sendRequest<boolean>({
      url: `${RequestPath.SEND_V_CODE}?number=${loginValue.phone}`,
      method: 'GET',
      needToken: false,
    }).then((result: Result<boolean>) => {
      setLoading(false);
      if (result.resCode === ResultCode.OK) {
        message.success('验证码已发送，请您查收');
        this.setState({
          codeSendTimeout: 120,
        }, () => {
          this.codeSending();
        })
      } else {
        message.error('验证码发送失败，请您联系系统管理员');
      }
    }).catch(err => {
      logger.error(err);
      setLoading(false);
      message.error('验证码发送失败，请您联系系统管理员');
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>, number: boolean) => {
    const {name, value} = {
      name: event.target !== undefined ? event.target.name : '',
      value: event.target !== undefined ? (event.target.type === 'checkbox' ? event.target.checked : event.target.value) : '',
    };
    if (!number || value === '' || (!isNaN(Number(value)) && /^\d+$/.test(value + ''))) {
      // @ts-ignore
      this.setState({
        [name]: value,
      });
    }
  };

  codeSending = () => {
    if (this.codeTimeoutInterval !== undefined) {
      clearInterval(this.codeTimeoutInterval);
    }
    this.codeTimeoutInterval = setInterval(() => {
      this.setState((prevState) => ({
        codeSendTimeout: prevState.codeSendTimeout > 0 ? prevState.codeSendTimeout - 1 : 0,
      }));
    }, 1000);
  }

  login = () => {
    const {setLoading} = this.props;
    const form = this.formRef.current;
    if (form === null) {
      message.error('form 表单未初始化');
      return;
    }
    form.validateFields().then(() => {
      const loginValue = form.getFieldsValue();
      const {tab} = this.state;
      if (tab === '1') {
        const formData = new FormData();
        formData.set('phoneNumber', loginValue.phone);
        formData.set('vcode', loginValue.code);
        setLoading(true);
        sendRequest<LoginParamInfo>({
          url: RequestPath.PHONE_LOGIN,
          method: 'POST',
          body: formData,
          needToken: false,
        }).then((result: Result<LoginParamInfo>) => {
          setLoading(false);
          if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
            message.success('登录成功');
            localStorage.setItem(ConstantKey.USER, JSON.stringify(result.resBody));
            localStorage.removeItem(ConstantKey.RE_LOGIN_TIMEOUT_NAME);
            window.location.reload();
          } else {
            message.error('登录失败，请检查用户名密码是否正确');
          }
        }).catch(err => {
          setLoading(false);
          logger.error(err);
          message.error('登录失败，请您联系系统管理员');
        });
      } else if (tab === '2') {
        const time = new Date().getTime();
        const pwd = enc.Base64.stringify(MD5(enc.Base64.stringify(MD5(loginValue.password)) + time));
        const formData = new FormData();
        formData.set('username', loginValue.account);
        formData.set('password', pwd);
        formData.set('speed', time + '');
        setLoading(true);
        sendRequest<LoginParamInfo>({
          url: RequestPath.LOGIN,
          method: 'POST',
          body: formData,
          needToken: false,
        }).then((result: Result<LoginParamInfo>) => {
          setLoading(false);
          if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
            message.success('登录成功');
            localStorage.setItem(ConstantKey.USER, JSON.stringify(result.resBody));
            if (socket !== null && socket?.connected && (result.resBody?.userInfo?.pjList?.length ?? 0) > 0) {
              socket?.emit('projects', result.resBody?.userInfo?.pjList?.map(pj => pj?.intProjectId).join(','));
            }
            window.location.reload();
          } else {
            message.error('登录失败，请检查用户名密码是否正确');
          }
        }).catch(err => {
          setLoading(false);
          logger.error(err);
          message.error('登录失败，请您联系系统管理员');
        });
      }
    }).catch(() => {

    });
  }

  setTab = (activeKey: string) => {
    if (['1', '2'].indexOf(activeKey) > -1) {
      // @ts-ignore
      this.setState({tab: activeKey,})
    }
  }

  render() {
    const {tab, codeSendTimeout, loginValue} = this.state;
    return <div className={styles.main}>
      <div className={styles.login}>
        <div className={styles.title}>重新登录</div>
        <Form layout={'vertical'} initialValues={loginValue} onValuesChange={() => {
          return false;
        }} ref={this.formRef}>
          <div className={styles.panel}>
            <Tabs defaultActiveKey={tab} centered size={'large'} onChange={this.setTab}>
              {/*<TabPane tab="手机登录" key="1">
                <div className={styles.loginInput}>
                  <div className={styles.inputItem}>
                    {
                      areaList.length > 0 &&
                      <Select className={styles.select} bordered={false} defaultValue={areaList[0].code}
                              style={{width: 120}} options={areaList.map((area: Area) => ({
                        label: area.code,
                        value: area.code,
                      }))}>
                      </Select>
                    }
                    <Form.Item
                      rules={tab === '1' ? [{required: true, message: '请输入手机号'}, {message: '请输入正确格式的手机号', pattern: phonePattern}] : []}
                      name={'phone'}>
                      <Input className={styles.input} bordered={false} type={'text'}
                             placeholder={'请输入手机号'} name={'phone'}/>
                    </Form.Item>
                  </div>
                  <div className={styles.inputItem}>
                    <div className={styles.inputDesc}>验证码</div>
                    <Form.Item rules={[{required: tab === '1', message: '请输入验证码'}]} name={'code'}>
                      <Input className={styles.input} bordered={false} type={'text'} placeholder={'请输入验证码'}
                             name={'code'}/>
                    </Form.Item>
                    <div className={`${styles.sendCode} ${codeSendTimeout > 0 ? styles.sending : ''}`}
                         onClick={codeSendTimeout > 0 ? undefined : this.sendCode}>{
                      codeSendTimeout > 0 ? '已发送' : '获取验证码'
                    }</div>
                  </div>
                  <div className={styles.tips} style={{
                    visibility: codeSendTimeout > 0 ? 'visible' : 'hidden'
                  }}>{`${codeSendTimeout}后重新发送`}</div>
                </div>
              </TabPane>*/}
              <TabPane tab="" key="2">
                <div className={styles.loginInput}>
                  <Form.Item rules={[{required: tab === '2', message: '请输入账号/手机号'}]} name={'account'}>
                    <Input className={styles.input} bordered={false} type={'text'} placeholder={'请输入账号/手机号'}
                           name={'account'}/>
                  </Form.Item>
                  <Form.Item rules={[{required: tab === '2', message: '请输入密码'}]} name={'password'}>
                    <Input.Password className={styles.input} bordered={false} type={'password'} placeholder={'请输入密码'}
                                    name={'password'}/>
                  </Form.Item>
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Form.Item>
            <Button className={styles.btn} htmlType={'submit'} onClick={this.login}>登录</Button>
          </Form.Item>
        </Form>
      </div>
    </div>;
  }
}

export default Login;
