import React from 'react';
import styles from './loading.module.less';

interface TopBarProps {
  show: boolean,
}

interface TopBarState {
  width: number,
}

class Loading extends React.Component<TopBarProps, TopBarState> {
  constructor(props: TopBarProps) {
    super(props);
    this.state = {
      width: -1,
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any
  componentDidUpdate(prevProps: Readonly<TopBarProps>, prevState: Readonly<TopBarState>, snapshot?: any) {
    if (prevProps.show !== this.props.show && this.props.show) {
      const rate = Math.random() * 0.3 + 0.7;
      this.setState({
        width: 20 * rate,
      }, () => {
        this.sleep(250).then(() => {
          if (this.props.show) {
            this.setState({
              width: 50 * rate,
            }, () => {
              this.sleep(250).then(() => {
                if (this.props.show) {
                  this.setState({
                    width: 75 * rate,
                  });
                }
              })
            });
          }
        });
      });
    } else if (prevProps.show !== this.props.show && !this.props.show) {
      const {show} = this.props;
      this.sleep(100).then(() => {
        if (!show) {
          this.setState({
            width: 100,
          }, () => {
            this.sleep(400).then(() => {
              if (!show) {
                this.setState({
                  width: -1,
                })
              }
            });
          });
        }
      });
    }
  }

  sleep = (timeout: number): Promise<string> => {
    return new Promise(resolve => setTimeout(() => {resolve('ok')}, timeout))
  }

  render(): JSX.Element {
    const {width} = this.state;
    return <div className={styles.main}>
      {
        width !== -1 && <div className={styles.status} style={{
          width: `${width}%`,
        }}/>
      }
    </div>;
  }
}

export default Loading;
