import {ECOption} from "./bar";
import {BarData, BarDataGroup} from "./bar.types";
import _ from 'lodash';
import Tools from "../../../../utils/tools";

const colors: string[] = [
  '#FF7E00',
  '#FF2A00',
  '#0099FF',
  '#C100FF',
]

export const getOptions = (barData: BarData[], width: number): ECOption => {
  const max = (_.max(barData.filter(data => Tools.isNumber(data?.value)).map(data => Number(data.value))) ?? 0) * 1.5;
  return {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: false,
    },
    grid: {
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      containLabel: false
    },
    xAxis: {
      show: false,
      type: 'value',
      max,
    },
    yAxis: {
      show: false,
      type: 'category',
    },
    graphic: {
      elements: barData?.flatMap((data, index) => ([
        {
          type: 'rect',
          z: 100,
          left: Math.ceil(Number(data?.value) * width / max) + 5,
          top: (35 - 10) + (index) * 70 - 3,
          shape: {
            width: 5,
            height: 6
          },
          style: {
            fill: colors[index % 4],
            lineWidth: 0,
          }
        },
        {
          type: 'rect',
          z: 100,
          left: Math.ceil(Number(data?.value) * width / max) + 5,
          top: (35 + 10) + (index) * 70 - 3,
          shape: {
            width: 5,
            height: 6
          },
          style: {
            fill: colors[index % 4],
            lineWidth: 0,
          }
        }
      ])),
    },
    series: [{
      type: 'bar',
      barWidth: 14,
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(180, 180, 180, 0.2)',
      },
      label: {
        show: true,
        position: [10, -22],
        formatter: (params) => {
          const data = _.head(barData.filter(data => data.time === params.name));
          return `{name|${params.name}}{space| }{divider| }{space| }{value|${data?.value ?? '--'}}{textSpace| }{value|${data?.unit ?? ''}}{centerSpace| }`;
        },
        rich: {
          left: {
            width: '50%',
          },
          name: {
            color: 'rgba(0, 0, 0, 0.65)',
            fontWeight: 'bold',
            fontSize: 14,
          },
          space: {
            width: 20,
          },
          divider: {
            width: 1,
            height: 16,
            backgroundColor: '#E9E9E9',
          },
          value: {
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.43)',
          },
          textSpace: {
            width: 2,
          },
          centerSpace: {
            width: '10%',
          },
          radio: {
            align: 'right',
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.43)',
            width: '50%',
          }
        }
      },
      data: barData.map((data, index) => ({
        name: data.time,
        value: data?.value,
        itemStyle: {
          color: colors[index % 4],
        },
      })).reverse()
    }, {
      type: 'bar',
      barWidth: 14,
      barGap: '-100%',
      label: {
        show: true,
        position: [width - 60, -22],
        formatter: (params) => {
          const data = _.head(barData.filter(data => data.time === params.name));
          const total = _.sum(barData.map(data => data.value));
          return `{radio|${Tools.numberAccuracy((Number(data?.value ?? 0) / total) * 100, 0)}%}`;
        },
        rich: {
          radio: {
            align: 'right',
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.43)',
          }
        }
      },
      data: barData.map((data) => ({
        name: data.time,
        value: 0,
      }))
    }],
  };
}

export const getOptions_1 = (dataGroups: BarDataGroup[]) => {
  console.log(new Set(dataGroups?.flatMap(data => data.data)?.map(data => data.time)));
  return {
    color: ['#40AEFF', '#24c157'],
    xAxis: {
      type: 'category',
      show: true,
      data: Array.from(new Set(dataGroups?.flatMap(data => data.data)?.map(data => data.time))),
      axisLabel: {
        show: true,
      }
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
    },
    grid: {
      containLabel: true,
      left: Number(Tools.remRadio(20)),
      right: Number(Tools.remRadio(20)),
      bottom: Number(Tools.remRadio(30)),
      top: Number(Tools.remRadio(20)),
    },
    legend: {
      show: true,
      type: 'scroll',
      top: 'bottom',
      icon: 'circle',
    },
    series: dataGroups.map(group => (
      {
        name: group?.name ?? '',
        data: group.data.map(data => ({
          name: data.time,
          value: data?.value
        })),
        type: 'bar',
        showBackground: true,
        backgroundStyle: {
          color: 'rgba(180, 180, 180, 0.2)'
        }
      }
    ))
  };
}
