import {FC, useEffect, useMemo, useRef, useState} from 'react';

import styles from './compare-setter.module.less';
import {Button, DatePicker, Form, Input, message, Radio, Space, Tooltip} from "antd";
import {CheckOutlined, DeleteOutlined} from '@ant-design/icons';

// @ts-ignore
import DetailConfig from '@daqo/react-detail-config-panel';
import {useParams} from "react-router-dom";
import {RouterParams} from "../../../pages/app/app.routers";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {DictDetail} from "../../../common/entity/dict-detail";
import Hashids from "hashids";
import {RequestPath, sendRequest} from "../../../api/http";
import {DateType, HistoryCompareReport} from "../../../common/entity/report";
import moment, {Moment} from "moment";
import _ from "lodash";
import Tools from "../../../utils/tools";
import {ResultCode} from "../../../common/entity/result";
import {error} from "@daqo/log4js";
import {DeviceInfo} from "../../../common/entity/device";

const hashids = new Hashids();

export interface ReportSetterProps {
  cancelDraw: () => void,
  exitDraw: () => void,
  setLoading: (showLoading: boolean) => void,
  current?: HistoryCompareReport,
  dev?: DeviceInfo,
  type: 'dev' | 'pj',
}

const splitTypeMapper: {[key: string]: string} = {
  [DateType.YEAR]: 'month',
  [DateType.MONTH]: 'day',
  [DateType.DIY]: 'day',
  [DateType.DAY]: 'day',
}

const dateFormatterMapper: {[key: string]: string} = {
  [DateType.YEAR]: 'YYYY',
  [DateType.MONTH]: 'YYYY-MM',
  [DateType.DIY]: 'YYYY-MM-DD',
  [DateType.DAY]: 'YYYY-MM-DD',
}

const dateAddMapper: {[key: string]: 'y' | 'M' | 'd'} = {
  [DateType.YEAR]: 'y',
  [DateType.MONTH]: 'M',
  [DateType.DIY]: 'd',
  [DateType.DAY]: 'd',
}

const datePickerMapper: {[key: string]: 'year' | 'month' | 'date'} = {
  [DateType.YEAR]: 'year',
  [DateType.MONTH]: 'month',
  [DateType.DIY]: 'date',
  [DateType.DAY]: 'date',
}

export enum SplitType {
  ALL = 'all',
  INTEGRAL = 'integral',
}

interface ReportDetail extends DictDetail {
  customName?: string,
}

enum ReportType {
  YOY = 'yoy',
  MOM = 'mom',
  CUSTOM = 'custom',
}

const CompareSetter: FC<ReportSetterProps> = (props) => {
  const urlParams = useParams<RouterParams>();
  const formRef = useRef<FormInstance>(null);
  const [checkedDetail, setCheckedDetail] = useState<DictDetail>();
  const [dateType, setDateType] = useState<DateType>(DateType.YEAR);
  const [reportType, setReportType] = useState<ReportType>(ReportType.YOY);
  const [splitType, setSplitType] = useState<SplitType | string>(SplitType.INTEGRAL);
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>();
  const [dateAppender, setDateAppender] = useState<Moment | null>(null);
  const [dateGroup, setDateGroup] = useState<Moment[]>([]);
  const {setLoading, type, dev, cancelDraw, exitDraw} = props;

  const stateDetailCheck = (detail: DictDetail) => {
    setCheckedDetail(detail);
  }

  const addReport = () => {
    if (formRef?.current !== null) {
      if (urlParams?.projectId !== undefined) {
        if (dateType === DateType.DIY && (dateRange?.indexOf(null) ?? 0) > -1) {
          message.warn('自定义统计周期时，需要配置起止时间范围');
          return;
        }
        if (checkedDetail === undefined) {
          message.warn('请至少选择一个监测点');
          return;
        }
        formRef?.current?.validateFields().then(() => {
          const projectId = hashids.decode(urlParams?.projectId ?? '').toString();
          const name = formRef?.current?.getFieldValue('name');
          const reportType = formRef?.current?.getFieldValue('reportType');
          const dateType = formRef?.current?.getFieldValue('dateType');
          const compareDate: Moment = formRef?.current?.getFieldValue('compareDate');
          let dates = '';
          if (reportType === ReportType.YOY) {
            dates = `${moment(compareDate).add(-1, 'y').format(dateFormatterMapper[dateType])},${compareDate.format(dateFormatterMapper[dateType])}`;
          } else if (reportType === ReportType.MOM) {
            dates = `${moment(compareDate).add(-1, dateAddMapper[dateType]).format(dateFormatterMapper[dateType])},${compareDate.format(dateFormatterMapper[dateType])}`;
          } else if (reportType === ReportType.CUSTOM && formatDates !== undefined && formatDates !== null) {
            dates = formatDates;
          }
          const report: HistoryCompareReport = {
            strCustomName: name,
            strCustomType: dateType,
            strProjectId: projectId,
            strReportType: 'Custom',
            strDeviceId: checkedDetail?.deviceInfo?.strKey,
            strCategory: checkedDetail?.strDetailCategory,
            strDataoffset: checkedDetail?.strKey,
            strDimension: checkedDetail?.strDim,
            strCustomDateLst: dates,
            strShowDeviceId: type === 'dev' ? dev?.intDeviceid : undefined,
          }
          setLoading(true);
          sendRequest<string>({
            url: RequestPath.ADD_HISTORY_COMPARE_REPORT,
            method: 'POST',
            contentType: 'application/json;charset=utf-8',
            body: JSON.stringify(report),
          }).then(result => {
            setLoading(false);
            if (result?.resCode === ResultCode?.OK) {
              message.success('历史对比报表添加成功');
              exitDraw();
            } else {
              message.error('历史对比报表添加失败');
            }
          }).catch(err => {
            setLoading(false);
            error(err);
          });
        }).catch();
      }
    }
  }

  const addDateAppend = () => {
    if (dateAppender !== null && dateGroup?.map(date => date.format(dateFormatterMapper[dateType])).filter(date => date === dateAppender?.format(dateFormatterMapper[dateType])).length === 0) {
      setDateGroup(prevState => [
        ...(prevState ?? []),
        dateAppender,
      ])
    }
  }

  useEffect(() => {
    setDateGroup([]);
  }, [dateType])

  const removeDateAppend = () => {
    if (dateGroup.length > 0) {
      setDateGroup(dateGroup?.sort((a, b) => a.valueOf() - b.valueOf())?.filter((a, i) => i !== dateGroup.length - 1));
    }
  }

  const renderDates = (dates?: string | null) => {
    if ((dates ?? '') === '') {
      return '--';
    }
    return dates;
  }

  const targetDevice = () => {
    if (type === 'dev' && dev !== undefined) {
      return new Promise(resolve => {
        resolve({
          ok: true,
          body: {
            code: 0,
            body: {
              totalCount: 1,
              pageNum: 1,
              pageCount: 10,
              resultData: [{
                strKey: dev?.intDeviceid,
                strNameInfo: dev?.strDeviceName,
              }]
            }
          }
        });
      });
    }
    return new Promise(resolve => {
      resolve({
        ok: true,
        body: {
          code: 0,
          body: {
            totalCount: 0,
            pageNum: 1,
            pageCount: 10,
            resultData: [],
          }
        }
      });
    });
  }

  const formatDates = useMemo(() => {
    return renderDates(Array.from(new Set(dateGroup?.sort((a, b) => a.valueOf() - b.valueOf())?.map(date => date.format(dateFormatterMapper[dateType])))).join(','));
  }, [dateGroup])

  const currentProjectId = useMemo(() => {
    return hashids.decode(urlParams?.projectId ?? '').toString();
  }, [urlParams.projectId])

  return <div className={styles.main}>
    <Form
      name="basic"
      initialValues={{
        remember: false,
        name: props?.current?.strCustomName ?? '',
        dateType: props?.current?.strReportType ?? DateType.YEAR,
        reportType,
      }}
      autoComplete="off"
      style={{
        height: '100%',
      }}
      ref={formRef}
    >
      <div className={styles.main_content}>
        <Form.Item
          label="报表名称"
          name="name"
          rules={[{required: true, message: '请输入报表名称'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="报表类型"
          name="reportType"
          rules={[{required: true, message: '请选择报表类型 '}]}
        >
          <Radio.Group value={reportType} onChange={(e) => setReportType(e.target.value)}>
            <Radio.Button value={ReportType.YOY}>同比</Radio.Button>
            <Radio.Button value={ReportType.MOM}>环比</Radio.Button>
            <Radio.Button value={ReportType.CUSTOM}>自定义</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="对比周期"
          name="dateType"
          rules={[{required: true, message: '请选择对比周期'}]}
        >
          <Radio.Group value={dateType} onChange={(e) => setDateType(e.target.value)}>
            <Radio.Button value={DateType.YEAR}>年</Radio.Button>
            <Radio.Button value={DateType.MONTH}>月</Radio.Button>
            <Radio.Button value={DateType.DAY}>日</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {
          (reportType === ReportType.YOY || reportType === ReportType.MOM) && <Form.Item
              label={'对比时间'}
              name={'compareDate'}
              rules={[{required: true, message: '请设置对比时间'}]}
          >
            <DatePicker picker={datePickerMapper[dateType] ?? 'date'}/>
          </Form.Item>
        }
        {
          (reportType === ReportType.CUSTOM) && <Form.Item
            label={'对比时间'}
            name={'appendDate'}
            rules={[() => ({
              validator() {
                if ((formatDates ?? '--') !== '--' && dateGroup.length > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('请设置对比时间'));
              },
            })]}
          >
            <div className={styles.dates_append}>
              <div className={styles.dates_append_date_picker}>
                <DatePicker picker={datePickerMapper[dateType] ?? 'date'} value={dateAppender} onChange={(date) => setDateAppender(date)}/>
                <Button type="primary" shape="circle" icon={<CheckOutlined/>} disabled={dateAppender === null} size={'small'} className={styles.checked_btn} onClick={addDateAppend}/>
                <Button type="default" shape="circle" icon={<DeleteOutlined/>} disabled={dateGroup.length === 0} size={'small'} className={styles.checked_btn} onClick={removeDateAppend}/>
              </div>
              <Tooltip title={formatDates ?? '--'} mouseEnterDelay={0.4}>
                <div className={styles.dates_append_dates}>{
                  formatDates ?? '--'
                }</div>
              </Tooltip>
            </div>
          </Form.Item>
        }
        <Form.Item>
          <DetailConfig categoryData={[
            {
              key: '10',
              name: '遥信',
            },
            {
              key: '20',
              name: '遥测',
            },
            {
              key: '30',
              name: '遥脉',
            },
            {
              key: '40',
              name: '定值',
            },
            {
              key: '50',
              name: '历史',
            },
            {
              key: '60',
              name: '计算',
            }
          ]} projectId={currentProjectId} onCheck={stateDetailCheck} theme={'light'} width={540} height={400}
                        fontSize={14}
                        cssModules={false} appType={'ORIGIN'} getProjectDevices={type === 'pj' ? undefined : targetDevice}/>
        </Form.Item>
        <Form.Item>
          <div className={styles.main_monitor_title}>监测项</div>
          {
            checkedDetail !== undefined && <>
              <div className={styles.main_monitor_item}>{`来源设备：${checkedDetail?.deviceInfo?.strNameInfo ?? '--'}`}</div>
              <div
                  className={styles.main_monitor_item}>{`数据类型：${_.head(Tools.categories.filter(category => (category.key + '') === checkedDetail?.strDetailCategory))?.name ?? '--'}`}</div>
              <div className={styles.main_monitor_item}>{`监测点：${checkedDetail?.strNameInfo ?? '--'}`}</div>
            </>
          }
        </Form.Item>
      </div>
      <div className={styles.main_btn}>
        <Form.Item noStyle={true}>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={addReport}>
              保存
            </Button>
            <Button onClick={cancelDraw}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  </div>
}

export default CompareSetter;
