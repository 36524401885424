export enum ConstantKey {
  USER = 'ck.cloud.user',
  LOGIN_TIMEOUT_NAME = 'ck.login-phone-code-time',
  FORGET_TIMEOUT_NAME = 'ck.forget-code-time',
  RE_LOGIN_TIMEOUT_NAME = 'ck.re-login-phone-code-time',
  USER_PWD_STORE = 'ck.user.auth',
  USER_PWD_STORE_AES_KEY = 'yYdcDNhCixEjB9hI',
}

export const OSS_PJ = `https://daqo-${process.env.REACT_APP_OSS_ENV}-pj.${process.env.REACT_APP_OSS_ENDPOINT}/`;

export const OSS_DEV = `https://daqo-${process.env.REACT_APP_OSS_ENV}-dev.${process.env.REACT_APP_OSS_ENDPOINT}/`;

export const phonePattern = /^1\d{10}$/;

export const pwdPattern = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}/;
