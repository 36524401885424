import {FC, useEffect, useRef, useState} from "react";

import styles from './system-param.module.less';
import {Button, Form, Input, message, Space} from "antd";
import {useParams} from "react-router-dom";
import {RouterParams} from "../../../pages/app/app.routers";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {AnalysisBoard, VirtualDetail} from "../../../common/entity/detail";
import {RequestPath, sendRequest} from "../../../api/http";
import {PageData, ResultCode} from "../../../common/entity/result";
import Hashids from "hashids";
import {DeviceParamInfo} from "../../../common/entity/report";

interface SystemParamProps {
  cancelDraw: () => void,
  exitDraw: () => void,
  current?: AnalysisBoard,
  total: AnalysisBoard[],
  type: 'pj' | 'dev',
}

const hashids = new Hashids();

const SystemParam: FC<SystemParamProps> = (props) => {
  const urlParams = useParams<RouterParams>();
  const formRef = useRef<FormInstance>(null);
  const [virtualDetails, setVirtualDetails] = useState<VirtualDetail[]>([]);
  const [currentDetail, setCurrentDetail] = useState<VirtualDetail | null>(null);
  const {cancelDraw, current, type, total, exitDraw} = props;

  useEffect(() => {
    let url = '';
    if (type === 'pj') {
      url = `${RequestPath.GET_VIRTUAL_LIST_FOR_PROJECT}?pageNum=1&pageCount=100`;
    } else if (type === 'dev' && urlParams?.deviceId) {
      const deviceId = hashids.decode(urlParams.deviceId);
      url = `${RequestPath.GET_DEVICE_MONITOR_PARAMS}?pageNum=1&pageCount=100&deviceid=${deviceId}&category=v`;
    }
    sendRequest<PageData<VirtualDetail | DeviceParamInfo>>({
      url,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        if (type === 'pj') {
          // @ts-ignore
          setVirtualDetails(result?.resBody?.resultData ?? []);
        } else if (type === 'dev') {
          // @ts-ignore
          setVirtualDetails(result?.resBody?.resultData?.map((param: DeviceParamInfo) => ({
            intPkId: Number(param?.strMongoDbOffset),
            strName: param?.strTitle,
          })) ?? []);
        }
      } else {
        message.error('无法获取系统监测点，请联系系统管理员');
      }
    }).catch(() => {
    })
  }, [urlParams?.deviceId, type])

  useEffect(() => {
    setCurrentDetail({
      intPkId: Number(current?.intDetailId),
    })
  }, [current?.intDetailId])

  const save = () => {
    if (formRef.current !== null) {
      formRef?.current.validateFields().then(() => {
        if (formRef.current !== null) {
          const name = formRef.current.getFieldValue('name');
          const boards = total?.map(board => {
            if (board?.intBoardId === current?.intBoardId) {
              board.intDetailId = currentDetail?.intPkId + '';
              board.strTitle = name;
            }
            return board;
          });
          sendRequest<string>({
            url: RequestPath.UPDATE_ANALYSIS_BOARD,
            method: 'POST',
            contentType: 'application/json;charset=utf-8',
            body: JSON.stringify(boards),
          }).then(result => {
            if (result?.resCode === ResultCode.OK) {
              message.success('概况信息更新成功');
              exitDraw();
            } else {
              message.error('概况信息更新失败，请联系系统管理员');
            }
          }).catch(() => {})
        }
      }).catch(() => {
      })
    }

  }

  return <div className={styles.main}>
    <Form
      name="basic"
      initialValues={{
        remember: false,
        name: current?.strTitle ?? '',
      }}
      autoComplete="off"
      style={{
        height: '100%',
      }}
      ref={formRef}
      layout={'vertical'}
    >
      <div className={styles.main_content}>
        <Form.Item
          label="看板名称"
          name="name"
          rules={[{required: true, message: '请输入看板名称'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item label={'监测点'} required={true}>
          <div className={styles.details}>{
            virtualDetails?.map((detail, index) => (
              <div
                className={`${styles.details_item} ${(currentDetail?.intPkId === detail?.intPkId) ? styles.details_active : ''}`}
                key={`${detail?.intPkId}_${index}`} onClick={() => setCurrentDetail(detail)}>{detail?.strName}</div>
            ))
          }</div>
        </Form.Item>
      </div>
      <div className={styles.main_btn}>
        <Form.Item noStyle={true}>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={save}>
              保存
            </Button>
            <Button onClick={cancelDraw}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  </div>
}

export default SystemParam;
