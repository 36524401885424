import {FC, useEffect, useState} from 'react';

import styles from './chart-panel.module.less';
import {Divider} from "antd";
import LinePanel from "../charts/line/line";
import BarPanel from "../charts/bar/bar";
import {DeviceParamInfo, TimeDataSet} from "../../../common/entity/report";
import _ from "lodash";
import {LineData} from "../charts/line/line.types";
import {BarDataGroup} from "../charts/bar/bar.types";

export interface ChartPanelProps {
  reportData: TimeDataSet[],
  deviceParams: DeviceParamInfo[],
}

const ChartPanel: FC<ChartPanelProps> = (props) => {
  const {reportData, deviceParams} = props;
  const [chartData, setChartData] = useState<LineData[] | BarDataGroup[]>([]);

  useEffect(() => {
    if (reportData.length > 0 && deviceParams.length > 0) {
      setChartData(deviceParams?.map(param => {
        return {
          name: param?.strCustomName ?? '--',
          data: reportData?.map(data => ({
            time: data?.dateTime ?? '',
            value: _.head(data?.lstValue?.filter(d => d?.strDeviceId === param?.intDeviceid && d?.strEventTypeName === param?.intKindId && d?.strDetailId === param?.strMongoDbOffset))?.strValue ?? '',
          }))
        }
      }))
    }
  }, [reportData, deviceParams])

  return <div className={styles.main}>
    <div className={`${styles.main_chart}`}>
      <LinePanel lineData={chartData}/>
    </div>
    <Divider/>
    <div className={`${styles.main_chart}`}>
      <BarPanel barData={[]} multiBarData={chartData}/>
    </div>
  </div>
};

export default ChartPanel;
