import {bindActionCreators, Dispatch} from 'redux';
import {connect, RootStateOrAny} from 'react-redux';
import App from "../../pages/app/app";
import loginActions from '../../redux/login/login.action';
import {setLoading} from '../../redux/loading/loading.action';

const mapStateToProps = (state: RootStateOrAny) => ({
  showLogin: state.login.showLogin,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLogin: loginActions.setLogin,
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
