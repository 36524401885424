import {bindActionCreators, Dispatch} from 'redux';
import {connect, RootStateOrAny} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import CompareSetter from "../../component/report/history-compare/compare-setter";

const mapStateToProps = (state: RootStateOrAny) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareSetter);
