import {ECOption} from "./pie";
import * as echarts from 'echarts/core';
import Tools from "../../../../utils/tools";

const orders = new Map<string, number>();
orders.set('电源故障', 0);
orders.set('线路故障', 1);
orders.set('设备故障', 2);
orders.set('电能质量异常', 3);

const getOption = (normalCount: number,errorCount: number,startAngle: number, type: 0 | 1): ECOption => {
  return (
    {
      tooltip: {
        trigger: 'item',
        show: false
        // formatter: "{b}<br/>{c}<br/>{d}%"
      },
      legend: {
        show: false
      },
      toolbox: {
        show: true
      },
      calculable: true,
      series: [{
        name: '',
        type: 'pie',
        radius: ['40%', '68%'],
        selectedMode: 'single',
        selectedOffset: 0,
        animation: true,
        label: {
          show: true,
          fontSize: Tools.remRadio(14),
          rich: {
            box1: {
              backgroundColor: {
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAMAAAD3n0w0AAAAe1BMVEUAAAAAAAAACAsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAs/4Al/oAmPcAjPMAuP4AiuwAtfsAf9YAsPYAY60ARncAsPIAZKUAouYAK0kABAcAouAAiL8AsPwAsPwAo/8Asv8AsP8Arv8Aq/8AqP8Atv8Anf8Alv8Amv9tCeJMAAAAH3RSTlMABQskEhoyRkE4LPT06urm39a/rJp4dHRZV01JJOjmKoMdEgAAAKFJREFUGNN9ykcWgjAUQFFSSei9q1hQ979C/48RkolveM8LfhEo8CO0y/OOIh/XXG5QOR83WerNVi/E2hCve/FAjNHT6nSmBI1fvTglgOLmJRA5u3sxbvDhZfHi9Q/Tp1NqsU/ee0lvkAo5Vi9bNUpBETnTYVsgFW2oGQfElUk1NVnWTEoygfbVSKswVDqyZpQDSwnE0awiQ0BoDkNIfo58AApVGXQXwapEAAAAAElFTkSuQmCC'
              },
              width: 15,
              height: 15
            },
            box2: {
              backgroundColor: {
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAMAAAD3n0w0AAAAclBMVEUAAAAAAAAAAAAAAAALCgAAAAAAAAAAAAAAAAAAAAAAAAAAAAD+6AD62wD32QDz0gD85QD+6QDszQDWuQD24QCtlQB3ZwDy3gClkADm0gBJPwAHBgDgzgC/rwD75gD75gD/5AD/5wD/6QD/3wD/4QD/3QBpbe+eAAAAIHRSTlMABCQRCxYbMkZBOCz09Orq5+bfv6yaeHR0WVdNSSTW1a2FPJMAAACeSURBVBjTlc5XEsIwDEVR5Lg7vVcgAfa/RSxsiP3J/Tyj0bzLN7Bd4oAOVTVQCAnW62G7rec1bO3hazfwNqXHr3QC967cg0oKaGyPYlYh4THyBJHco8i/+IzymD+Cco9jdlo2OuRibl6+ZhYckTKiZV8j1b3UBMd/VKilK4puUcKZU6OVlEobZ04TTowQhvAEzatlxjmzhBawDSkukDfEuhk2xrRvCAAAAABJRU5ErkJggg=='
              },
              width: 15,
              height: 15
            },
            text: {
              fontSize: Tools.remRadio(14),
              color: "#ffe500"
            },
            textWhite: {
              fontSize: Tools.remRadio(14),
              color: "#00acff",
              fontWeight: 'normal'
            },
            item: {
              height: 15,
              width: 18
            },
            blank: {
              height: 15,
              width: 3
            }
          }
        },
        startAngle: startAngle,
        data: [{
          value: errorCount,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#ffee00'
            }, {
              offset: 1,
              color: '#ffe800'
            }]),
          },
          label: {
            color: '#ffe500',
            formatter: function (params) {
              return '{item| }{text|' + params.name + '\n}{box2|}{blank| }{text|' + params.value + '台\n}{item| }{text|' + (params.percent == 100 || params.percent == 0 ? params.percent : params?.percent?.toFixed(1) ?? '--') + '%}'
            }
          },
          name: type === 0 ? '异常' : '未处理'
        }, {
          value: normalCount,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: '#00c7ff'
            }, {
              offset: 1,
              color: '#007aff'
            }]),
          },
          label: {
            color: '#00acff',
            formatter: function (params) {
              return '{textWhite|' + params.name + '}{item| \n}{textWhite|' + params.value + '台}{blank| }{box1|}\n{textWhite|' + (params.percent == 100 || params.percent == 0 ? params.percent : params?.percent?.toFixed(1) ?? '--') + '%}{item| }'
            }
          },
          name: type === 0 ? '正常' : '已处理'
        }]
      }
      ]
    }
  );
};

export default getOption;
