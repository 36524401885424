import {bindActionCreators, Dispatch} from 'redux';
import {connect, RootStateOrAny} from 'react-redux';
import Loading from "../../component/loading/loading";

const mapStateToProps = (state: RootStateOrAny) => ({
  show: state.loading.showLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Loading);
