import ReactDOM from 'react-dom';
import './index.css';
import './iconfont.css';
import App from '../../container/app/app.container';
import {Route, Switch, HashRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Tools from "../../utils/tools";
import store from '../../redux/store/store-creator';
import logger, {debug} from '@daqo/log4js';
import {Provider} from "react-redux";

import {ConfigProvider} from 'antd';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import Login from "../../container/login/login.container";
import Modules from "../../container/modules/modules.container";
import Cockpit from "../../container/cockpit/cockpit.container";

logger.level(0);

if (Tools.ifMobile()) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <HashRouter>
        <Switch>
          <Route component={App} path={['/pj/:projectId/:menu', '/dev/:projectId/:nodeId/:deviceId/:menu', '/monitor']}/>
          <Route component={Login} exact path={['/login', '/']}/>
          <Route component={Modules} exact path={['/modules']}/>
          <Route component={Cockpit} exact path={['/cockpit']}/>
        </Switch>
      </HashRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(debug);
