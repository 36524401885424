import { ECOption } from "./line";
import Tools from "../../../../utils/tools";
import { LineData } from "./line.types";
import _ from 'lodash';
import {CallbackDataParams} from "echarts/types/dist/shared";

export const getOption = (lineData: LineData[]): ECOption => (
  {
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: '#cccccc',
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        margin: Tools.ifMobile() ? Number(Tools.remRadio(12)) : 12,
        fontSize: Tools.ifMobile() ? Number(Tools.remRadio(12, false, 12)) : 12,
        color: '#999',
      },
      data: lineData.length > 0 ? lineData[0].data.map(d => d.time) : []
    },
    legend: {
      show: true,
      top: 'bottom',
      icon: 'circle',
      type: 'scroll',
    },
    grid: {
      containLabel: true,
      left: Number(Tools.remRadio(20)),
      right: Number(Tools.remRadio(20)),
      bottom: Number(Tools.remRadio(30)),
      top: Number(Tools.remRadio(20)),
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#666',
        fontSize: Number(Tools.remRadio(16, false, 12)),
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#999',
        fontSize: Tools.ifMobile() ? Number(Tools.remRadio(12, false, 12)) : 12,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: [5, 10],
          dashOffset: 0
        }
      },
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        lineStyle: {
          color: 'rgba(96,96,96,0.4)',
        },
      },
      textStyle: {
        fontSize: Tools.ifMobile() ? Number(Tools.remRadio(12, false, 12)) : 12,
      },
      formatter: (params: CallbackDataParams | CallbackDataParams[]) => {
        if (Array.isArray(params)) {
          const value = params.map((param: CallbackDataParams) => `${param.seriesName}: ${param.value}${_.head(lineData.filter(data => data.name === param.seriesName))?.unit ?? ''}`)
          return `${params[0].name}<br>${value.join('<br>')}`;
        }
        return `${params.name}: ${params.value}`;
      }
    },
    series: lineData.map((data, index) => ({
      name: data.name,
      data: data.data.map(d => d.value),
      type: 'line',
      smooth: true,
      connectNulls: true,
      showSymbol: false,
      lineStyle: {
        width: 2,
        shadowColor: 'rgba(148,212,236,0.2)',
        shadowOffsetY: Number(Tools.remRadio(8)),
      },
      itemStyle: {
        borderWidth: 2,
      },
      animation: true,
      animationDuration: 1000,
      animationDurationUpdate: 0,
      animationEasing: 'cubicIn',
      animationEasingUpdate: 'quinticIn',
      animationDelayUpdate: 400,
      animationDelay: 400,
    }))
  }
);
