import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  useHistory,
  useLocation,
  useParams,
  withRouter
} from "react-router-dom";
import {routerDecide, routerNames, RouterParams, Routers} from './app.routers';
import Login from '../../container/login/relogin.container';

import styles from './app.module.less';
import Loading from "../../container/loading/loading.container";
import {ConstantKey} from "../../utils/constant";
import {NoAuth} from "../../component/no-auth/no-auth";
import {Input, Menu, message, Tooltip, Tree, notification} from "antd";
import Hashids from 'hashids';

import {HomeOutlined} from '@ant-design/icons';
import DataBoard from '../databoard';
import Alarm from '../alarm';
import Scada from '../scada';
import CheckUp from "../checkup";
import MaintLog from "../checkup/maintLog";



import socket from "../../component/socket/socket";

import {RequestPath, sendRequest} from "../../api/http";
import {ProjectInfo} from "../../common/entity/project";
import {ResultCode} from "../../common/entity/result";
import {DataNode, Key} from "rc-tree/lib/interface";
import {error} from "@daqo/log4js";
import {DeviceInfo, NewShowNode} from "../../common/entity/device";
import GeneralInfoProject from "../../container/general-info/general-info.project.container";
import GeneralInfoDevice from "../../container/general-info/general-info.device.container";
import Video from "../../container/video/video-panel.container";
import {MenuInfo} from "rc-menu/lib/interface";
import RealtimeReportProject from "../../container/report/realtime-report.project.container";
import HistoryCompareProject from "../../container/report/history-compare.project.container";
import {isUserToken, LoginParamInfo} from "../../common/entity/user";
import _ from 'lodash';
import RealtimeReportDevice from "../../container/report/realtime-report.device.container";
import HistoryCompareDevice from "../../container/report/history-compare.device.container";
import {EventInfo} from "../../common/entity/detail";
import moment from "moment";

interface AppProps extends RouteComponentProps<RouterParams> {
  showLogin: boolean,
  setLogin: (showLogin: boolean) => void,
  setLoading: (showLoading: boolean) => void,
}

const {Search} = Input;
const {SubMenu} = Menu;
const hashids = new Hashids();

const App: FC<AppProps> = (props) => {
  const routerParams = useParams<RouterParams>();
  const history = useHistory();
  const location = useLocation();
  const [projects, setProjects] = useState<ProjectInfo[]>([]);
  const [device, setDevice] = useState<DeviceInfo>();
  const [menuData, setMenuData] = useState<DataNode[]>();
  const [projectSearch, setProjectSearch] = useState<string>('');

  const {setLoading} = props;

  const goSubMenu = (key: string) => {
    if (location?.pathname?.startsWith('/pj')) {
      if (routerParams?.projectId !== undefined) {
        history.push(`/pj/${routerParams.projectId}/${key}`);
      }
    } else if (location?.pathname?.startsWith('/dev')) {
      if (routerParams?.projectId !== undefined && routerParams?.deviceId !== undefined && routerParams.nodeId !== undefined) {
        history.push(`/dev/${routerParams.projectId}/${routerParams.nodeId}/${routerParams.deviceId}/${key}`);
      }
    }
  }

  const getDeviceStructure = useCallback(() => {
    setLoading(true);
    sendRequest<ProjectInfo[]>({
      url: `${RequestPath.GET_DEVICE_STRUCTURE}?type=display`,
    }).then(result => {
      setLoading(false);
      if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
        setProjects(result?.resBody?.map(project => {
          project.pinyin = pinyinUtil.getPinyin(project?.strPjName ?? '').replace(/ /g, '');
          return project;
        }));
      } else {
        message.error('无法获取到工程设备列表，请联系系统管理员');
      }
    }).catch(err => {
      setLoading(false);
      message.error('无法获取到工程设备列表，请联系系统管理员');
      error(err);
    })
  }, []);

  const formatDevices = (nodes: NewShowNode[], result: NewShowNode[], parentId: string) => {
    if (nodes.filter(node => node?.strNodeId === parentId).length > 0) {
      nodes.filter(node => node?.strNodeId === parentId)[0].nodes = result;
      return nodes;
    }
    for (let i = 0; i < nodes.length; i++) {
      const formatted = formatDevices(nodes[i].nodes ?? [], result, parentId);
      if (JSON.stringify(nodes[i].nodes) !== JSON.stringify(formatted)) {
        nodes[i].nodes = formatted;
        return nodes;
      }
    }
    return nodes;
  }

  const getDevicesByParent = (key: string) => {
    let parent = '0';
    let projectId = key;
    if (key.toString().indexOf(',') > -1) {
      [projectId, parent] = key.toString().split(',');
    }
    return new Promise<void>(resolve => {
      sendRequest<NewShowNode[]>({
        url: `${RequestPath.LIST_NODES_BY_PARENT}?projectId=${projectId}&parent=${parent}`,
      }).then((result) => {
        if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
          setProjects(projects.map(project => {
            if (project.intProjectId === projectId) {
              project.nodes = (parent === '0' ? result.resBody ?? [] : formatDevices(project?.nodes ?? [], result.resBody ?? [], parent));
            }
            return project;
          }));
          resolve();
        } else {
          message.error('无法获取到设备列表，请联系系统管理员');
        }
      }).catch(err => error(err));
    })
  }

  useEffect(() => {
    getDeviceStructure();
  }, [getDeviceStructure]);

  const formatNodes = useCallback((nodes: NewShowNode[], projectId: string): DataNode[] => {
    return nodes?.map(node => ({
      key: `${projectId},${node?.strNodeId ?? '--'},${node?.strDeviceId ?? '--'}`,
      title: <Tooltip title={node?.strNodeName ?? '--'} mouseEnterDelay={0.5}
                      className={styles.treeItem}>{node?.strNodeName ?? '--'}</Tooltip>,
      isLeaf: !(node?.haveNext ?? true),
      selectable: node?.strDeviceId !== undefined,
      children: formatNodes(node?.nodes ?? [], projectId),
    }))
  }, []);

  useEffect(() => {
    setMenuData(projects?.filter(project => (project?.intProjectId?.indexOf(projectSearch) ?? -1) > -1 || (project?.strPjName?.indexOf(projectSearch) ?? -1) > -1 || (project?.pinyin?.indexOf(projectSearch) ?? -1) > -1).map(project => ({
      key: project.intProjectId ?? '--',
      title: <Tooltip title={project?.strPjName ?? '--'} mouseEnterDelay={0.5}
                      className={`${styles.treeItem} ${styles.pjName}`}>{project?.strPjName ?? '--'}</Tooltip>,
      isLeaf: false,
      children: formatNodes(project?.nodes ?? [], project.intProjectId ?? '--'),
    })));
    if (routerParams?.projectId === undefined && projects.length > 0) {
      setTimeout(() => {
        history.push(`/pj/${hashids.encode(projects[0]?.intProjectId ?? '')}/${Routers.BASE_INTO}`);
      }, 1000);
    }
  }, [projects, formatNodes, projectSearch]);

  const checkProjectDevice = (selectedKeys: Key[]) => {
    if (selectedKeys.length > 0) {
      const key = selectedKeys[0] + '';
      const keys = key.split(',');
      if (key.indexOf(',') > -1 && keys.length === 3) {
        const [projectId, nodeId, deviceId] = keys;
        const menu = routerParams?.menu ?? Routers.BASE_INTO;
        history.push(`/dev/${hashids.encode(projectId)}/${hashids.encode(nodeId)}/${hashids.encode(deviceId)}/${routerDecide['dev'].indexOf(menu) > -1 ? menu : Routers.BASE_INTO}`);
      } else {
        const menu = routerParams?.menu ?? Routers.BASE_INTO;
        history.push(`/pj/${hashids.encode(key)}/${routerDecide['pj'].indexOf(menu) > -1 ? menu : Routers.BASE_INTO}`);
      }
    }
  }

  const expandMenu = (expandedKeys: Key[]) => {
    console.log(expandedKeys);
  }

  const selectKey = useMemo(() => {
    if (location.pathname.startsWith('/pj') && routerParams.projectId !== undefined) {
      return hashids.decode(routerParams.projectId).toString();
    } else if (location.pathname.startsWith('/dev') && routerParams.projectId !== undefined && routerParams.nodeId !== undefined && routerParams.deviceId !== undefined) {
      return `${hashids.decode(routerParams.projectId)},${hashids.decode(routerParams.nodeId)},${hashids.decode(routerParams.deviceId)}`;
    }
    return '';
  }, [routerParams.projectId, routerParams.deviceId, routerParams.nodeId, location.pathname]);

  const searchProject = (value: string) => {
    setProjectSearch(value);
  }

  const user = localStorage.getItem(ConstantKey.USER);
  if (user === null) {
    props?.setLogin(true);
  }

  const currentUser: LoginParamInfo | null = useMemo(() => {
    if (user !== null) {
      const userToken = JSON.parse(user);
      if (isUserToken(userToken)) {
        return userToken;
      }
      return null;
    }
    return null;
  }, [user]);

  const currentProject = useMemo(() => {
    if (routerParams.projectId !== undefined && projects.length > 0) {
      const projectId = hashids.decode(routerParams.projectId).toString();
      return _.head(projects.filter(pj => pj.intProjectId === projectId)) ?? null;
    }
    return null;
  }, [projects, routerParams.projectId])

  const currentDevice = useCallback(() => {
    if (routerParams?.deviceId !== undefined) {
      const deviceId = hashids.decode(routerParams.deviceId);
      sendRequest<DeviceInfo>({
        url: `${RequestPath.GET_DEVICE_INFO}?deviceId=${deviceId}`
      }).then(result => {
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          setDevice(result.resBody);
        }
      }).catch(() => {
      })
    }
  }, [
    routerParams.deviceId
  ])

  useEffect(() => {
    currentDevice();
  }, [currentDevice])

  useEffect(() => {
    socket?.on('connect', () => {
      if (currentUser !== null && (currentUser?.userInfo?.pjList?.length ?? 0) > 0) {
        socket?.emit('projects', currentUser?.userInfo?.pjList?.map(pj => pj.intProjectId).join(','));
      }
    });
  }, [currentUser]);

  const openNotificationWithIcon = (type: 'info' | 'warning', title?: string, content?: string, time?: string, device?: string) => {
    notification[type]({
      message: title ?? '--',
      description: <div className={styles.event}>
        <div className={styles.content}><Tooltip title={content ?? '--'} mouseEnterDelay={0.2}>{content ?? '--'}</Tooltip></div>
        <div className={styles.time}><Tooltip title={time ?? '--'} mouseEnterDelay={0.2}>{time ?? '--'}</Tooltip></div>
        <div className={styles.device}><Tooltip title={device ?? '--'} mouseEnterDelay={0.2}>{device ?? '--'}</Tooltip></div>
      </div>,
    });
  };

  const getValueFromData = (elem: EventInfo) => {
    try {
      if (typeof (JSON.parse(elem?.strEventContent ?? '')) == "object")
        return JSON.parse(elem?.strEventContent ?? '')[elem?.strEventValue ?? ''];
      else
        return elem.strEventContent;
    } catch (e) {
      return elem.strEventContent;
    }
  };

  const showEvent = (event: string | null) => {
    console.log(event);
    if (event !== null) {
      try {
        const eventInfo: EventInfo = JSON.parse(event);
        let time = eventInfo?.strOccurrenceTime ?? '--';
        if (/\d+/.test(time)) {
          time = moment(new Date(Number(time))).format('YYYY-MM-DD HH:mm:ss');
        }
        openNotificationWithIcon(
          eventInfo?.strEventKingId === '100' ? 'warning' : 'info',
          eventInfo?.strEventName,
          getValueFromData(eventInfo) ?? '--',
          time,
          `${eventInfo?.strProjectName ?? '--'}/${eventInfo?.strDeviceName ?? '--'}`)
      } catch (e) {
        error(e);
      }
    }
  }

  useEffect(() => {
    socket?.on('event', showEvent)
    return () => {
      socket?.off('event', showEvent);
    }
  }, []);

  const currentMenu = useMemo(() => {
    if (routerParams.menu === undefined) {
      return '--';
    }
    return routerNames[routerParams.menu] ?? '--';
  }, [routerParams.menu]);

  return (
    <div className={styles.app}>
      {
        user !== null ? <div className={styles.app_content}>
          <div className={styles.header}>
            <div className={styles.header_title}>
              <i className={`daqo-iconfont daqo-icon-xunhuan ${styles.icon}`}/>
              <span className={styles.text}>配电运维管理</span>
            </div>
            <div className={styles.header_banner}>
              <div className={styles.routers}>
                {routerParams?.menu === Routers.BASE_INTO ? (location?.pathname?.startsWith('/dev') ? '设备' : (location?.pathname?.startsWith('/pj') ? '工程' : '')) : ''}
                {currentMenu} | {currentProject?.strPjName ?? '--'}
                {location?.pathname?.startsWith('/dev') && device !== undefined ? ` | ${device?.strDeviceName ?? '--'}` : ''}
              </div>
              <Link to={'/modules'}>
                <div className={styles.account}><HomeOutlined style={{fontSize: '0.28rem'}}/></div>
              </Link>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.body_menus}>
              <div className={styles.devices}>
                <div className={styles.devices_search}>
                  <Search className={styles.searchPanel} placeholder="搜索工程" onSearch={(value) => searchProject(value)}/>
                </div>
                <div className={styles.devices_items}>
                  <Tree
                    onExpand={expandMenu}
                    blockNode={true}
                    showLine={{
                      showLeafIcon: false,
                    }}
                    className={styles.font_size_14}
                    showIcon={true}
                    selectedKeys={[selectKey]}
                    treeData={menuData}
                    loadData={(node) => getDevicesByParent(node?.key + '')}
                    onSelect={checkProjectDevice}
                  />
                </div>
              </div>
              <Link to={'/cockpit'}>
                <div className={styles.cockpit}>
                  <i className={`daqo-iconfont daqo-icon-zhinanzhen ${styles.cockpit_icon}`}/>
                  <div className={styles.cockpit_text}>进入驾驶仓</div>
                </div>
              </Link>
            </div>
            <div className={styles.body_content}>
              <div className={styles.subMenu}>
                <Menu onClick={(info: MenuInfo) => goSubMenu(info.key)} className={`${styles.subMenu_menu_group} ${styles.font_size_14}`}
                      selectedKeys={[routerParams.menu ?? Routers.BASE_INTO]} mode="horizontal">
                  {
                    location?.pathname?.startsWith('/pj') && <Menu.Item key={Routers.BASE_INTO}>
                      工程概况
                    </Menu.Item>
                  }
                  {
                    location?.pathname?.startsWith('/dev') && <Menu.Item key={Routers.BASE_INTO}>
                      设备概况
                    </Menu.Item>
                  }
                  <Menu.Item key={Routers.DATA_BOARD}>
                    数据看板
                  </Menu.Item>
                  {
                    location?.pathname?.startsWith('/pj') && <Menu.Item key={Routers.VIDEO}>
                      视频监控
                    </Menu.Item>
                  }
                  <SubMenu key="SubMenu" title="报表曲线">
                    <Menu.Item key={Routers.REAL_REPORT} className={styles.subMenu_menu_item}>实时报表曲线</Menu.Item>
                    <Menu.Item key={Routers.COMPARE} className={styles.subMenu_menu_item}>历史比较</Menu.Item>
                  </SubMenu>
                  {
                    location?.pathname?.startsWith('/pj') && <Menu.Item key={Routers.SCADA}>
                      组态界面
                    </Menu.Item>
                  }
                  <Menu.Item key={Routers.ALARM}>
                    报警事件
                  </Menu.Item>
                  {
                    location?.pathname?.startsWith('/dev') && <Menu.Item key={Routers.HEALTH}>
                      健康诊断
                    </Menu.Item>
                  }
                </Menu>
              </div>
              <div className={styles.contentPanel}>
                <Switch>
                  <Route render={() => <GeneralInfoProject/>} path={`/pj/:projectId/${Routers.BASE_INTO}`}/>
                  <Route render={() => <GeneralInfoDevice/>}
                         path={`/dev/:projectId/:nodeId/:deviceId/${Routers.BASE_INTO}`}/>
                  <Route render={() => <Video/>} path={`/pj/:projectId/${Routers.VIDEO}`}/>
                  <Route render={() => <RealtimeReportProject/>}
                         path={[`/pj/:projectId/${Routers.REAL_REPORT}/:reportId`, `/pj/:projectId/${Routers.REAL_REPORT}`]}/>
                  <Route render={() => <HistoryCompareProject/>}
                         path={[`/pj/:projectId/${Routers.COMPARE}/:reportId`, `/pj/:projectId/${Routers.COMPARE}`]}/>
                  <Route render={() => <RealtimeReportDevice device={device}/>}
                         path={[`/dev/:projectId/:nodeId/:deviceId/${Routers.REAL_REPORT}/:reportId`, `/dev/:projectId/:nodeId/:deviceId/${Routers.REAL_REPORT}`]}/>
                  <Route render={() => <HistoryCompareDevice device={device}/>}
                         path={[`/dev/:projectId/:nodeId/:deviceId/${Routers.COMPARE}/:reportId`, `/dev/:projectId/:nodeId/:deviceId/${Routers.COMPARE}`]}/>
                  <Route render={() => '报警事件'} path={`/pj/:projectId/${Routers.ALARM}`} component={Alarm}/>
                  <Route
                    path={[`/pj/:projectId/${Routers.DATA_BOARD}`, `/dev/:projectId/:nodeId/:deviceId/${Routers.DATA_BOARD}`]}
                    component={DataBoard}/>
                  <Route path={`/pj/:projectId/${Routers.SCADA}`} component={Scada}/>
                  <Route
                    path={[`/pj/:projectId/${Routers.ALARM}`, `/dev/:projectId/:nodeId/:deviceId/${Routers.ALARM}`]}
                    component={Alarm}/>
                  <Route path={`/dev/:projectId/:nodeId/:deviceId/${Routers.HEALTH}`} component={CheckUp}/>
                  <Route path={`/dev/:projectId/:nodeId/:deviceId/${Routers.HEALTH_LOG}`} component={MaintLog} />
                </Switch>
              </div>
            </div>
          </div>
        </div> : <NoAuth/>
      }
      {
        props?.showLogin && <Login/>
      }
      <Loading/>
    </div>
  );
}

export default withRouter(App);