import {Form, Input } from "antd";
import {createContext, FC, useContext, useEffect, useRef, useState} from "react";
import {FormInstance} from "antd/es";
import {ReportDetail} from "./realtime-report/report-setter";

const EditableContext = createContext<FormInstance<ReportDetail> | null>(null);

interface EditableRowProps {
}

export const EditableRow: FC<EditableRowProps> = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export interface EditableCellProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ReportDetail;
  record: ReportDetail;
  handleSave: (record: ReportDetail) => void;
}

export const EditableCell: FC<EditableCellProps> = ({
                                                editable,
                                                children,
                                                dataIndex,
                                                record,
                                                handleSave,
                                                ...restProps
                                              }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef?.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: '自定义名称不能为空',
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
