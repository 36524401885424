import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import Modules from "../../pages/modules/modules";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading: setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modules);
