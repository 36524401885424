import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import SystemParam from "../../component/general-info/system-param/system-param";

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SystemParam);
