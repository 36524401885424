import {bindActionCreators, Dispatch} from 'redux';
import {connect, RootStateOrAny} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import HistoryCompareProject from "../../pages/report/history-compare/history-compare.project";

const mapStateToProps = (state: RootStateOrAny) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoryCompareProject);
