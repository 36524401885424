import { FC, useEffect, useState, useRef } from 'react';
import {message, Modal, Tooltip} from 'antd';
import { RequestPath, sendRequest } from "../../../api/http";
import * as echarts from 'echarts/core';
import {
  LineChart,
} from 'echarts/charts';
import {
  TooltipComponent,
  TitleComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  LegendScrollComponent,
  MarkPointComponent,
} from 'echarts/components';
import {
  CanvasRenderer
} from 'echarts/renderers';
import { LineChartOutlined} from '@ant-design/icons';
import moment from 'moment';

import styles from './index.module.less';
import { DataForm, DataParam } from '../entitys'
import {Result} from "../../../common/entity/result";

interface DataCardProps {
  param: DataParam;
}

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, AxisPointerComponent, LegendComponent, ToolboxComponent, LegendScrollComponent, MarkPointComponent]
);

const DataCard: FC<DataCardProps> = (props: DataCardProps) => {
  const { param } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const myChart = useRef<any>();
  const [realtimeParams, setRealtimeParams] = useState<DataParam[]>([]);
  const timerId = useRef<NodeJS.Timeout>();


  useEffect(() => {
    if (realtimeParams.length === 0) {
      const arr = new Array(10).fill('');
      arr[9] = { ...param };
      setRealtimeParams(arr);
    }
    // 清除定时器
    return () => clearTimer();
  }, []);
  useEffect(() => {
    if (isModalVisible) {
      // 定时获取该点实时数据
      timerId.current = setInterval(() => {
        getDataFormDetail();
      }, 5 * 60 * 1000);
      refreshChart();
    } else {
      clearTimer();
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      refreshChart();
    }
  }, [realtimeParams]);

  const clearTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current);
    }
  }
  
  const onClickChart = () => {
    setIsModalVisible(true);
  }

  const refreshChart = () => {
    if (!param) {
      return;
    }
    if (!myChart.current) {
      const chartDom = document.getElementById(`popChart_${param.intRealDataFormParamsId}`);
      if (chartDom) {
        myChart.current = echarts.init(chartDom);
      } else {
        return;
      }
    }
    myChart.current.setOption({
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        data: realtimeParams.map(p => p && p.strDateTime ? (moment(Number(p.strDateTime)).format('HH:mm:ss')) : '')
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [{
        data: realtimeParams.map(p => p ? (p.strValue ?? '') : undefined),
        type: 'line',
      }]
    }, true);
    window.addEventListener("resize", () => {
      myChart.current.resize();
    });
  }

  const getDataFormDetail = () => {
    sendRequest<DataForm>({
      url: `${RequestPath.FLESH_REAL_DATA_FORMS}?formId=${param.intRealDataFormId}&formType=pro`,
      method: 'POST',
    }).then((result: Result<DataForm>) => {
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        const targetParam = result.resBody.lsParamsInfo?.find(p => p.intRealDataFormParamsId === param.intRealDataFormParamsId)
        setRealtimeParams((preState) => {
          const arr: any[] = [...preState];
          arr.push(targetParam);
          const nArr = arr.splice(1);
          return nArr;
        });
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  const getValue = () => {
    try {
        if (param?.strDateTime === '0') {
          return '--';
        }
        const infoDic = JSON.parse(param.strDescinfo!);
        const res = infoDic[param.strValue!];
        if (res) {
          return res;
        } 
        return param?.strValue ? (Math.floor(Number(param?.strValue) * 1000) / 1000).toString().replace(/(\.0+)$/, '') : '--';
      } catch (error) {
        return param?.strValue ? (Math.floor(Number(param?.strValue) * 1000) / 1000).toString().replace(/(\.0+)$/, '') : '--';
      }
  }

  const hasDim = () => {
    try {
      const infoDic = JSON.parse(param.strDescinfo!);
      return !infoDic[param.strValue!];
    } catch (error) {
      return true;
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Tooltip mouseEnterDelay={1} overlay={param.strParamName}>
          <div className={styles.title}>{param.strParamName}</div>
        </Tooltip>
        <LineChartOutlined onClick={onClickChart} className={styles.chart} />
      </div>
      <div className={styles.valueWrap}>
        <div className={styles.value}>
          <Tooltip mouseEnterDelay={1} overlay={getValue()}>
            {getValue()}
          </Tooltip>
        </div>
        {hasDim() && <span className={styles.dim}>{param.strDimension}</span>}
      </div>

      <div className={styles.bottom}>{param?.strDateTime && param?.strDateTime !== '0'  ? moment(Number(param?.strDateTime)).format('YYYY.MM.DD HH:mm:ss') : '--'}</div>
      <Modal width={640} footer={false} title={param.strParamName} onCancel={() => setIsModalVisible(false)} visible={isModalVisible}>
        <div id={`popChart_${param.intRealDataFormParamsId}`} style={{ height: '300px', marginLeft: 10 }}></div>
      </Modal>
    </div>
  );
};

export default DataCard;
