import {FC, useEffect, useMemo, useRef, useState} from 'react';

import styles from './report-setter.module.less';
import {Button, DatePicker, Form, Input, message, Radio, Space, Table} from "antd";
import _ from "lodash";
import Tools from "../../../utils/tools";

// @ts-ignore
import DetailConfig from '@daqo/react-detail-config-panel';
import {useParams} from "react-router-dom";
import {RouterParams} from "../../../pages/app/app.routers";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {DictDetail} from "../../../common/entity/dict-detail";
import Hashids from "hashids";
import {ColumnsType} from "antd/es/table";
import {RequestPath, sendRequest} from "../../../api/http";
import {DateType, DeviceReportForm} from "../../../common/entity/report";
import {ResultCode} from "../../../common/entity/result";
import {error} from "@daqo/log4js";
import moment, {Moment} from "moment";
import {DeviceInfo} from "../../../common/entity/device";
import {EditableCell, EditableRow} from "../editable-cell";

const hashids = new Hashids();

const {RangePicker} = DatePicker

export interface ReportSetterProps {
  cancelDraw: () => void,
  exitDraw: () => void,
  setLoading: (showLoading: boolean) => void,
  current?: DeviceReportForm,
  dev?: DeviceInfo,
  type: 'dev' | 'pj',
}

const splitTypeMapper: {[key: string]: string} = {
  [DateType.YEAR]: 'month',
  [DateType.MONTH]: 'day',
  [DateType.DIY]: 'day',
}

export enum SplitType {
  ALL = 'all',
  INTEGRAL = 'integral',
}

export interface ReportDetail extends DictDetail {
  customName?: string,
}

const ReportSetter: FC<ReportSetterProps> = (props) => {
  const columns = [
    {
      title: '监测点',
      dataIndex: 'detail',
      render: (val: string, record: ReportDetail) => {
        return `${record?.deviceInfo?.strNameInfo ?? '--'}, ${_.head(Tools.categories?.filter(category => category?.key === record?.strDetailCategory))?.name ?? '--'}, ${record?.strNameInfo ?? '--'}`;
      }
    },
    {
      title: '自定义名称',
      dataIndex: 'customName',
      editable: true,
      onCell: (record: ReportDetail) => ({
        editable: true,
        dataIndex: 'customName',
        record,
        handleSave: (row: ReportDetail) => {
          const newDetails = checkedDetail?.map(detail => {
            if (detail.strDetailCategory === row.strDetailCategory && detail?.strKey === row?.strKey && detail?.deviceInfo?.strKey === row?.deviceInfo?.strKey) {
              detail.customName = row.customName
            }
            return detail;
          })
          setCheckedDetail(newDetails);
        }
      })
    },
    {
      title: '操作',
      render: (val: string, record: ReportDetail) => {
        return <Button type="link" onClick={() => removeDetail(record)}>删除</Button>
      }
    }
  ]

  const urlParams = useParams<RouterParams>();
  const formRef = useRef<FormInstance>(null);
  const [checkedDetail, setCheckedDetail] = useState<ReportDetail[]>([]);
  const [dateType, setDateType] = useState<DateType>(DateType.YEAR);
  const [splitType, setSplitType] = useState<SplitType | string>(SplitType.INTEGRAL);
  const [dateRange, setDateRange] = useState<[Moment | null, Moment | null]>();
  const {setLoading, current, type, dev, cancelDraw, exitDraw} = props;

  const stateDetailCheck = (detail: DictDetail) => {
    if (checkedDetail?.filter(d => d?.strDetailCategory === detail?.strDetailCategory && d?.deviceInfo?.strKey === detail?.deviceInfo?.strKey && d?.strKey === detail?.strKey)?.length === 0) {
      setCheckedDetail((prevState => [
        ...prevState,
        {
          ...detail,
          customName: `${detail?.deviceInfo?.strNameInfo}.${detail?.strNameInfo}`,
        },
      ]));
    }
  }

  const removeDetail = (detail: ReportDetail) => {
    setCheckedDetail((prev) => {
      return prev?.filter(d => d?.strKey !== detail?.strKey || d?.strDetailCategory !== detail?.strDetailCategory || d?.deviceInfo?.strKey !== detail?.deviceInfo?.strKey);
    })
  }

  const addReport = () => {
    if (formRef?.current !== null) {
      if ((type === 'pj' && urlParams?.projectId !== undefined) || (type === 'dev' && urlParams?.deviceId)) {
        if (dateType === DateType.DIY && (dateRange?.indexOf(null) ?? 0) > -1) {
          message.warn('自定义统计周期时，需要配置起止时间范围');
          return;
        }
        if (checkedDetail?.length === 0) {
          message.warn('请至少选择一个监测点');
          return;
        }
        formRef?.current?.validateFields().then(() => {
          let targetId = '';
          if (type === 'dev') {
            targetId = hashids.decode(urlParams?.deviceId ?? '').toString();
          } else if (type === 'pj') {
            targetId = hashids.decode(urlParams?.projectId ?? '').toString();
          }
          const name = formRef?.current?.getFieldValue('name');
          const report: DeviceReportForm = {
            intReportId: current?.intReportId,
            intTargetId: targetId,
            strTargetType: type === 'pj' ? 'pj' : 'show',
            strReportName: name,
            lstDeviceParams: checkedDetail?.map(detail => ({
              intDeviceid: detail?.deviceInfo?.strKey ?? '',
              intKindId: detail?.strDetailCategory ?? '',
              strCustomName: detail?.customName ?? '',
              strMongoDbOffset: detail?.strKey ?? '',
            })),
            strReportSpace: dateType === DateType.DAY ? splitType : splitTypeMapper[dateType],
            strReportType: dateType,
            strStartDateTime: dateType === DateType.DIY && dateRange !== undefined && dateRange[0] !== null ? moment(moment(dateRange[0]).add(1, 'd').add(-1, 's')).format('YYYY-MM-DD HH:mm:ss') : undefined,
            strEndDateTime: dateType === DateType.DIY && dateRange !== undefined && dateRange[1] !== null ? moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          }
          setLoading(true);
          sendRequest<string>({
            url: `${current !== undefined ? RequestPath.UPDATE_DEVICE_REPORT : RequestPath.INSERT_REPORT_INFO}`,
            method: 'POST',
            contentType: 'application/json;charset=utf-8',
            body: JSON.stringify(report),
          }).then(result => {
            setLoading(false);
            if (result?.resCode === ResultCode?.OK) {
              message.success('报表添加成功');
              exitDraw();
            } else {
              message.error('报表添加失败，请联系系统管理员');
            }
          }).catch(err => {
            setLoading(false);
            error(err);
          })
        }).catch();
      }
    }
  }

  useEffect(() => {
    if (current !== undefined) {
      setCheckedDetail(current?.lstDeviceParams?.map(param => ({
        customName: param?.strCustomName,
        strKey: param?.strMongoDbOffset,
        strNameInfo: param?.strTitle,
        deviceInfo: {
          strKey: param?.intDeviceid,
          strNameInfo: param?.strDeviceName,
        },
        strDetailCategory: param?.intKindId,
      })) ?? []);
      setDateType(current?.strReportType ?? DateType.YEAR);
      if (current?.strReportType === DateType.DAY) {
        setSplitType(current?.strReportSpace ?? SplitType.INTEGRAL);
      } else if (current?.strReportType === DateType.DIY) {
        setDateRange([moment(current?.strStartDateTime), moment(current?.strEndDateTime)]);
      }
    }
  }, [current]);

  const targetDevice = () => {
    if (type === 'dev' && dev !== undefined) {
      return new Promise(resolve => {
        resolve({
          ok: true,
          body: {
            code: 0,
            body: {
              totalCount: 1,
              pageNum: 1,
              pageCount: 10,
              resultData: [{
                strKey: dev?.intDeviceid,
                strNameInfo: dev?.strDeviceName,
              }]
            }
          }
        });
      });
    }
    return new Promise(resolve => {
      resolve({
        ok: true,
        body: {
          code: 0,
          body: {
            totalCount: 0,
            pageNum: 1,
            pageCount: 10,
            resultData: [],
          }
        }
      });
    });
  }

  const currentProjectId = useMemo(() => {
    return hashids.decode(urlParams?.projectId ?? '').toString();
  }, [urlParams.projectId])

  return <div className={styles.main}>
    <Form
      name="basic"
      initialValues={{
        remember: false,
        name: current?.strReportName ?? '',
        dateType: current?.strReportType ?? DateType.YEAR,
        splitType: current?.strReportSpace ?? SplitType.INTEGRAL,
      }}
      autoComplete="off"
      style={{
        height: '100%',
      }}
      ref={formRef}
    >
      <div className={styles.main_content}>
        <Form.Item
          label="报表名称"
          name="name"
          rules={[{required: true, message: '请输入报表名称'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="统计周期"
          name="dateType"
          rules={[{required: true, message: '请选择统计周期'}]}
        >
          <Radio.Group value={dateType} onChange={(e) => setDateType(e.target.value)}>
            <Radio.Button value={DateType.YEAR}>年</Radio.Button>
            <Radio.Button value={DateType.MONTH}>月</Radio.Button>
            <Radio.Button value={DateType.DAY}>日</Radio.Button>
            <Radio.Button value={DateType.DIY}>自定义</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {
          dateType === DateType.DAY && <Form.Item
            label="分隔时间"
            name="splitType"
            rules={[{required: true, message: '请选择统计周期'}]}
          >
            <Radio.Group value={splitType} onChange={(e) => setSplitType(e.target.value)}>
              <Radio.Button value={SplitType.INTEGRAL}>整点</Radio.Button>
              <Radio.Button value={SplitType.ALL}>全部</Radio.Button>
            </Radio.Group>
          </Form.Item>
        }
        {
          dateType === DateType.DIY && <Form.Item label={'时间范围'} rules={[{
            required: dateType === DateType.DIY,
            message: '请设置起止时间范围',
          }]}>
            <RangePicker onChange={(e) => setDateRange(e ?? [null, null])}/>
          </Form.Item>
        }
        <Form.Item>
          <DetailConfig categoryData={[
            {
              key: '10',
              name: '遥信',
            },
            {
              key: '20',
              name: '遥测',
            },
            {
              key: '30',
              name: '遥脉',
            },
            {
              key: '40',
              name: '定值',
            },
            {
              key: '50',
              name: '历史',
            },
            {
              key: '60',
              name: '计算',
            }
          ]} projectId={currentProjectId} activeDetails={checkedDetail?.map(detail => ({
            detailId: detail?.strKey ?? '',
            category: detail?.strDetailCategory ?? '',
            deviceId: detail?.deviceInfo?.strKey ?? '',
          })) ?? []} onCheck={stateDetailCheck} theme={'light'} width={540} height={400}
                        fontSize={14}
                        cssModules={false} appType={'ORIGIN'} getProjectDevices={type === 'pj' ? undefined : targetDevice}/>
        </Form.Item>
        <Form.Item>
          <div className={styles.main_monitor_title}>监测项</div>
          <Table pagination={false} components={{
            body: {
              row: EditableRow,
              cell: EditableCell,
            },
          }} columns={columns as ColumnsType<ReportDetail>} dataSource={checkedDetail}/>
        </Form.Item>
      </div>
      <div className={styles.main_btn}>
        <Form.Item noStyle={true}>
          <Space size={'large'}>
            <Button type="primary" htmlType="submit" onClick={addReport}>
              保存
            </Button>
            <Button onClick={cancelDraw}>
              取消
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  </div>
}

export default ReportSetter;
