import {ECOption} from "./device-run";
import * as echarts from 'echarts/core';
import Tools from "../../../../utils/tools";

export const getOptions = (xAxis: string[], invalidData: number[], newData: number[], validData: number[]): ECOption => {
  return {
    resize: window.onresize,
    legend: {
      show: true,
      data: [{name: '已停运', icon: "circle"}, {name: '运行中', icon: "circle"}, {name: '新增', icon: "circle"}],
      textStyle: {
        color: '#c4c4c4',
        fontFamily: "细黑",
        fontSize: Tools.remRadio(14),
      },
      right: Tools.remRadio(20),
      top: Tools.remRadio(20),
      selectedMode: false
    },
    toolbox: {},
    tooltip: {
      trigger: 'item',
      show: true,
      formatter: "{c}",
    },
    xAxis: {
      name: '',
      type: 'category',
      data: xAxis,
      splitLine: {show: false},//去除网格线
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#999',
        fontSize: Tools.remRadio(12),
        fontFamily: "细黑",
        padding: [Number(Tools.remRadio(10) + ''), 0, 0, 0],
        fontWeight: "bold",
        formatter: function (value: any) {
          let ret = "";//拼接加\n返回的类目项
          const maxLength = 2;//每项显示文字个数
          const valLength = value.length;//X轴类目项的文字个数
          const rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
          if (rowN > 1)//如果类目项的文字大于3,
          {
            ret = value.substring(0, maxLength);
            return ret;
          } else {
            return value;
          }
        }
      }
    },
    yAxis: [{
      name: '已停运',
      nameTextStyle: {
        color: '#999',
        padding: [Number(Tools.remRadio(5)), 0, 0, 0 - Number(Tools.remRadio(55))],
        fontSize: Tools.remRadio(14),
        fontFamily: "细黑",
        fontWeight: "bold"
      },
      nameLocation: 'start',
      position: 'left',
      axisLine: {show: false},
      splitArea: {show: false},
      type: 'value',
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#4c4c4c',
        fontSize: Tools.remRadio(16),
        fontFamily: "细黑",
        fontWeight: "bold",
        formatter: function (value: any) {
          if (value < 0)
            return -value;
          else
            return value;
        }
      }
    }, {
      name: '运行中',
      nameLocation: 'end',
      position: 'left',
      nameTextStyle: {
        color: '#999',
        padding: [Number(Tools.remRadio(5)), 0, 0, 0 - Number(Tools.remRadio(55))],
        fontSize: Tools.remRadio(14),
        fontFamily: "细黑",
        fontWeight: "bold"
      },
      axisLine: {show: false},
      splitArea: {show: false},
      type: 'value',
      splitLine: {
        show: false
      },//去除网格线
      axisTick: {
        show: false
      },
      axisLabel: {
        color: '#4c4c4c',
        fontSize: Tools.remRadio(16),
        fontFamily: "细黑",
        fontWeight: "bold",
        formatter: function (value: any) {
          if (value < 0)
            return -value;
          else
            return value;
        }
      }
    }],
    grid: {
      left: Tools.remRadio(50),
      // y2: 45
    },
    visualMap: {
      show: false,
      type: 'continuous',
      calculable: true,
      inRange: {
        //  colorLightness: [0.4, 0.8]
        //  color: ['#fa7e21','#d6d6d6', '#6e5d58'],

      }
    },
    series: [
      {
        name: '已停运',
        type: 'bar',
        barWidth: Tools.remRadio(8),
        stack: 'one',
        // itemStyle: itemStyle,
        data: invalidData,
        tooltip: {
          formatter: function (params) {
            return params.name + ":<br>" + params.seriesName + ":" + (0 - Number(params.value));
          }
        },
        label: {
          show: true,
          position: 'bottom',
          formatter: function (params) {
            return `${0 - Number(params.value)}`;
          },
          color: '#fff'
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#ffef00'
          }, {
            offset: 1,
            color: '#ffdb00'
          }]),
          borderRadius: [0, 0, 20, 20],
        },
        emphasis: {
          barBorderWidth: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        }
      },
      {
        name: '运行中',
        type: 'bar',
        barWidth: Tools.remRadio(8),
        stack: 'one',
        data: validData,
        tooltip: {
          formatter: function (params) {
            return params.name + ":<br>" + params.seriesName + ":" + (Number(params.value) + newData[params.dataIndex]);
          }
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00ddff'
          }, {
            offset: 1,
            color: '#0079ff'
          }]),
          shadowOffsetY: -2,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0,0,0,0.5)',
          borderRadius: [20, 20, 0, 0],
          shadowBlur: 0,
          borderWidth: 1,
        }
      },
      {
        name: '新增',
        type: 'bar',
        barWidth: Tools.remRadio(8),
        stack: 'one',
        data: newData,
        label: {
          show: true,
          position: 'top',
          formatter: function (params) {
            return (Number(params.value) + validData[params.dataIndex]) + '';
          },
          color: '#fff'
        },
        tooltip: {
          formatter: function (params) {
            return params.name + ":<br>" + params.seriesName + ":" + params.value;
          }
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#33da74'
          }, {
            offset: 1,
            color: '#1a873a'
          }]),
          borderRadius: [20, 20, 0, 0],
          shadowOffsetX: 0,
          shadowOffsetY: 2,
          shadowColor: '#1a873a',
        }
      }
    ]
  };
}
