import LoginTypes from './login.types';
import {AppAction} from "../app/app.reducer";

interface AppReducer {
  showLogin: boolean,
}

const initialState: AppReducer = {
  showLogin: false,
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case LoginTypes.SHOW_LOGIN:
      return {
        ...state,
        showLogin: action.data,
      };
    default:
      return state;
  }
}

export default reducer;
