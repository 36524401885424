import { FC, useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Button, Drawer, Input, Table, message, Popconfirm, Typography, Form
} from 'antd';
import { RequestPath, sendRequest } from "../../../api/http";
import {Result} from "../../../common/entity/result";

import styles from './index.module.less';
import { DataForm, DataParam } from '../entitys';
import { DeviceInfo } from '../../../common/entity/device';
import {DictDetail} from "../../../common/entity/dict-detail";
import {RouterParams} from '../../app/app.routers';

// @ts-ignore
import DetailConfig from '@daqo/react-detail-config-panel';
import Hashids from 'hashids';
const hashids = new Hashids();

const cates = [
  { name: '遥信', key: '10' },
  { name: '遥测', key: '20' },
  { name: '遥脉', key: '30' },
  { name: '定值', key: '40' },
  { name: '历史', key: '50' },
  { name: '计算', key: '60' },
];
interface ActiveDetail {
  detailId?: string,
  category?: string,
  deviceId?: string,
}
interface DrawPageProps {
  dataForm?: DataForm;
  visible: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

const DrawPage: FC<DrawPageProps> = (props: DrawPageProps) => {
  const { dataForm, visible, onClose, onSuccess } = props;
  const routerParams = useParams<RouterParams>();
  const location = useLocation();
  const type = location.pathname.startsWith('/pj') ? 'pro' : 'dev';
  const projectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams]);
  const deviceId = useMemo(() => {
    return hashids.decode(routerParams?.deviceId ?? '').toString();
  }, [routerParams]);

  const [dataFormDetail, setDataFormDetail] = useState<DataForm>();

  const [selectedDetails, setSelectedDetails] = useState<DictDetail[]>();
  const [activeDetails, setActiveDetails] = useState<ActiveDetail []>();
  const [form] = Form.useForm();

  useEffect(() => {
    // console.log('+++++++++++++++++');
    console.log('+++++++++++++++++',location);

    if (dataForm) {
      getBoardDetail();
    } else {
      setDataFormDetail(undefined);
      setSelectedDetails([]);
      setActiveDetails([]);
    }
  }, [dataForm]);



  // useEffect(() => {
  //   console.log('------sourceDevices', sourceDevices);
  // }, [sourceDevices])
  useEffect(() => {
    if (dataFormDetail) {
      const selDetails = dataFormDetail?.lsParamsInfo?.map((p: DataParam) => paramToDetail(p));
      setSelectedDetails(selDetails);
      form.resetFields();
      form.setFieldsValue({ strViewName: dataFormDetail?.strViewName });
    } else {
      setSelectedDetails([]);
      form.resetFields();
    }
  }, [dataFormDetail])

  useEffect(() => {
    const selDetails = selectedDetails?.map((d: DictDetail) => ({
      detailId: d.strKey,
      category: d.strDetailCategory,
      deviceId: d.deviceInfo?.strKey,
    }));
    setActiveDetails(selDetails);
  }, [selectedDetails])


  const paramToDetail = (param: DataParam): DictDetail => {
    return (
      {
        strKey: `${param.intCatagoryDetail}`,
        strDetailCategory: param.strCategory,
        deviceInfo: {
          strKey: `${param.intDeviceId}`,
          strNameInfo: param.strDeviceName,
        },
        strNameInfo: param.strParamName
      }
    );
  }
  const detailToParam = (detail: DictDetail) => {
    // 判断之前是否存在
    const oldParam = dataFormDetail?.lsParamsInfo?.find((p: DataParam) => (
      `${p.intCatagoryDetail}` === detail.strKey &&
      p.strCategory === detail.strDetailCategory &&
      `${p.intDeviceId}` === detail.deviceInfo?.strKey
    ));
    if (oldParam) {
      return oldParam;
    } else {
      return {
        intCatagoryDetail: detail.strKey,
        intDeviceId: detail.deviceInfo?.strKey,
        needDelete: 0,
        strCategory: detail.strDetailCategory,
        strDeviceName: detail.deviceInfo?.strNameInfo,
        strNetGateName: detail.netGateInfo?.strNameInfo,
        strParamName: detail.strNameInfo
      };
    }
  }

  /**
  * 保存
  */
  const onSaveEdit = async () => {
    try {
      const fileds = await form.validateFields();
      const body = {
        intRealTimeFormId: dataFormDetail?.intRealTimeFormId,
        intTargerId: dataFormDetail?.intTargerId,
        strType: dataFormDetail?.strType,
        strViewName: fileds.strViewName,
        lsParamsInfo: selectedDetails?.map(detailToParam)
      };

      sendRequest({
        url: `${RequestPath.UPDATE_REALDATA_FORM}`,
        method: 'POST',
        contentType: "application/json;charset=utf-8",
        body: JSON.stringify(body),
      }).then((result: Result<any>) => {
        if (result.resCode === 'ok') {
          // 清空数据
          setDataFormDetail(undefined);
          setSelectedDetails([]);
          setActiveDetails([]);
          form.resetFields();
          // 刷新界面
          onSuccess?.();
          message.success('修改成功');
        } else {
          message.error('保存失败');
        }
      }).catch(err => {
        message.error('保存失败');

      });
    } catch (error) {
      message.error('保存失败');
    }
  }

  const onSaveAdd = async () => {
    try {
      const fileds = await form.validateFields();
      const body = {
        intTargerId: type === "pro" ? projectId : deviceId,
        strType: type === 'pro' ? 'pro' : 'show',
        strViewName: fileds.strViewName,
        lsParamsInfo: selectedDetails?.map(detailToParam)
      };

      sendRequest({
        url: `${RequestPath.INSERT_REALDATA_FORM}`,
        method: 'POST',
        contentType: "application/json;charset=utf-8",
        body: JSON.stringify(body),
      }).then((result: Result<any>) => {
        if (result.resCode === 'ok') {
          setDataFormDetail(undefined);
          setSelectedDetails([]);
          setActiveDetails([]);
          form.resetFields();
          // 刷新界面
          onSuccess?.();
          message.success('添加成功');
        } else {
          message.error('添加失败');
        }
      }).catch(err => {
        message.error('添加失败');

      });
    } catch (error) {
      message.error('添加失败');
    }
  }
  /**
  * 获取看板详情
  */
  const getBoardDetail = () => {
    sendRequest<DataForm>({
      url: `${RequestPath.FLESH_REAL_DATA_FORMS}?formId=${dataForm?.intRealTimeFormId}&formType=${dataForm?.strType}`,
      method: 'POST',
    }).then((result: Result<DataForm>) => {
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setDataFormDetail(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }


  /**
  * 点击数据点
  */
  const onClickDetail = (detail: DictDetail) => {
    // 添加到selectedDetails, 没有则添加
    console.log('----------', detail);
    const isSelected = selectedDetails?.some((d: DictDetail) => {
      return d.strKey === detail.strKey &&
        d.deviceInfo?.strKey === detail.deviceInfo?.strKey &&
        d.strDetailCategory === detail.strDetailCategory;
    });
    if (!isSelected) {
      const arr = selectedDetails ? [...selectedDetails] : [];
      arr.push({...detail});
      setSelectedDetails(arr);
    }
  }

  const onDrawClose = () => {
    setDataFormDetail(undefined);
    setSelectedDetails([]);
    setActiveDetails([]);
    form.resetFields();
    onClose?.();
  }

  /**
  * 移除数据点
  */
  const removeDetail = (detail: DictDetail) => {
    const restArr = selectedDetails?.filter((d: DictDetail) => {
      return !(d.strKey === detail.strKey &&
        d.deviceInfo?.strKey === detail.deviceInfo?.strKey &&
        d.strDetailCategory === detail.strDetailCategory);
    });
    setSelectedDetails(restArr);
  }

  const targetDevice = () => {
    if (type === 'dev') {
      const resPromise = sendRequest<DeviceInfo>({
        url: `${RequestPath.GET_DEVICE_INFO}?deviceId=${deviceId}`
      }).then(result => {
        if (result.resCode === "ok" && result.resBody !== undefined) {
          const deviceInfo = result.resBody
          return new Promise(resolve => {
            resolve({
              ok: true,
              body: {
                code: 0,
                body: {
                  totalCount: 1,
                  pageNum: 1,
                  pageCount: 10,
                  resultData: [{
                    strKey: deviceInfo?.intDeviceid,
                    strNameInfo: deviceInfo?.strDeviceName,
                  }]
                }
              }
            });
          });
        }
      }).catch(() => { });
      return resPromise;

    }
    return new Promise(resolve => {
      resolve({
        ok: true,
        body: {
          code: 0,
          body: {
            totalCount: 1,
            pageNum: 1,
            pageCount: 10,
            resultData: []
          }
        }
      });
    });
  }

  return (
    <Drawer
      title={`${dataForm?.intRealTimeFormId ? '编辑' : '新增'}看板`}
      maskClosable={false}
      width={600}
      onClose={onDrawClose}
      visible={visible}
      destroyOnClose
      footer={
        <div style={{ textAlign: 'right',}}>
          <Button onClick={onDrawClose} style={{ marginRight: 8 }}>取消</Button>
          <Button onClick={dataFormDetail ? onSaveEdit : onSaveAdd} type="primary">保存</Button>
        </div>
      }
    >
      <Form form={form}>
        <Form.Item
          name="strViewName"
          label="看板名称"
          rules={[{ required: true, message: '请输入看板名称' }]}
        >
          <Input placeholder="请输入看板名称" />
        </Form.Item>
      </Form>
      <div className={styles.section}>元素选择</div>
      <DetailConfig
        categoryData={cates}
        projectId={projectId}
        onCheck={onClickDetail}
        theme={'light'}
        width={540}
        height={400}
        fontSize={14}
        cssModules={false}
        appType={'ORIGIN'}
        activeDetails={activeDetails ?? []}
        mounted={() => { }}
        getProjectDevices={type === 'pro' ? undefined : targetDevice}
      />
      <div style={{marginTop: 20}} className={styles.section}>监测项</div>
      <Table
        bordered
        dataSource={selectedDetails ?? []}
        pagination={false}
        size="small"
      >
          <Table.Column key="no" title="NO." dataIndex='no' render={(_, record: any, index: number) => index + 1} />
          <Table.Column key="strDeviceName" title="来源设备" dataIndex={["deviceInfo","strNameInfo"]} />
          <Table.Column key="strNameInfo" title="监测点" dataIndex='strNameInfo' />
          <Table.Column width={ 60 } key="actions" title="操作" dataIndex='actions'
            render={(_,record: DictDetail) => (
              <Popconfirm
                title="删除确认"
                onConfirm={(e) => removeDetail(record)}
                onCancel={() => { }}
                okText="确认"
                cancelText="取消"
              >
                <Typography.Link onClick={(e) => e.stopPropagation()} type="danger">删除</Typography.Link>
              </Popconfirm>
            )}
          />
      </Table>
   </Drawer>
  );
};

export default DrawPage;
