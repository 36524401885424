import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import HistoryCompareDevice from "../../pages/report/history-compare/history-compare.device";

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoryCompareDevice);
