import {ChannelInfo} from "./channel";
import {ShowDeviceDetail} from "./detail";
import {ProjectInfo, ProjectUnitInfo} from "./project";

export interface DeviceInfo {
  intDeviceid?: string,
  strDeviceName?: string,
  pjInfo?: ProjectInfo,
  lstDBParams?: DeviceParamInfo[],
  strPosition?: string,
  strDeviceNote?: string,
  strInstallTimeStamp?: string,
  strDeviceSnapShot?: string,
  strGuid?: string,
  deviceKind?: DeviceKindInfo,
  vendor?: VendorInfo,
  devModel?: DeviceModel,
  unitInfo?: ProjectUnitInfo,
  deleteFlag?: string,
  strActiveTime?: string,
  strMaintLevel?: string,
  strMaintCycle?: string,
  strRemainMaintDays?: string,
  strMaintLogCount?: string,
  devImgList?: string[],
}

export interface DeviceKindInfo {
  intDeviceKindId?: number,
  strDeviceKindName?: string,
  strCount?: string,
}

export interface DeviceParamInfo {
  intDeviceid?: string,
  strDeviceName?: string,
  strNetGateId?: string,
  strNetGateName?: string,
  strTitle?: string,
  strKind?: string,
  strMongoDbOffset?: string,
  intKindId?: string,
  strCustomName?: string,
  strActive?: string,
  strEventContent?: string,
  strDimension?: string,
}

export interface Version {
  strVersionId?: string,
  strVersion?: string,
  strFileName?: string,
  strVersionInfo?: string,
  strCreateTime?: string,
  fileType?: number,
}

export interface NetGateInfo extends DeviceInfo {
  strBoot?: string,
  channelList?: ChannelInfo[],
  lstDevice?: DevInNetGate[],
  strVersion?: string,
  versionList?: Version[],
}

export interface DevInNetGate extends DeviceInfo {
  netGate?: NetGateInfo,
  strDevType?: string,
  intChannelId?: string,
  strAddress?: string,
}

export interface DeviceModel {
  intDeviceModelId?: string,
  strModelName?: string,
  strModelType?: string,
  vendorInfo?: VendorInfo,
  strReplaceLife?: number,
  strMaintLife?: number,
  strDescInfo?: string,
  channelList?: ChannelInfo[],
  lstNamePlate?: ModelNamePlate[],
  lstDocs?: string[],
}

export interface VendorInfo {
  intVendorId?: number,
  strName?: string,
  strVendorLogo?: string,
  oldLogo?: string,
  strDescInfo?: string,
  lstDeviceModel?: DeviceModel[],
}

export interface ModelNamePlate {
  intPkId?: string,
  modelId?: string,
  modelName?: string,
  strNameInfo?: string,
  strRatedValue?: string,
  strCompany?: string,
  strDescInfo?: string,
}

export interface ShowNode {
  name?: string,
  type?: string,
  strKey?: string,
  quote?: boolean,
}

export interface DeviceShowInfo {
  target?: ShowNode,
  showFlag?: boolean,
  nodes?: DeviceShowInfo[],
}

export interface ShowDevice {
  netGate?: string,
  strDevId?: string,
  exDevInfo?: DeviceInfo,
  strProjectId?: string,
  strProjectName?: string,
  lstDetail?: ShowDeviceDetail[],
  lstDevice?: DeviceInfo[],
}

export interface NewShowNode {
  strNodeId?: string,
  strProjectId?: string,
  strNodeName?: string,
  strNodeKey?: string,
  strParent?: string,
  haveNext?: boolean,
  strDeviceId?: string,
  strDeviceName?: string,
  strNetgateId?: string,
  strKind?: boolean,
  devModel?: DeviceModel,
  strDeviceSnapshot?: string,
  nodes?: NewShowNode[],
  lstDetail?: ShowDeviceDetail[],
}

export interface DeviceRun {
  intModelId?: string,
  strModelName?: string,
  validCounts?: string,
  newCounts?: string,
  invalidCounts?: string,
}

export interface BaseStatis {
  strStatisName?: string,
}

export interface DeviceState extends BaseStatis{
  strErrorCount?: string,
  strNormalCount?: string,
  startAngle?: number,
}

export interface DeviceAlertState extends BaseStatis {
  strUnsolveCount?: string,
  strSolvedCount?: string,
  startAngle?: number,
}

export interface DeviceCount extends BaseStatis {
  strCount?: string,
}

export interface EventCount extends BaseStatis {
  strCount?: string,
}

export interface DeviceStateDistribute extends BaseStatis {
  strSafeCount?: string,
  strLowWarningCount?: string,
  strMidWarningCount?: string,
  strHighWarningCount?: string,
  strDangerCount?: string,
  strUnknowCount?: string,
  strSafeDevs?: string,
  strLowWarningDevs?: string,
  strMidWarningDevs?: string,
  strHighWarningDevs?: string,
  strDangerDevs?: string,
  strUnknowDevs?: string,
}

export interface DangerDevice {
  intDeviceId?: string,
  strDeviceName?: string,
  intPjId?: string,
  strPjName?: string,
  intMaintLevel?: string,
}

export interface ReliabilityInfo {
  MODEL_ID?: number,
  MODEL_NAME?: string,
  alertcounts?: number,
  maintcounts?: number,
  reliability?: string,
  safedays?: number,
}

export interface MaintCosts {
  remindCount?: number,
  logCount?: number,
  expertCount?: number,
  totalCounts?: number,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEventCount = (obj: any): obj is EventCount => {
  return 'strCount' in obj;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDeviceCount = (obj: any): obj is DeviceCount => {
  return 'strCount' in obj;
}

// eslint-disable-next-line
export const isDeviceStateDistribute = (obj: any): obj is DeviceStateDistribute => {
  return 'strSafeCount' in obj;
}
