import {FC, useCallback, useEffect, useRef, useState} from "react";
import * as echarts from 'echarts/core';
import {
  PieChart,
  PieSeriesOption,
} from 'echarts/charts';
import {
  TooltipComponent,
  TooltipComponentOption,
  TitleComponent,
  TitleComponentOption,
  AxisPointerComponent,
  AxisPointerComponentOption,
  LegendComponent,
  LegendComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  GridComponent,
  GridComponentOption,
  LegendScrollComponent,
  MarkPointComponent,
  MarkPointComponentOption,
} from 'echarts/components';
import {
  CanvasRenderer
} from 'echarts/renderers';

import getOption from "./pie.option";
import {EChartsType} from "echarts/types/dist/shared";
import ResizeObserver from "resize-observer-polyfill";

export type ECOption = echarts.ComposeOption<
  TooltipComponentOption | PieSeriesOption | TitleComponentOption | GridComponentOption | AxisPointerComponentOption | LegendComponentOption | ToolboxComponentOption | MarkPointComponentOption
  >;

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, PieChart, CanvasRenderer, AxisPointerComponent, LegendComponent, ToolboxComponent, LegendScrollComponent, MarkPointComponent]
);

import styles from './pie.module.less';

interface PieProps {
  normalCount: number,
  errorCount: number,
  startAngle: number,
  type: 0 | 1,
}

const Pie: FC<PieProps> = (props) => {
  const chart = useRef<HTMLDivElement>(null);
  const resizeTimeout = useRef<NodeJS.Timeout | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const [myChart, setMyChart] = useState<EChartsType>();

  const {normalCount, errorCount, startAngle, type} = props;

  const drawChart = useCallback(() => {
    const $chart = chart.current;
    if ($chart !== null) {
      let myChart = echarts.getInstanceByDom($chart);
      if (myChart === undefined) {
        echarts.dispose($chart);
        myChart = echarts.init($chart, undefined, {
          renderer: 'svg'
        });
      }

      setMyChart(myChart);

      const option = getOption(normalCount, errorCount, startAngle, type);
      myChart.setOption(option, true);
    }
  }, [normalCount, errorCount, startAngle]);

  const chartResize = useCallback(() => {
    if (resizeTimeout?.current !== null) {
      clearTimeout(resizeTimeout?.current);
    }
    resizeTimeout.current = setTimeout(() => {
      drawChart();
      const $chart = chart?.current;
      if (myChart !== undefined && $chart !== null) {
        myChart.resize();
      }
    }, 200);
  }, [drawChart, myChart])

  useEffect(() => {
    drawChart();
    const $chart = chart?.current;
    if ($chart !== null) {
      resizeObserver.current = new ResizeObserver(() => {
        chartResize();
      });
      resizeObserver.current?.observe($chart);
    }
    return () => {
      if (resizeTimeout?.current !== null) {
        clearTimeout(resizeTimeout?.current);
      }
      if ($chart !== null && resizeObserver.current !== null) {
        resizeObserver.current?.unobserve($chart);
      }
    };
  }, [drawChart, chartResize]);

  return <div ref={chart} className={styles.main}/>
};

export default Pie;
