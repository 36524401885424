import {FC, useCallback, useEffect, useRef, useState} from 'react';

import styles from './general-info.device.module.less';
import {useParams} from "react-router-dom";
import {RouterParams} from "../app/app.routers";
import {RequestPath, sendRequest} from "../../api/http";
import Hashids from 'hashids';
import {error} from "@daqo/log4js";
import {ResultCode} from "../../common/entity/result";
import {message, Image, Carousel, Tooltip, Empty, Space, Popconfirm, Drawer, Spin} from "antd";
import {LeftOutlined, RightOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

import NoImg from '../../common/images/no-img.png';
import {OSS_DEV} from "../../utils/constant";
import {CarouselRef} from "antd/lib/carousel";
import mime from 'mime-types';
import {AnalysisBoard, DynamicParams} from "../../common/entity/detail";
import LinePanel from "../../component/general-info/charts/line/line";
import Tools from "../../utils/tools";
import KeyParams from "../../container/general-info/key-params.container";
import {DeviceInfo, ModelNamePlate} from "../../common/entity/device";
import SystemParamPanel from '../../container/general-info/system-param.container';
import ResizeObserver from 'resize-observer-polyfill';

// @ts-ignore
import SelfAdaptFontSize from 'self-adapt-fontsize';

const hashids = new Hashids();
const safs = SelfAdaptFontSize.getInstance();

export interface GeneralInfoProps {
  setLoading: (showLoading: boolean) => void,
}

export interface KeyParamStatus {
  show: boolean,
  current?: DynamicParams,
  dev?: DeviceInfo,
}

export interface SystemParam {
  show: boolean,
  current?: AnalysisBoard,
}

const GeneralInfoDevice: FC<GeneralInfoProps> = (props: GeneralInfoProps) => {
  const analysisRef = useRef<HTMLDivElement>(null);
  const routerParams = useParams<RouterParams>();
  const [device, setDevice] = useState<DeviceInfo>();
  const [namePlates, setNamePlates] = useState<ModelNamePlate[]>([]);
  const [analysisBoard, setAnalysisBoard] = useState<AnalysisBoard[]>([]);
  const [technicalFiles, setTechnicalFiles] = useState<string[]>([]);
  const [currentImg, setCurrentImg] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);
  const [dynamicParams, setDynamicParams] = useState<DynamicParams[]>([]);
  const [paramPanel, setParamPanel] = useState<KeyParamStatus>({
    show: false,
  });
  const [systemParam, setSystemParam] = useState<SystemParam>({
    show: false,
  });
  const [infoLoading, setInfoLoading] = useState<boolean>(false);
  const [namePlateLoading, setNamePlateLoading] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [boardLoading, setBoardLoading] = useState<boolean>(false);
  const [dynamicLoading, setDynamicLoading] = useState<boolean>(false);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const {setLoading} = props;

  const getDeviceInfo = useCallback(() => {
    if (routerParams.deviceId !== undefined) {
      const deviceId = hashids.decode(routerParams.deviceId);
      setLoading(true);
      setInfoLoading(true);
      sendRequest<DeviceInfo>({
        url: `${RequestPath.GET_DEVICE_INFO}?deviceId=${deviceId}`
      }).then(result => {
        setLoading(false);
        setInfoLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          const deviceInfo = result.resBody;
          deviceInfo.devImgList = (deviceInfo?.strDeviceSnapShot ?? '') !== '' ? (deviceInfo?.strDeviceSnapShot ?? '').split(',').map(img => `${OSS_DEV}${deviceId}/${img}`) : [];
          if ((deviceInfo?.devModel?.intDeviceModelId ?? '') !== '') {
            getNamePlateInfo(deviceInfo?.devModel?.intDeviceModelId ?? '');
            getTechnicalFiles(deviceInfo?.devModel?.intDeviceModelId ?? '');
          }
          setDevice(deviceInfo);
        } else {
          setDevice(undefined);
          message.error('无法获取设备信息，请联系系统管理员');
        }
      }).catch(err => {
        setInfoLoading(false);
        setLoading(false);
        message.error('无法获取设备信息，请联系系统管理员');
        error(err);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    routerParams.deviceId
  ]);

  const getNamePlateInfo = (modelId: string) => {
    setNamePlateLoading(true);
    sendRequest<ModelNamePlate[]>({
      url: `${RequestPath.GET_NAME_PLATE_LIST}?modelId=${modelId}`
    }).then(result => {
      setNamePlateLoading(false);
      if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
        setNamePlates(result.resBody);
      } else {
        setNamePlates([]);
        message.error('无法获取设备铭牌信息，请联系系统管理员');
      }
    }).catch(err => {
      setNamePlateLoading(false);
      message.error('无法获取设备铭牌信息，请联系系统管理员');
      error(err);
    })
  }

  const getTechnicalFiles = (modelId: string) => {
    setFileLoading(true);
    sendRequest<{ [key: string]: string[] }>({
      url: `${RequestPath.GET_TECHNICAL_FILES}?modelId=${modelId}`
    }).then(result => {
      setFileLoading(false);
      if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
        setTechnicalFiles(result.resBody[modelId]?.filter(file => !file.includes('deviceModelImage')) ?? []);
      } else {
        setTechnicalFiles([]);
        message.error('无法获取设备技术文档，请联系系统管理员');
      }
    }).catch(err => {
      setFileLoading(false);
      message.error('无法获取设备技术文档，请联系系统管理员');
      error(err);
    })
  }

  const formatBoardSize = () => {
    for (let i = 0; i < 5; i++) {
      const valueDom = document.getElementById(`board_value_${i}`);
      const unitDom = document.getElementById(`board_unit_${i}`);
      if (valueDom !== null) {
        safs.fontSize(valueDom, Tools.remRadio(150), Tools.remRadio(80));
      }
      if (unitDom !== null) {
        safs.fontSize(unitDom, Tools.remRadio(35), Tools.remRadio(80));
      }
    }
  }

  useEffect(() => {
    const analysisPanel = analysisRef.current
    if (analysisPanel !== null) {
      resizeObserver.current = new ResizeObserver(() => {
        formatBoardSize();
      });
      resizeObserver.current?.observe(analysisPanel);
    }
    return () => {
      if (analysisPanel !== null && resizeObserver.current !== null) {
        resizeObserver.current?.unobserve(analysisPanel);
      }
    }
  }, [])

  const getAnalysisBoard = useCallback(() => {
    if (routerParams.deviceId !== undefined) {
      const deviceId = hashids.decode(routerParams.deviceId);
      setBoardLoading(true);
      sendRequest<AnalysisBoard[]>({
        url: `${RequestPath.GET_ANALYSIS_BOARD}?targetId=${deviceId}&targetType=dev`
      }).then(result => {
        setBoardLoading(false);
        if (result.resCode === ResultCode.OK) {
          setAnalysisBoard(result?.resBody ?? []);
          setTimeout(() => {
            formatBoardSize();
          });
        } else {
          message.error('无法获取到设备概况数据，请联系系统管理员');
        }
      }).catch(() => {
        setBoardLoading(false);
        message.error('无法获取到设备概况数据，请联系系统管理员');
      })
    }
  }, [
    routerParams.deviceId
  ]);

  useEffect(() => {
    getAnalysisBoard();
  }, [getAnalysisBoard])

  const getDynamicParams = useCallback(() => {
    if (routerParams.deviceId !== undefined) {
      setDynamicLoading(true);
      sendRequest<DynamicParams[]>({
        url: `${RequestPath.GET_DYNAMIC_PARAMS}?targetid=${hashids.decode(routerParams.deviceId)}&type=show`,
      }).then(result => {
        setDynamicLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          setDynamicParams(result.resBody);
        } else {
          setDynamicParams([]);
          message.error('无法获取关键参数，请联系系统管理员');
        }
      }).catch(err => {
        setDynamicLoading(false);
        message.error('无法获取关键参数，请联系系统管理员');
        error(err);
      })
    }
  }, [routerParams.deviceId])

  useEffect(() => {
    getDeviceInfo();
  }, [getDeviceInfo]);

  useEffect(() => {
    getDynamicParams();
  }, [getDynamicParams])

  const prevImg = () => {
    if (carouselRef !== null && carouselRef.current !== null) {
      carouselRef.current.prev();
    }
  }

  const nextImg = () => {
    if (carouselRef !== null && carouselRef.current !== null) {
      carouselRef.current.next();
    }
  }

  const downloadFile = (deviceId?: string, filename?: string) => {
    if (deviceId === undefined || filename === undefined) {
      message.warn('未找到设备或设备技术文档，请联系系统管理员');
      return;
    }
    props?.setLoading(true);
    sendRequest({
      url: `${RequestPath.DOWNLOAD_FILE}?targetId=${deviceId}&fileName=${filename}`,
      resultType: 'arraybuffer',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((result: any) => {
      props?.setLoading(false);
      const filetype = filename.indexOf('.') > -1 ? filename.substring(filename.lastIndexOf('.')).toLowerCase() : '';
      const mimeType = mime.lookup(filetype);
      const file = new Blob([result], {
        ...mimeType ? {
          type: mimeType,
        } : {}
      });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      message.error('无法下载此技术文档，请联系系统管理员');
      props?.setLoading(false);
      error(err);
    });
  }

  const removeParam = (paramId?: string) => {
    if (paramId !== undefined) {
      props?.setLoading(true);
      sendRequest({
        url: `${RequestPath.DELETE_DYNAMIC_PARAMS}?paramId=${paramId}`,
        method: 'POST',
      }).then(result => {
        props?.setLoading(false);
        if (result.resCode === ResultCode.OK) {
          message.success('成功删除关键参数');
          getDynamicParams();
        } else {
          message.error('无法删除关键参数，请联系系统管理员');
        }
      }).catch(err => {
        message.error('无法删除关键参数，请联系系统管理员');
        props?.setLoading(false);
        error(err);
      })
    }
  }

  return <div className={styles.main}>
    <div className={styles.main_basic}>
      <div className={`${styles.info} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>基本信息</div>
        <Spin spinning={infoLoading}>
          <div className={styles.info_img_container}>
            {
              (device?.devImgList ?? []).length > 1 && <LeftOutlined className={styles.left} onClick={prevImg}/>
            }
            {
              (device?.devImgList ?? []).length > 0 ?
                <Carousel style={{width: '2rem'}} autoplay={true} dots={false} ref={carouselRef}
                          beforeChange={(from, to) => setCurrentImg(to)}>
                  {
                    device?.devImgList?.map((img, index) => <Image
                      width={'2rem'}
                      height={'1.2rem'}
                      src={`${img}`}
                      className={styles.img}
                      fallback={NoImg}
                      key={`${img}_${index}`}
                    />)
                  }
                </Carousel> : <Image
                  width={'2rem'}
                  height={'1.2rem'}
                  src={NoImg}
                  preview={false}
                  className={styles.img}
                  fallback={NoImg}
                />
            }
            {
              (device?.devImgList ?? []).length > 1 && <RightOutlined className={styles.right} onClick={nextImg}/>
            }
          </div>
          {
            (device?.devImgList ?? []).length > 1 && <div className={styles.info_img_pointer}>
              {
                device?.devImgList?.map((img, index) => <div key={`${img}_${index}`}
                                                             className={`${styles.pointer} ${currentImg === index ? styles.active : ''}`}/>)
              }
            </div>
          }
          <div className={styles.info_infos_item}>
            <div className={styles.name}>设备名：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`设备名：${device?.strDeviceName ?? '--'}`}
              mouseEnterDelay={0.5}>{device?.strDeviceName ?? '--'}</Tooltip>
            </div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>设备型号：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`设备型号：${device?.devModel?.strModelName ?? '--'}`}
              mouseEnterDelay={0.5}>{device?.devModel?.strModelName ?? '--'}</Tooltip>
            </div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>激活时间：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`激活时间：${device?.strActiveTime ?? '--'}`}
              mouseEnterDelay={0.5}>{device?.strActiveTime ?? '--'}</Tooltip></div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>安装位置：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip title={`安装位置：${device?.strPosition ?? '--'}`}
                                                                           mouseEnterDelay={0.5}>{device?.strPosition ?? '--'}</Tooltip>
            </div>
          </div>
        </Spin>
      </div>
      <div className={`${styles.brand} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>铭牌信息</div>
        <Spin spinning={namePlateLoading}>
          {
            namePlates.length > 0 ? namePlates.map((name, index) => (
              <div className={styles.brand_infos_item} key={`${name?.intPkId}_${index}`}>
                <div className={styles.name}><Tooltip
                  title={`${name?.strNameInfo}：${name?.strRatedValue ?? '--'}${name?.strCompany ?? ''}`}
                  mouseEnterDelay={0.5}>{`${name?.strNameInfo}：`}</Tooltip></div>
                <div className={`${styles.value} ${styles.one_line}`}><Tooltip
                  title={`${name?.strNameInfo}：${name?.strRatedValue ?? '--'}${name?.strCompany ?? ''}`}
                  mouseEnterDelay={0.5}>{`${name?.strRatedValue ?? '--'}${name?.strCompany ?? ''}`}</Tooltip>
                </div>
              </div>
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
          }
        </Spin>
      </div>
      <div className={`${styles.archives} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>技术文档</div>
        <Spin spinning={fileLoading}>
          {
            technicalFiles.length > 0 ? technicalFiles.map((doc, index) => (
              <div key={`${doc}_${index}`} className={styles.archives_archive}
                   onClick={() => downloadFile(device?.devModel?.intDeviceModelId, doc)}>{
                <Tooltip title={`${doc}`} mouseEnterDelay={0.5}>{
                  doc
                }</Tooltip>
              }</div>
            )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
          }
        </Spin>
      </div>
    </div>
    <div className={styles.main_monitor_panel}>
      <div className={`${styles.main_system_monitor} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>
          设备概况
        </div>
        <Spin spinning={boardLoading}>
          <div className={`${styles.params_panel} ${styles.general_situation}`} ref={analysisRef}>
            {
              (analysisBoard?.length ?? 0) > 0 ? analysisBoard?.map((board, index) => (
                <div className={styles.params_panel_item} key={`${board?.intBoardId}_${index}`}>
                  <div className={styles.title_panel}>
                    <Tooltip title={board?.strTitle ?? '--'} mouseEnterDelay={0.4}>
                      <div className={styles.title}>{board?.strTitle ?? '--'}</div>
                    </Tooltip>
                    <i className={`daqo-iconfont daqo-icon-xiugai ${styles.add_param}`} onClick={() => setSystemParam({
                      show: true,
                      current: board,
                    })}/>
                  </div>
                  <Tooltip title={`${Tools.renderConstValue(board?.strValue) ?? '--'}${board?.strDimension ?? ''}`}
                           mouseEnterDelay={0.4}>
                    <div className={styles.value}>
                      <span id={Tools.renderConstValue(board?.strValue) !== '--' ? `board_value_${index}` : `useless_value_${index}`}>{Tools.renderConstValue(board?.strValue) ?? '--'}</span>
                      <span id={(board?.strDimension ?? '') !== '' ? `board_unit_${index}` : `useless_unit_${index}`}>{board?.strDimension ?? ''}</span>
                    </div>
                  </Tooltip>
                </div>
              )) : <div className={styles.center_wrap}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              className={styles.font_size_14}/></div>
            }
          </div>
        </Spin>
      </div>
      <div className={`${styles.main_monitors} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>
          关键数据
          <i className={`daqo-iconfont daqo-icon-tianjia ${styles.add_param}`} onClick={() => setParamPanel({
            show: true,
            dev: device,
          })}/>
        </div>
        <Spin spinning={dynamicLoading}>
          <div className={styles.params_panel}>
            {
              dynamicParams?.length > 0 ? dynamicParams?.map((param, index) => (
                <div key={`${param.intParamId}_${index}`} className={styles.params_panel_item}>
                  <div className={styles.param_title}>
                    <Tooltip
                      title={`${param?.strTitle ?? '--'}: ${Tools.renderConstValue(param?.strValue ?? '--')} ${param?.strDimension ?? ''}`}
                      mouseEnterDelay={0.5}>
                      <div className={styles.param_title_value}>
                        <span className={styles.name}>{param?.strTitle ?? '--'}</span>
                        <span
                          className={styles.value}>{`${Tools.renderConstValue(param?.strValue ?? '--')} ${param?.strDimension ?? ''}`}</span>
                      </div>
                    </Tooltip>
                    <div className={styles.param_title_manager}>
                      <Space>
                        <EditOutlined className={styles.btn} onClick={() => setParamPanel({
                          show: true,
                          current: param,
                          dev: device,
                        })}/>
                        <Popconfirm
                          title="确认要删除此项吗?"
                          onConfirm={() => removeParam(param?.intParamId)}
                          okText="是"
                          cancelText="否"
                        >
                          <DeleteOutlined className={styles.btn}/>
                        </Popconfirm>
                      </Space>
                    </div>
                  </div>
                  <div className={styles.param_chart}>
                    <LinePanel lineData={[
                      {
                        name: '',
                        data: param?.dataList?.map((d, index) => ({
                          value: d,
                          time: `${index + 1}`,
                        })) ?? [],
                      }
                    ]}/>
                  </div>
                </div>
              )) : <div className={styles.center_wrap}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              className={styles.font_size_14}/></div>
            }
          </div>
        </Spin>
      </div>
    </div>
    <Drawer title="关键数据" placement="right" visible={paramPanel?.show ?? false} destroyOnClose={true}
            onClose={() => setParamPanel({
              show: false,
            })} width={600}>
      <KeyParams activeDetail={dynamicParams?.map(param => ({
        detailId: param?.intDetailId,
        category: param?.strCategory,
        deviceId: param?.intSrcId,
      })) ?? []} current={paramPanel?.current} type={'dev'} dev={paramPanel?.dev} exitDraw={() => {
        setParamPanel({
          show: false,
        });
        getDynamicParams();
      }} cancelDraw={() => {
        setParamPanel({
          show: false,
        });
      }}/>
    </Drawer>
    <Drawer title="编辑设备概况" placement="right" visible={systemParam?.show ?? false} destroyOnClose={true}
            onClose={() => setSystemParam({
              show: false,
            })} width={360}>
      <SystemParamPanel total={analysisBoard} type={'dev'} current={systemParam?.current} exitDraw={() => {
        setSystemParam({
          show: false,
        });
        getAnalysisBoard();
      }} cancelDraw={() => {
        setSystemParam({
          show: false,
        });
      }}/>
    </Drawer>
  </div>
}

export default GeneralInfoDevice;
