import {FC, useEffect, useState} from 'react';

import styles from './chart-panel.module.less';
import {Divider} from "antd";
import LinePanel from "../charts/line/line";
import BarPanel from "../charts/bar/bar";
import {DeviceParamInfo, HistoryCompareReport, TimeDataSet} from "../../../common/entity/report";
import _ from "lodash";
import {LineData} from "../charts/line/line.types";
import {BarDataGroup} from "../charts/bar/bar.types";
import {reportTypeMapper} from "../../../pages/report/history-compare/history-compare.project";

export interface ChartPanelProps {
  reportData?: HistoryCompareReport,
}

const ChartPanel: FC<ChartPanelProps> = (props) => {
  const {reportData} = props;
  const [chartData, setChartData] = useState<LineData[] | BarDataGroup[]>([]);

  useEffect(() => {
    if (reportData !== undefined && reportData.strCustomDataSet !== undefined) {
      setChartData(Object.entries(reportData.strCustomDataSet).map(dataset => ({
        name: dataset[0],
        data: dataset[1].map((value, index) => ({
          time: `${index + 1}${reportTypeMapper[reportData?.strCustomType ?? ''] ?? ''}`,
          value,
        })),
      })));
    }
  }, [reportData])

  return <div className={styles.main}>
    <div className={`${styles.main_chart}`}>
      <LinePanel lineData={chartData}/>
    </div>
    <Divider/>
    <div className={`${styles.main_chart}`}>
      <BarPanel barData={[]} multiBarData={chartData}/>
    </div>
  </div>
};

export default ChartPanel;
