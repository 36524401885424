import { FC, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {Button, Switch, message, Space} from 'antd';
import { RequestPath, sendRequest } from "../../api/http";
import * as echarts from 'echarts/core';
import 'echarts-liquidfill';
import { MenuOutlined} from '@ant-design/icons';
import moment  from 'moment';
import {RouterParams, Routers} from '../app/app.routers';
import {Result} from "../../common/entity/result";
import styles from './index.module.less';

import Hashids from 'hashids';
import { MaintAnalysisInfo, MaintCheckItem } from '../../common/entity/maint';
import loop_01 from './loop_01.png';
import loop_02 from './loop_02.png';
import loop_03 from './loop_03.png';
import loop_04 from './loop_04.png';
import loop_05 from './loop_05.png';
import loop_06 from './loop_06.png';

const Levels = [
  { lname: '未知', value: 0.9, strMaintCycle: 180, pic: loop_06, color: '#a8a8a8' },
  { lname: '稳定级', value: 0.9, strMaintCycle: 180, pic: loop_05, color: 'green'},
  { lname: '低风险级', value: 0.7, strMaintCycle: 90, pic: loop_04, color: '#1890ff' },
  { lname: '中等风险级', value: 0.5, strMaintCycle: 30, pic: loop_03, color: 'yello' },
  { lname: '高风险级', value: 0.3, strMaintCycle: 35, pic: loop_02, color: 'orange' },
  { lname: '极高风险级', value: 0.1, strMaintCycle: 7, pic: loop_01, color: '#e44141' },
];

const hashids = new Hashids();

interface TextRowProps {
  text: string;
  timeStr: string;
}
const TextRow = (props: TextRowProps) => (
  <div className={styles.textRow}>
    <div>{props.text}</div>
    <div className={styles.timeText}>{props.timeStr}</div>
  </div>
);

interface CheckItemProps {
  checkItem: MaintCheckItem;
  onChange?: (item: MaintCheckItem) => void;
}
const CheckItem: FC<CheckItemProps> = (props: CheckItemProps) => {
  const { checkItem, onChange } = props;
  const getDetialStr = () => {
    if (checkItem?.intActiveFlag === '0') {
      return {
        style: styles.checkItem_unknow,
        text: '未知'
      };
    }
    if (checkItem?.lstLevel?.length) {
      return {
        style: styles.checkItem_err,
        text: checkItem?.lstLevel?.[0].strExpertInfo ?? '--',
      };
    } else {
      return {
        style: styles.checkItem_normal,
        text: '正常'
      };
    }
  }
  return (
    <div className={styles.checkItem}>
      <div className={styles.checkItem_left}><Switch onChange={() => onChange?.(checkItem)} defaultChecked={checkItem?.intActiveFlag === '1'} /></div>
      <div className={styles.checkItem_right}>
        <div className={styles.checkItem_title}>{checkItem?.detectName}</div>
        <div className={getDetialStr().style}>{getDetialStr().text}</div>
      </div>
    </div>
  );
}

interface CheckUpProps {
}

const CheckUp: FC<CheckUpProps> = (props: CheckUpProps) => {
  const history = useHistory();

  // 全局状态依赖
  // const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const routerParams = useParams<RouterParams>();
  const location = useLocation();
  const type = location.pathname.startsWith('/pj') ? 'pj' : 'dev';
  const projectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams]);
  const nodeId = useMemo(() => {
    return hashids.decode(routerParams?.nodeId ?? '').toString();
  }, [routerParams]);
  const deviceId = useMemo(() => {
    return hashids.decode(routerParams?.deviceId ?? '').toString();
  }, [routerParams]);
  const [analysisInfo, setAnalysisInfo] = useState<MaintAnalysisInfo>();
  const [checking, setChecking] = useState<boolean>(false);

  const myChart = useRef<HTMLDivElement>(null);

  useEffect(() => {
    initChart();
  }, []);

  useEffect(() => {
    getMaintAnalysis();
  }, [deviceId]);

  useEffect(() => {
    refreshChart();
  }, [analysisInfo]);


  // 最大风险等级   5 4 3 2 1:7 15 30 90 180 风险等级对应天数 0表示未知(前端添加的)
  const maxLevel = useMemo(() => {
    try {
      if (!analysisInfo?.lstMaint || analysisInfo?.lstMaint?.length === 0) {
        return 1;
      }
      const levels = analysisInfo?.lstMaint
        ?.map((m: MaintCheckItem) => m.lstLevel?.[0].intLevel ?? NaN)
        .filter(level => level === level)
        .map(l => Number(l))
        .sort((a, b) => b - a);
      return levels?.[0] ?? 1;
    } catch (error) {
      return 0;
    }
  }, [analysisInfo]);

  const initChart = () => {
    if (myChart.current && !echarts.getInstanceByDom(myChart.current)) {
      echarts.init(myChart.current!);
      refreshChart();
    }
  }
  /**
  * 刷新里列表
  */
  const refreshChart = () => {
    const chart = echarts.getInstanceByDom(myChart.current!);
    const level = Levels[maxLevel];
    console.log('-------', level);
    const options = {
      "series": [{
        "type": "liquidFill",
        "radius": "75%",
        "data": [{
          "name": level.lname,
          "value": level.value
        }],
        "outline": {
          "show": true,
          "borderDistance": -40,
          "itemStyle": {
            "borderWidth": 0,
            "shadowBlur": 20,
            "borderColor": "#019cec",
            "shadowColor": "rgba(0, 0, 0, 0.25)"
          }
        },
        "backgroundStyle": {
          "borderWidth": 1,
          "borderColor": "none",
          "color": "none",
          "shadowColor": "rgba(0, 0, 0, 0.8)",
          "shadowBlur": 80
        },
        "itemStyle": {
          "normal": {
            "color": {
              "x": 0,
              "y": 0,
              "x2": 0,
              "y2": 1,
              "type": "linear",
              "global": false,
              "colorStops": [{
                "offset": 0,
                "color": level.color
              }, {
                "offset": 1,
                "color": level.color
              }]
            }
          },
          "emphasis": {
            "opacity": 1
          }
        },
        "label": {
          "normal": {
            "position": ["50%", "50%"],
            "formatter": level.lname,
            "textStyle": {
              "fontSize": 36,
              "color": "#000",
              "insideColor": "#000",
              "fontFamily": "平方粗体"
            }
          }
        }
      }]
    };
    chart.setOption(options, true);
    window.addEventListener("resize", () => {
      chart.resize();
    });
  }
  
  const getMaintAnalysis = () => {
    setChecking(true);
    sendRequest<MaintAnalysisInfo>({
      url: `${RequestPath.GET_MAINT_ANALYSIS}?deviceId=${deviceId}`,
      method: 'GET',
    }).then((result: Result<MaintAnalysisInfo>) => {
      // dispatch({type: 'show_loading', data: false});
      setTimeout(() => {
        setChecking(false);
      }, 2000);
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setAnalysisInfo(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      setChecking(false);
      message.error('系统出现异常，请联系系统管理员');
    });
  }
  // 维保检测
  const doMaintAnalysis = () => {
    setChecking(true);
    sendRequest<MaintAnalysisInfo>({
      url: `${RequestPath.DO_MAINT_ANALYSIS}?deviceId=${deviceId}`,
      method: 'POST',
    }).then((result: Result<MaintAnalysisInfo>) => {
      setTimeout(() => {
        setChecking(false);
      }, 2000);
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setAnalysisInfo(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      setChecking(false);
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  // 忽略维保项
  const ignoreMaint = (item: MaintCheckItem) => {
    sendRequest<any>({
      url: `${RequestPath.IGNOREMAINT}?deviceId=${deviceId}&maintId=${item.intMaintId}&flag=${item.intActiveFlag}`,
      method: 'GET',
    }).then((result: Result<any>) => {
      
      if (result.resCode === "ok") {
        message.success('操作成功');
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      setChecking(false);
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  const goToMaint = () => {
    sendRequest<any>({
      url: `${RequestPath.MAINT_PUTH_TO_APNS}?devId=${deviceId}`,
      method: 'POST',
    }).then((result: Result<any>) => {
      
      if (result.resCode === "ok") {
        message.success('维保推送已发出');
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      setChecking(false);
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  const onCheckClick = () => {
    if (!checking) {
      doMaintAnalysis();
    }
  }

  // 距离下次维保天数
  const nextMaint = () => {
    // 上次维保时间
    const lastMaintTime = moment(analysisInfo?.maintLogInfo?.maintTime);
    // 应维保日期
    const strMaintCycle = Levels[maxLevel].strMaintCycle;
    const nextMaintTime = moment(lastMaintTime).add(strMaintCycle, 'days');
    // 距离天数
    const days = nextMaintTime.diff(moment(), 'days');
    if (days <= 0) {
      return <div>建议<span className={styles.blueText}>立即</span>进行维保</div>;
    }
    return <div>建议<span className={styles.blueText}>{days}</span>天后进行维保</div>;

  }
  // 下次维保日期
  const nextMaintDateTime = () => {
    // 上次维保时间
    const lastMaintTime = moment(analysisInfo?.maintLogInfo?.maintTime);
    // 应维保日期
    const strMaintCycle = Levels[maxLevel].strMaintCycle;
    const nextMaintTime = moment(lastMaintTime).add(strMaintCycle, 'days');
    if (nextMaintTime.diff(moment(), 'days') < 0) {
      return '今天';
    }
    return nextMaintTime.format('YYYY-MM-DD HH:mm:ss');
  }
  
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>
            设备状态
            <MenuOutlined onClick={() => { history.push(`/dev/${routerParams.projectId}/${routerParams.nodeId}/${routerParams.deviceId}/${Routers.HEALTH_LOG}`)}} />
          </div>
          <div className={styles.ballWrper}>
            <img src={Levels[maxLevel].pic} className={checking ? styles.ballBackLoop : styles.ballBack}/>
            <div id="ball-charts" ref={myChart} style={{height: '460px',width: '460px'}} className={styles.ball}></div>
          </div>
          <Space>
            <Button onClick={onCheckClick} type="primary">重新检测</Button>
            <Button onClick={goToMaint} type="default">马上维保</Button>
          </Space>
          {nextMaint()}
          <TextRow text={'上次检测时间:'} timeStr={analysisInfo?.analysisTime ?? '--'} />
          <TextRow text={'上次维保时间:'} timeStr={analysisInfo?.maintLogInfo?.maintTime ?? '--'} />
          <TextRow text={'建议下次维保时间:'} timeStr={nextMaintDateTime()} />
        </div>
        <div className={styles.right}>
          <div className={styles.block}>
            <div className={styles.title}>电网故障</div>
            {analysisInfo?.lstMaint?.filter((el: MaintCheckItem) => el.strMaintType === '电网故障').map((item: MaintCheckItem) => (<CheckItem onChange={ignoreMaint} key={item.intMaintId} checkItem={item} />))}
          </div>
          <div className={styles.block}>
            <div className={styles.title}>机械故障</div>
            {analysisInfo?.lstMaint?.filter((el: MaintCheckItem) => el.strMaintType !== '电网故障').map((item: MaintCheckItem) => (<CheckItem onChange={ignoreMaint} key={item.intMaintId} checkItem={item} />))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckUp;
