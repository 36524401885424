import React, { FC, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button, message, Space, Radio, DatePicker,
  Input, Form, Table, Pagination, Divider, Modal, Drawer
} from 'antd';
import { RequestPath, sendRequest } from "../../api/http";
import * as echarts from 'echarts/core';
import {
  LineChart,
} from 'echarts/charts';
import {
  TooltipComponent,
  TitleComponent,
  AxisPointerComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  LegendScrollComponent,
  MarkPointComponent,
} from 'echarts/components';
import {
  CanvasRenderer
} from 'echarts/renderers';
import { ExportOutlined} from '@ant-design/icons';
import moment, { Moment } from 'moment';
import {RouterParams} from '../app/app.routers';
import {Result,PageData} from "../../common/entity/result";
import { Event, EventCount, CountItem, EventDataParam } from '../../common/entity/event';
import styles from './index.module.less';

import Hashids from 'hashids';
import { ConstantKey } from '../../utils/constant';
const hashids = new Hashids();
const {RangePicker} = DatePicker;

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, AxisPointerComponent, LegendComponent, ToolboxComponent, LegendScrollComponent, MarkPointComponent]
);

enum DateType {
  WEEK = 'week',
  MONTH = 'month',
  DAY = 'date',
  CUSTOM = 'diydate',
}
enum EventType {
  ALARM = '100',
  EVENT = '110',
}
const user = localStorage.getItem(ConstantKey.USER);
const CurrentUser = user ? JSON.parse(user) : undefined;
interface RangeDate {
  start: Moment | null;
  end: Moment | null;
}
interface AlarmProps {
}

const Alarm: FC<AlarmProps> = (props: AlarmProps) => {
  const history = useHistory();

  // 全局状态依赖
  // const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const routerParams = useParams<RouterParams>();
  const location = useLocation();
  const type = location.pathname.startsWith('/pj') ? 'pj' : 'dev';
  const projectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams]);
  const deviceId = useMemo(() => {
    return hashids.decode(routerParams?.deviceId ?? '').toString();
  }, [routerParams]);
  const [eventType, setEventType] = useState<EventType>(EventType.ALARM);
  const [dateType, setDateType] = useState<DateType>(DateType.DAY);
  // 默认当天
  const [date, setDate] = useState<[Moment | null, Moment | null]>([moment().startOf('day'),moment().endOf('day')]);

  const myChart = useRef<HTMLDivElement>(null);
  // table数据
  const [events, setEvents] = useState<Event[]>([]);
  const [editEvent, setEditEvent] = useState<Event>();
  const [eventDataSet, setEventDataSet] = useState<EventDataParam[]>([]);

  // 折线图数据
  const [eventCount, setEventCount] = useState<EventCount[]>([]);
  const [pageInfo, setPageInfo] = useState({ current: 1, pageSize: 10, total: 1 });
  const [visible, setVisible] = useState<boolean>();
  const [form] = Form.useForm();
  const searchKey = useRef('');
  const [exportVisible, setExportVisible] = useState<boolean>(false);
  const [breakList, setBreakList] = useState<Event[]>([]);
  const [selectExportRows, setSelectExportRows] = useState<Event[]>([]);
  const [brealSearch, setBrealSearch] = useState<string>('');

  


  useEffect(() => {
    getEvents();
    getEventCount();
  }, [eventType, date, projectId, deviceId]);
  
  useEffect(() => {
    switch (dateType) {
      case DateType.DAY:
        setDate([moment().startOf('day'), moment().endOf('day')]);
        break;
      case DateType.WEEK:
        setDate([moment().startOf('week'), moment().endOf('week')]);
        break;
      case DateType.MONTH:
        setDate([moment().startOf('month'), moment().endOf('month')]);
        break;
    
      default:
        break;
    }
  }, [dateType]);

  useEffect(() => {
    initChart();
    // getEvents();
    // getEventCount();
  }, []);

  useEffect(() => {
    refreshChart();
  }, [eventCount]);

  useEffect(() => {
    if (editEvent && visible) {
      getDataPic();
    }
  }, [editEvent, visible]);
  
  useEffect(() => {
    if (exportVisible) {
      getBreakList();
    }
  }, [exportVisible]);
  /**
  * 初始化echart
  */
  const initChart = () => {
    if (myChart.current && !echarts.getInstanceByDom(myChart.current)) {
      echarts.init(myChart.current!);
      refreshChart();
    }
  }
  /**
  * 刷新里列表
  */
  const refreshChart = () => {
    const chart = echarts.getInstanceByDom(myChart.current!);
    chart.setOption({
      tooltip: {
        trigger: 'axis',
        formatter: '{b}<br/> {c}次'
      },
      xAxis: {
        type: 'category',
        data: eventCount.map(e => e.dateTime)
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [{
        data: eventCount.map(e => e.lstValue?.map((item: CountItem) => Number(item.strValue)).reduce((p,c) => p + c,0)),
        smooth: true,
        showSymbol: false,
        type: 'line',
      }]
    }, true);
    window.addEventListener("resize", () => {
      chart.resize();
    });
  }

  const tmpType = (type: DateType) => {
    if (type === DateType.CUSTOM) {
      return 'day';
    } else {
      return type;
    }
  }
  /**
  * 获取报警或事件
  */
  const getEvents = (pageNum?: number) => {
    dispatch({type: 'show_loading', data: true});
    sendRequest<PageData<Event>>({
      url: `${RequestPath.GET_DEVICE_EVENT_REPORT}?type=${type}&targetId=${type === "pj" ? projectId : deviceId}&dateType=${dateType === 'date' ? 'day' : dateType}&kinds=${eventType}&pageNum=${pageNum ?? pageInfo.current}&pageCount=${pageInfo.pageSize}&startDate=${date[0]?.valueOf()}&endDate=${date[1]?.valueOf()}&filter=${searchKey.current}`,
      method: 'GET',
    }).then((result: Result<PageData<Event>>) => {
      dispatch({type: 'show_loading', data: false});

      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setEvents(result.resBody.resultData ?? []);
        setPageInfo({ current: result.resBody?.pageNum ?? 1, pageSize: pageInfo.pageSize, total: result.resBody?.totalCount || 0 });
        
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      dispatch({type: 'show_loading', data: false});

      message.error('系统出现异常，请联系系统管理员');
    });
  }
  /**
  * 获取报导出列表
  */
  const getBreakList = () => {
    dispatch({type: 'show_loading', data: true});
    sendRequest<Event[]>({
      url: `${RequestPath.GET_BREAK_LIST}?type=${type}&targetId=${type === "pj" ? projectId : deviceId}&dateType=${dateType}&startDate=${date[0]?.valueOf()}&endDate=${date[1]?.valueOf()}`,
      method: 'GET',
    }).then((result: Result<Event []>) => {
      dispatch({type: 'show_loading', data: false});
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setBreakList(result.resBody ?? []);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      dispatch({type: 'show_loading', data: false});

      message.error('系统出现异常，请联系系统管理员');
    });
  }
 /**
  * 获取报导出列表
  */
  const exportBreakList = () => {
    if (selectExportRows.length === 0) {
      message.warn('请选择要导出的记录');
      return;
    }
    dispatch({ type: 'show_loading', data: true });
    const ids = selectExportRows.map((r: Event) => r.strDetailId).reduce((pre, cur) => `${pre},${cur}`);
    const pDateType = dateType === DateType.DAY ? 'day' : dateType;
    const url = `${RequestPath.EXPORT_DEVICE_EVENT}?type=${type}&selectIds=${ids}&targetId=${type === "pj" ? projectId : deviceId}&dateType=${pDateType}&kinds=${eventType}&startDate=${date[0]?.valueOf()}&endDate=${date[1]?.valueOf()}`;

    sendRequest({
      url,
      resultType: 'arraybuffer',
    }).then((result: any) => {
      dispatch({ type: 'show_loading', data: false });

      const file = new Blob([result]);
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = `报警表导出(${date[0]?.format('YYYY-MM-DD')}_${date[1]?.format('YYYY-MM-DD')}).xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      dispatch({ type: 'show_loading', data: false });
    });
  }
  /**
  * 获取报警事件统计数据(折线图数据)
  */
  const getEventCount = () => {
    const spaceOption = {
      date: 'hour',
      diydate: 'day',
      month: 'day',
      week: 'day'
    };
    const typeOption = {
      date: 'day',
      diydate: 'diydate',
      month: 'month',
      week: 'week'
    };
    const requestUrl = type === "pj" ? (
      `${RequestPath.GET_EVENT_COUNT_FOT_PROJECT}?pjid=${projectId}&type=${typeOption[dateType]}&kinds=${eventType}&spaceType=${spaceOption[dateType]}&statis=false&transFlag=false&startDate=${date[0]?.valueOf()}&endDate=${date[1]?.valueOf()}`
    ) : (
      `${RequestPath.GET_EVENT_COUNT_FOT_DEVICE}?deviceid=${deviceId}&type=${typeOption[dateType]}&kinds=${eventType}&spaceType=${spaceOption[dateType]}&statis=false&transFlag=false&startDate=${date[0]?.valueOf()}&endDate=${date[1]?.valueOf()}`
    );

    sendRequest<EventCount[]>({
      url: requestUrl,
      method: 'GET',
    }).then((result: Result<EventCount []>) => {
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        // setMenuCards(result.resBody);
        setEventCount(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }
  /**
  * 获取数据快照
  */
  const getDataPic = () => {
    sendRequest<EventDataParam[]>({
      url: `${RequestPath.GET_EVENT_DATASET}?eventId=${editEvent?.intDeviceEventId}`,
      method: 'GET',
    }).then((result: Result<EventDataParam []>) => {
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setEventDataSet(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }
  /**
  * 确认报警
  */
  const confirmEvent = async () => {
    try {
      const fileds = await form.validateFields();
      const body = {
        intDeviceEventId: editEvent?.intDeviceEventId,
        strProcessMethod: fileds.strProcessMethod,
      };
      sendRequest({
        url: `${RequestPath.CONFIRM_ONE_EVENT}`,
        method: 'POST',
        contentType: "application/json;charset=utf-8",
        body: JSON.stringify(body),
      }).then((result: Result<any>) => {
        if (result.resCode === 'ok') {
          // 刷新界面
          message.success('确认成功');
          getEvents();
          setEditEvent(undefined);
          setVisible(false);
          setEventDataSet([]);
        } else {
          message.error('保存失败');
        }
      }).catch(err => {
        message.error('保存失败');
      });
    } catch (error) {
      message.error('保存失败');
    }
  }

  const onRowAction = (record: Event) => {
    const confirmed = Number(record.strConfirmStatus) === 1;
    setEditEvent(record);
    form.resetFields();
    if (confirmed) {
      // 查看详情
      form.setFieldsValue(record);
    } else {
      // 确认
    }
    setVisible(true);
  }

  const onClose = () => {
    setVisible(false);
    setEditEvent(undefined);
    setEventDataSet([]);
  }

  /**
  * 根据报警值与描述，解析报警详情
  */
  const parseAlertDetail = (content?: string, key?: string) => {
    if (!content) {
      return '--';
    }
    try {
      const res = JSON.parse(content);
      if (typeof res === 'object' && key) {
        return res[key];
      }
      return res ?? '--';
    } catch (error) {
      return content;
    }
  }

  const onChangePage = (page: number) => {
    const pInfo = {...pageInfo};
    pInfo.current = page;
    setPageInfo(pInfo);
    getEvents(page);
  }

  const onChangeEventType = (type: EventType) => {
    setEventType(type);
    setPageInfo({ current: 1, pageSize: 10, total: 1 });
    setEvents([]);
    setEventCount([]);
  }

  const exportAlarm = () => {
    setExportVisible(true);
  }
  const closeExport = () => {
    setExportVisible(false);
    setSelectExportRows([]);
    setBrealSearch('');
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectExportRows(selectedRows);
    },
  };

  const tryToParse = (jsonStr?: string) => {
    if (!jsonStr) {
      return undefined;
    }
    try {
      return JSON.parse(jsonStr);
    } catch (error) {
      return undefined;
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Space size={50} >
          <Space>
            <div className={styles.secnedTitle}>周期:</div>
            <Radio.Group value={dateType} onChange={(e) => setDateType(e?.target?.value ?? DateType.DAY)}>
              <Radio.Button value={DateType.DAY}>日</Radio.Button>
              <Radio.Button value={DateType.WEEK}>周</Radio.Button>
              <Radio.Button value={DateType.MONTH}>月</Radio.Button>
              <Radio.Button value={DateType.CUSTOM}>自定义</Radio.Button>
            </Radio.Group>
          </Space>
          <Space>
            {
              dateType === DateType.CUSTOM &&
              <>
                <div className={styles.secnedTitle}>时间:</div>
                <RangePicker
                  picker={'date'}
                  onChange={(date: any) => { setDate(date) }}
                  value={Array.isArray(date) ? date : null}
                />
              </>
            }
            {
              eventType === EventType.ALARM && (
                <Button onClick={exportAlarm} type="primary" icon={<ExportOutlined />} />
              )
            }
          </Space>
        </Space>
        <Space split={<Divider type="vertical" />}>
          <Button size="small" type={eventType === EventType.ALARM ? "link" : "text"} onClick={() => { onChangeEventType(EventType.ALARM)}}>报警</Button>
          <Button size="small" type={eventType === EventType.EVENT ? "link" : "text"} onClick={() => { onChangeEventType(EventType.EVENT)}}>事件</Button>
        </Space>
      </div>
        
      <div className={ styles.content}>
        <div className={styles.table}>
          <div className={styles.cardHeader}>
            <div className={styles.cardHeader_title}>{eventType === EventType.ALARM ? '报警列表' : '事件列表'}</div>
            <div>
              {/* <Input.Search onSearch={() => getEvents(1)} onChange={(e) => searchKey.current = e.target.value} placeholder="请输入关键字"/> */}
            </div>
          </div>
          {eventType === EventType.ALARM ? (
            <Table
              style={{margin: 30, marginBottom: 10}}
              bordered
              size="small"
              dataSource={events}
              pagination={false}
            >
              <Table.Column key="no" title="NO." dataIndex='no' render={(_, record: any, index: number) => index + 1} />
              <Table.Column width={180} key="strEventTimeStamp" title="时间" dataIndex='strEventTimeStamp' render={text => text ? moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') : '--'} />
              <Table.Column key="area" title="区域" dataIndex={["netGate","strDeviceName"]} />
              <Table.Column key="strDeviceName" title="设备" dataIndex={["devInfo","strDeviceName"]} />
              <Table.Column key="strEventName" title="报警项" dataIndex='strEventName' />
              <Table.Column key="detail" title="报警详情" dataIndex='detail' render={(_,record: Event) => parseAlertDetail(record.strEventContent,record.strDataValue)} />
              <Table.Column key="strDataValue" title="报警值" dataIndex='strDataValue' />
              <Table.Column key="strConfirmStatus" title="状态" dataIndex='strConfirmStatus' render={(text) => text === 0 ? <span style={{color: 'red'}}>未处理</span> : <span style={{color: 'green'}}>已处理</span>} />
              <Table.Column width={60} key="actions" title="操作" dataIndex='actions'
                render={(text: string, record: Event) => <Button onClick={() => onRowAction(record)} size="small" type="link">{Number(record.strConfirmStatus) === 1 ? '详情' : '处理'}</Button>}/>
            </Table>
          ) : (
            <Table
              style={{margin: 30, marginBottom: 10}}
              bordered
              size="small"
              dataSource={events}
              pagination={false}
            >
              <Table.Column key="no" title="NO." dataIndex='no' render={(_, record: any, index: number) => index + 1} />
              <Table.Column width={180} key="strEventTimeStamp" title="时间" dataIndex='strEventTimeStamp' render={text => text ? moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') : '--'} />
              <Table.Column key="strDeviceName" title="设备" dataIndex={["devInfo","strDeviceName"]} />
              <Table.Column key="strEventName" title="事件名称" dataIndex='strEventName' />
              <Table.Column key="strEventContent" title="事件详情" dataIndex='strEventContent' render={(_,record: Event) => parseAlertDetail(record.strEventContent,record.strDataValue)} />
              <Table.Column key="strDataValue" title="值" dataIndex='strDataValue' />
            </Table>
          )}
          
          <div className={styles.pagination}>
             <Pagination showSizeChanger={false} onChange={onChangePage} size="small" {...pageInfo} showTotal={(total: number) => `共${total}条`} />
           </div>
        </div>
        <div className={styles.chart}>
          <div className={styles.cardHeader}>
            <div className={styles.cardHeader_title}>{eventType === EventType.ALARM ? '报警统计' : '事件统计'}</div>
          </div>
          <div ref={myChart} id="lineChart" style={{height: '3000px',width: '100%'}} />
        </div>
      </div>
      <Drawer
        title={Number(editEvent?.strConfirmStatus) === 1 ? '报警详情' : '处理报警'}
        maskClosable={false}
        width={450}
        onClose={onClose}
        visible={visible}
        destroyOnClose
        footer={ 
          <div style={{ textAlign: 'right', }}>
            {Number(editEvent?.strConfirmStatus) === 1 ? (
              <Button onClick={onClose}>关闭</Button>
            ): (
            <>
              <Button onClick={onClose} style={{ marginRight: 8 }}>取消</Button>
              <Button onClick={confirmEvent} type="primary">保存</Button>
            </>
            )}

          </div>
        }
      >
        <Form form={form}>
          <Form.Item label="报警名称"><div className={styles.secnedTitle}>{editEvent?.strEventName}</div></Form.Item>
          <Form.Item label="报警时间"><div className={styles.secnedTitle}>{editEvent?.strEventTimeStamp ? moment(Number(editEvent?.strEventTimeStamp)).format('YYYY-MM-DD HH:mm:ss') : '--'}</div></Form.Item>
          <Form.Item label="报警详情"><div className={styles.secnedTitle}>{parseAlertDetail(editEvent?.strEventContent,editEvent?.strDataValue)}</div></Form.Item>
          <Form.Item label="报警区域"><div className={styles.secnedTitle}>{editEvent?.netGate?.strDeviceName}</div></Form.Item>
          <Form.Item label="处理人员">
            {
              Number(editEvent?.strConfirmStatus) === 1 ? (
                <div className={styles.secnedTitle}>{editEvent?.userInfo?.strUsername}</div>
              ) : (
                <div className={styles.secnedTitle}>{CurrentUser?.strUsername ?? '--'}</div>
              )
            }
          </Form.Item>
          <Form.Item
            name="strProcessMethod"
            label="处理方式"
            rules={[{ required: true, message: '请输入处理方式' }]}
          >
            {
              Number(editEvent?.strConfirmStatus) === 1 ? (
                <div className={styles.secnedTitle}>{editEvent?.strProcessMethod}</div>
              ) : (
                <Input.TextArea placeholder="请输入处理方式" />
                )
            }
          </Form.Item>
          <div className={styles.section}>数据快照</div>
          <Table
            bordered
            dataSource={eventDataSet}
            pagination={false}
            size="small"
          >
            <Table.Column key="strParamName" title="数据名称" dataIndex='strParamName' />
            <Table.Column key="strDeviceName" title="设备" dataIndex='strDeviceName'
              render={(_,record:EventDataParam) =>`${record.strNetGateName ?? '-'}.${record.strDeviceName ?? '-'}`}
            />
            <Table.Column key="strValue" title="数值" dataIndex='strValue'
              render={(_, record: EventDataParam) => {
                const jsonObj = tryToParse(record.strDescinfo);
                if (jsonObj) {
                  return record.strValue ? (jsonObj[record.strValue] ?? record.strValue) : '--';
                } else {
                  return record.strValue ?? '--';
                }
              }}
            />
            <Table.Column key="strDateTime" title="发生时间" dataIndex='strDateTime' render={text => text ? moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') : '--'} />
          </Table>
        </Form>
      </Drawer>
      <Modal
        title="报警导出信息"
        centered
        visible={exportVisible}
        onOk={exportBreakList}
        onCancel={closeExport}
        width={800}
        okText="导出"
        maskClosable={false}
        destroyOnClose
      >
        <div className={styles.modalContent}>
        <Input.Search
          placeholder={'请输入报警名称'}
          onChange={(e) => setBrealSearch(e.target.value)}
          style={{width: 300, marginBottom: 20}}
        />
        <Table
          bordered
          dataSource={brealSearch.length > 0 ? breakList.filter(b => b.strEventName?.includes(brealSearch)) : breakList}
          pagination={false}
          size="small"
          rowKey={(record: Event) => record.strDetailId!}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        >
          <Table.Column key="strEventName" title="报警名称" dataIndex='strEventName' />
          <Table.Column key="strDeviceName" title="设备" dataIndex={["devInfo","strDeviceName"]} />
        </Table>
        </div>
      </Modal>
    </div>
  );
};

export default Alarm;
