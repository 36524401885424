import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';

import styles from './history-compare.device.module.less';
import Search from "antd/es/input/Search";
import {Button, Drawer, Empty, message, Popconfirm, Spin, Table, Tabs, Tooltip} from "antd";
import {AreaChartOutlined, FileAddOutlined, TableOutlined} from '@ant-design/icons';
import {useHistory, useParams} from "react-router-dom";
import {RouterParams, Routers} from "../../app/app.routers";
import Hashids from "hashids";
import {RequestPath, sendRequest} from "../../../api/http";
import {error} from "@daqo/log4js";
import {HistoryCompareReport} from "../../../common/entity/report";
import {ResultCode} from "../../../common/entity/result";
import _ from 'lodash';
import {ColumnsType} from "antd/lib/table/interface";
import Tools from "../../../utils/tools";
import CompareSetter from "../../../container/report/history-compare-setter.container";
import ChartPanel from "../../../component/report/history-compare/chart-panel";
import {DeviceInfo} from "../../../common/entity/device";

const hashids = new Hashids();

const {TabPane} = Tabs;

export const reportTypeMapper: { [key: string]: string } = {
  'year': '月',
  'month': '日',
  'day': '时',
  'custom': '日',
};

interface CompareColumnType {
  reportType: string,
  reportData: {[key: string]: string},
}

const basicColumns: ColumnsType<CompareColumnType> = [
  {
    title: '时间',
    dataIndex: 'time',
    fixed: 'left',
    width: 200,
    render: (value, record, index) => {
      return `${index + 1}${reportTypeMapper[record?.reportType ?? ''] ?? ''}`;
    }
  }
]

const dateTypeMapper: { [key: string]: string } = {
  'year': '年',
  'month': '月',
  'day': '日',
  'diydate': '自',
}

export interface RealtimeReportParams extends RouterParams {
  reportId?: string,
}

export interface RealtimeReportProps {
  setLoading: (showLoading: boolean) => void,
  device?: DeviceInfo,
}

interface ReportSetterStatus {
  show: boolean,
  current?: HistoryCompareReport,
}

const HistoryCompareDevice: FC<RealtimeReportProps> = (props) => {
  const routerParams = useParams<RealtimeReportParams>();
  const history = useHistory();
  const [reportForms, setReportForms] = useState<HistoryCompareReport[]>([]);
  const [formSearch, setFormSearch] = useState<string>('');
  const [showType, setShowType] = useState<string>('table');
  const [reportData, setReportData] = useState<CompareColumnType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<CompareColumnType>>(basicColumns);
  const [chartData, setChartData] = useState<HistoryCompareReport>();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [reportSetter, setReportSetter] = useState<ReportSetterStatus>({
    show: false,
  });

  const {setLoading, device} = props;

  const getHistoryReportList = useCallback(() => {
    if (routerParams.deviceId !== undefined) {
      const deviceId = hashids.decode(routerParams.deviceId);
      setLoading(true);
      sendRequest<HistoryCompareReport[]>({
        url: `${RequestPath.GET_HISTORY_REPORT_LIST}?targetId=${deviceId}&targetType=show`,
      }).then(result => {
        setLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          setReportForms(result.resBody);
        } else {
          setReportForms([]);
          message.error('无法获取报表数据，请联系系统管理员');
        }
      }).catch(err => {
        setLoading(false);
        message.error('无法获取报表数据，请联系系统管理员');
        error(err);
      })
    }
  }, [routerParams.deviceId]);

  useEffect(() => {
    getHistoryReportList();
  }, [getHistoryReportList]);

  useEffect(() => {
    if (routerParams?.reportId === undefined && reportForms?.length > 0) {
      history.push(`/dev/${routerParams?.projectId}/${routerParams?.nodeId}/${routerParams?.deviceId}/${Routers.COMPARE}/${hashids.encode(_.head(reportForms)?.strReportId ?? '')}`);
    }
  }, [reportForms])

  const getReportData = useCallback(() => {
    if (reportForms?.length > 0 && routerParams?.reportId !== undefined) {
      const reportId = hashids.decode(routerParams?.reportId ?? '').toString();
      const currentForm = _.head(reportForms?.filter(form => form?.strReportId === reportId));
      const columns: ColumnsType<CompareColumnType> = [...basicColumns];
      const reportData: CompareColumnType[] = [];
      if (currentForm !== undefined) {
        setDataLoading(true);
        sendRequest<HistoryCompareReport>({
          url: RequestPath.HISTORY_COMPARE,
          contentType: 'application/json;charset=utf-8',
          method: 'POST',
          body: JSON.stringify(currentForm)
        }).then(result => {
          setDataLoading(false);
          if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
            const form = result?.resBody;
            setChartData(form);
            Object.entries(form?.strCustomDataSet ?? {}).forEach(dataSet => {
              columns.push({
                title: dataSet[0],
                dataIndex: dataSet[0],
                render: (value, record) => {
                  return Tools.renderConstValue(record.reportData[dataSet[0]] ?? '--');
                }
              });
            });
            if (Object.entries(form?.strCustomDataSet ?? {}).length === 2) {
              columns.push({
                title: '差值',
                dataIndex: 'minus',
                render: (value, record) => {
                  const values = Object.entries(record.reportData).map(dataset => dataset[1]);
                  if (values.filter(value => !/^(-)?\d+(\.\d*)?$/.test(value)).length > 0) {
                    return '--';
                  }
                  if (values.length !== 2) {
                    return '--';
                  }
                  return Tools.numberAccuracy(Math.abs(_.subtract(Number(values[0]), Number(values[1]))));
                },
              })
            }
            const maxLength = _.max(Object.entries(form?.strCustomDataSet ?? {}).map(dataSet => dataSet[1].length)) ?? 0;
            new Array(maxLength).fill(0).forEach((item, index) => {
              const data: {[key: string]: string} = {};
              Object.entries(form?.strCustomDataSet ?? {}).forEach(dataSet => {
                data[dataSet[0]] = dataSet[1][index] ?? '--';
              })
              reportData.push({
                reportType: form?.strCustomType ?? '',
                reportData: data,
              })
            })
          } else {
            setChartData(undefined);
            message.error('无法获取对比数据，请联系系统管理员');
          }
          setReportData(reportData)
          setColumns(columns);
        }).catch(() => {
          setDataLoading(false);
          message.error('无法获取对比数据，请联系系统管理员');
          setChartData(undefined);
        });
      } else {
        message.warn('报表不存在，请刷新页面检查');
      }
    } else {
      setChartData(undefined);
      setReportData([])
      setColumns(basicColumns);
    }
  }, [routerParams?.reportId, reportForms])

  useEffect(() => {
    getReportData();
  }, [getReportData]);

  const deleteReport = (formId?: string) => {
    if (formId !== undefined && routerParams.projectId !== undefined) {
      props.setLoading(true);
      const projectId = hashids.decode(routerParams.projectId);
      sendRequest<string>({
        url: `${RequestPath.DELETE_HISTORY_COMPARE_REPORT}?reportId=${formId}&pjid=${projectId}`,
        method: 'POST',
      }).then(result => {
        props.setLoading(false);
        if (result?.resCode === ResultCode.OK) {
          message.success('成功删除一条历史比较报表');
          if (hashids.decode(routerParams?.reportId ?? '').toString() === formId) {
            history.push(`/dev/${routerParams?.projectId ?? ''}/${routerParams?.nodeId}/${routerParams?.deviceId}/${Routers.COMPARE}/`);
            getHistoryReportList();
          } else {
            getHistoryReportList();
          }
        } else {
          message.error('无法删除此报表，请联系系统管理员');
        }
      }).catch(err => {
        props.setLoading(false);
        message.error('无法删除此报表，请联系系统管理员');
        error(err);
      });
    }
  }

  const exportReport = (event: React.MouseEvent<HTMLSpanElement>, form: HistoryCompareReport) => {
    event.preventDefault();
    event.stopPropagation();
    if (form?.strReportId === undefined) {
      return;
    }
    const currentForm = _.head(reportForms?.filter(f => f?.strReportId === form?.strReportId));
    const a = document.createElement('a');
    a.download = `报表_${form?.strCustomName ?? '--'}.csv`;
    const title = ['时间间隔'];
    Object.entries(form?.strCustomDataSet ?? {}).forEach(dataSet => {
      title.push(dataSet[0] ?? '--');
    });
    if (Object.entries(form?.strCustomDataSet ?? {}).length === 2) {
      title.push('差值');
    }
    let dlText = title.join(',') + '\n';
    let tempContent: string[] = [];
    if (currentForm !== undefined) {
      setLoading(true);
      sendRequest<HistoryCompareReport>({
        url: RequestPath.HISTORY_COMPARE,
        contentType: 'application/json;charset=utf-8',
        method: 'POST',
        body: JSON.stringify(currentForm)
      }).then(result => {
        if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
          const form = result?.resBody;
          const maxLength = _.max(Object.entries(form?.strCustomDataSet ?? {}).map(dataSet => dataSet[1].length)) ?? 0;
          new Array(maxLength).fill(0).forEach((item, index) => {
            tempContent = [`${index + 1}${reportTypeMapper[form?.strCustomType ?? ''] ?? ''}`];
            Object.entries(form?.strCustomDataSet ?? {}).forEach(dataSet => {
              tempContent.push(Tools.renderConstValue(dataSet[1][index] ?? '--'));
            })
            if (Object.entries(form?.strCustomDataSet ?? {}).length === 2) {
              const values = [tempContent[1], tempContent[2]];
              if (values.filter(value => !/^(-)?\d+(\.\d*)?$/.test(value)).length > 0 || values.length !== 2) {
                tempContent.push('--');
              } else {
                tempContent.push(Tools.numberAccuracy(Math.abs(_.subtract(Number(values[0]), Number(values[1])))) + '');
              }
            }
            dlText = dlText + tempContent.join(',')+'\n';
          })
          dlText = encodeURIComponent(dlText);
          a.href = `data:text/csv;charset=utf-8,\ufeff${dlText}`;
          a.click();
        } else {
          message.warn('未找到此报表包含的数据');
        }
        setLoading(false);
      }).catch(() => {
        message.warn('未找到此报表包含的数据');
        setLoading(false);
      });
    } else {
      message.warn('报表不存在，请刷新页面检查');
    }
  }

  const currentReport = useMemo(() => {
    const reportId = hashids.decode(routerParams?.reportId ?? '').toString();
    return _.head(reportForms?.filter(form => form?.strReportId === reportId));
  }, [routerParams.reportId, reportForms]);

  return <div className={styles.main}>
    <div className={`${styles.panel} ${styles.main_data_panel}`}>
      <div className={`${styles.panel_title} ${styles.panel_title}`}>
        <div className={styles.panel_title_text}><Tooltip
          title={currentReport?.strCustomName ?? ''} placement={'topLeft'} mouseEnterDelay={0.5}>{currentReport?.strCustomName ?? ''}</Tooltip></div>
        <Tabs activeKey={showType} onChange={(key) => setShowType(key)}>
          <TabPane tab={<AreaChartOutlined className={styles.panel_title_icon}/>} key="chart"/>
          <TabPane tab={<TableOutlined className={styles.panel_title_icon}/>} key="table"/>
        </Tabs>
      </div>
      <div className={styles.data_content}>
        {
          (currentReport !== undefined && (currentReport?.strCustomDataSet?.length ?? 0)) > -1 ?
            (showType === 'table' ? <Table columns={columns} dataSource={reportData} pagination={false} loading={dataLoading}/> :
              (dataLoading ? <div className={styles.center_wrap}><Spin/></div> : <ChartPanel reportData={chartData}/>)) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
        }
      </div>
    </div>
    <div className={`${styles.panel} ${styles.main_data_item}`}>
      <div className={styles.panel_title}><Search placeholder={'请输入报表名称'} onSearch={(value) => setFormSearch(value)}/>
      </div>
      <div className={styles.content}>
        {
          reportForms !== undefined && reportForms.length > 0 ? reportForms?.filter(form => formSearch === '' || (form?.strCustomName?.indexOf(formSearch) ?? -1) > -1)?.map((form, index) => (
            <div
              onClick={() => history.push(`/dev/${routerParams?.projectId}/${routerParams?.nodeId}/${routerParams?.deviceId}/${Routers.COMPARE}/${hashids.encode(form?.strReportId ?? '')}`)}
              className={`${styles.content_item} ${currentReport?.strReportId === form?.strReportId ? styles.active : ''}`}
              key={`${form?.strReportId}_${index}`}>
              <div className={styles.title}>
                <div className={styles.history_type_panel}>
                  <div className={styles.title_date_type}>{dateTypeMapper[form?.strCustomType ?? ''] ?? '--'}</div>
                  <Tooltip title={form?.strCustomDateLst ?? '--'} mouseEnterDelay={0.6}><div className={styles.history_type_panel_date_detail}>{form?.strCustomDateLst ?? '--'}</div></Tooltip>
                </div>
                <div className={styles.title_report_title}><Tooltip title={form?.strCustomName ?? '--'}
                                                                    mouseEnterDelay={0.6}>{form?.strCustomName ?? '--'}</Tooltip>
                </div>
              </div>
              <div className={styles.menu_group}>
                {/*<span className={styles.menu_group_menu} onClick={(event) => {*/}
                {/*  event.preventDefault();*/}
                {/*  event.stopPropagation();*/}
                {/*  setReportSetter({*/}
                {/*    show: true,*/}
                {/*    current: form,*/}
                {/*  });*/}
                {/*}}>编辑</span>*/}
                <span className={styles.menu_group_menu} onClick={(event) => exportReport(event, form)}>导出</span>
                <Popconfirm
                  title="确定要删除此报表吗?"
                  onConfirm={(event) => {
                    if (event !== undefined) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                    deleteReport(form.strReportId);
                  }}
                  onCancel={(event) => {
                    if (event !== undefined) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  okText="是"
                  cancelText="否"
                >
                <span className={`${styles.menu_group_menu} ${styles.danger}`} onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}>删除</span>
                </Popconfirm>
              </div>
            </div>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
        }
      </div>
      <div className={styles.btn}>
        <Button onClick={() => setReportSetter({
          show: true,
        })} type="primary" icon={<FileAddOutlined/>}>新增报表</Button>
      </div>
    </div>
    <Drawer visible={reportSetter.show} title={'历史比较报表'} onClose={() => setReportSetter({
      show: false,
    })} width={600} destroyOnClose={true}>
      <CompareSetter type={'dev'} dev={device} current={reportSetter.current} exitDraw={() => {
        setReportSetter({
          show: false,
        });
        getHistoryReportList();
      }} cancelDraw={() => {
        setReportSetter({
          show: false,
        });
      }}/>
    </Drawer>
  </div>
}

export default HistoryCompareDevice;
