export enum Routers {
  BASE_INTO = 'base',
  DATA_BOARD = 'data-board',
  VIDEO = 'video',
  REAL_REPORT = 'real_report',
  HISTORY_REPORT = 'history_report',
  COMPARE = 'compare',
  SCADA = 'scada',
  ALARM = 'alarm',
  HEALTH = 'health',
  HEALTH_LOG = 'health_log',
}

export const routerNames: {[key: string]: string} = {
  [Routers.BASE_INTO]: '概况',
  [Routers.DATA_BOARD]: '数据看板',
  [Routers.VIDEO]: '视频监控',
  [Routers.REAL_REPORT]: '实时报表曲线',
  [Routers.COMPARE]: '历史比较',
  [Routers.SCADA]: '组态界面',
  [Routers.ALARM]: '报警事件',
  [Routers.HEALTH]: '设备维保 ',
  [Routers.HEALTH_LOG]: '设备检修日志',
};

export const routerDecide: {[key: string]: Routers[]} = {
  'dev': [Routers.BASE_INTO, Routers.DATA_BOARD, Routers.REAL_REPORT, Routers.HISTORY_REPORT, Routers.COMPARE, Routers.ALARM, Routers.HEALTH, Routers.HEALTH_LOG],
  'pj': [Routers.BASE_INTO, Routers.DATA_BOARD, Routers.REAL_REPORT, Routers.HISTORY_REPORT, Routers.COMPARE, Routers.ALARM, Routers.SCADA, Routers.VIDEO],
}

export interface RouterParams {
  nodeId?: string,
  projectId?: string,
  deviceId?: string,
  menu?: Routers,
}