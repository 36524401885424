export interface DeviceReportForm {
  intTargetId?: string,
  intReportId?: string,
  strReportName?: string,
  strStartDateTime?: string,
  strEndDateTime?: string,
  strReportType?: DateType,
  strReportSpace?: string,
  strTargetType?: string,
  lstDeviceParams?: DeviceParamInfo[],
}

export interface HistoryCompareReport {
  strReportId?: string,
  strProjectId?: string,
  strReportType?: string,
  strDeviceId?: string,
  strCategory?: string,
  strDataoffset?: string,
  strCustomType?: DateType,
  strCustomName?: string,
  strCustomDateLst?: string,
  strDimension?: string,
  strShowDeviceId?: string,
  strCustomDataSet?: {[key: string]: string[]},
}

export enum DateType {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
  DIY = 'diydate',
}

export interface DeviceParamInfo {
  intDeviceid?: string,
  strDeviceName?: string,
  strNetGateId?: string,
  strNetGateName?: string,
  strTitle?: string,
  strKind?: string,
  strMongoDbOffset?: string,
  intKindId?: string,
  strCustomName?: string,
  strActive?: string,
  strEventContent?: string,
  strDimension?: string,
}

export interface TimeDataSet {
  dateTime?: string,
  lstValue?: DetailValue[],
}

export interface DataValue {
  strValue?: string,
  strTime?: string,
}

export interface DetailValue extends DataValue {
  strEventType?: string,
  strEventTypeName?: string,
  strDetailId?: string,
  strDeviceId?: string,
  strDeviceName?: string,
  strDetailName?: string,
  lstDataValue?: DataValue[],
  strCustomName?: string,
  strDimension?: string,
}