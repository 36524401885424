import {FC, useEffect, useState} from 'react';

import styles from './amap.module.less';

import markPointIcon from './icons/mark_point.png';
import noImageIcon from './icons/noimage_5_3.png';
import {RequestPath, sendRequest} from "../../../api/http";
import {ProjectInfo, UserDistribute} from "../../../common/entity/project";
import {ResultCode} from "../../../common/entity/result";
import {message} from "antd";
import {error} from "@daqo/log4js";
import {useHistory} from "react-router-dom";

import Hashids from 'hashids';
import {OSS_PJ} from "../../../utils/constant";

const hashids = new Hashids();

interface AMapToolProps {
  deviceCount?: string,
  projectCount?: string,
  userDistribute?: UserDistribute,
}

const AMapTool: FC<AMapToolProps> = (props) => {
  const [distCluster, setDistCluste] = useState();
  const [map, setMap] = useState();

  const {deviceCount, projectCount, userDistribute} = props;

  const getDateDiff = (startDate: any) => {
    if (!/^\d+$/.test(startDate)) {
      return '--';
    }
    return (`${Math.abs(((new Date(Date.parse(startDate)).getTime() - new Date().getTime())) / (1000 * 60 * 60 * 24)).toFixed(0)}`)
  }

  const setMakers = (info: any, map: any, infowindow: any, extData: any) => {
    return new AMap.Marker({
      icon: markPointIcon,
      extData,
      position: new AMap.LngLat(
        JSON.parse(info.strPosition).lng,
        JSON.parse(info.strPosition).lat,
      ),
      map,
    }).on('click', async (e: any) => {
      const window = initInfoWindow(info);
      window.open(map, e.target.getPosition());
    });
  }

  const setProvinceMarkers = (DistrictCluster: any, $: any, data: any, map: any, infoWindow: any, pgMarkers: any) => {
    const distCluster = new DistrictCluster({
      map,
      autoSetFitView: true,
      getPosition: function (item: any) {
        if (!item || item.strPosition === null) {
          return null;
        }
        const position = JSON.parse(item.strPosition.replace(/'/g, '"'))
        return [position.lng, position.lat]
      },
      //行政区划聚合引擎
      renderOptions: {
        //显示在所辖数据点的平均位置
        getClusterMarkerPosition: DistrictCluster.ClusterMarkerPositionStrategy.AVERAGE_POINTS_POSITION,
        //基础样式
        featureStyle: {
          fillStyle: 'rgba(102,170,0,0.0)', //填充色
          lineWidth: 0, //描边线宽
          strokeStyle: 'rgb(31, 119, 180,0.0)', //描边色
          //鼠标Hover后的样式
          hoverOptions: null
        },
        //特定区划级别的默认样式
        featureStyleByLevel: {
          //全国
          country: {
            fillStyle: 'rgba(49, 163, 84, 0.0)'
          },
          //省
          province: {
            fillStyle: 'rgba(116, 196, 118, 0.0)'
          },
          //市
          city: {
            fillStyle: 'rgba(161, 217, 155, 0.0)'
          },
          //区县
          district: {
            fillStyle: 'rgba(199, 233, 192, 0.0)'
          }
        },
        minHeightToShowSubFeatures: 350,
        minSiblingAvgHeightToShowSubFeatures: 323,
        minSubAvgHeightToShowSubFeatures: 0,
        //显示在所辖数据点的中心位置
        getClusterMarker: (feature: any, dataItems: any) => {
          if (dataItems.length > 0) {
            //判断是否为省级地图
            // if(feature.properties.level == "province" || feature.properties.level == "city"){
            // 现修改为判断是否存在子级区划，如果不存在，那么只显示小帽子，否则显示工程信息
            if (feature.properties.childrenNum !== 0) {
              map.clearInfoWindow();
              map.remove(pgMarkers.filter((marker: any) => marker.w.extData !== undefined && Array.isArray(marker.w.extData) && marker.w.extData.indexOf(feature.properties.adcode) > -1));
              pgMarkers = pgMarkers.filter((marker: any) => marker.w.extData === undefined || !Array.isArray(marker.w.extData) || marker.w.extData.indexOf(feature.properties.adcode) < 0);
              return new AMap.Marker({
                map: map,
                position: feature.properties.centroid,
                content: "<img class='map_marker' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA8CAMAAAAEyYk6AAABYlBMVEUAAAAAAAADAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD+/gAAAAAAAAAAAACSkgD+/gD8/AD//wD8/AD6+gD7+wA2NgAREQDY2AD5+QD09ADx8QDu7gD+/gDj4wDr6wD9/QDe3gDq6gDKygDGxgDKygC3twCtrQD6+gCPjwCHhwC1tQBhYQD29gBXVwDs7AAkJADx8QDw8ABERAAeHgALCwDj4wDf3wDGxgDd3QBGRgD+/gDw8ADv7wD5+QD5+QD//wAAAAB/fwC8vAB9fQAHBwB6egBxcQDNzQDGxgAMDADn5wDKygCsrABZWQBTUwBISAA0NAAYGAASEgDz8wDAwACjowCWlgDf3wDT0wC1tQCQkABqagBhYQBPTwA6OgAlJQDh4QDc3AC4uACEhABAQAAoKAAiIgARx8qQAAAATnRSTlMAAwYHDBkTChAtIyYWQjUfHVROSjkxWEY+/VErPA327uTIu5VqXyD17enk3NfRzc3HvLeuq6SgkIh9eXdqYmJeVk9NODcwGw8L8N3dhoR6l9iuAAADg0lEQVRIx63Wd3PaMBgG8CZgMDb1AGwgbtqkGV3Ze+/VJUEI2Xvv7u/fV7KwbJQAucvzh+uz+N2j11fbefHsqaurW16Gw9MQpL2lCaGmlnZy/gTW+O01onn9vZHTqi40DW2lNE2HqKzO6heaUSDNC/UiFVn7UBaVJTvULlBhuHEYTszn8cCoYt3MJ/RIPs3wUoEtvkUV8naRUtE1tmRRxWRbyH6FuqUuVDVdS6VS7laaUQ1pXglIcKFJVJZ8DpJHZZkMgfS7cPl9ucM0B+V3KEwkd1H5TWD95Baz3J4EFt7IUSoZjIYj/tXzXWpyOfrP7rn/zkbCUQ/CRuU44tk+xDQIYZrDbcQTl8lmS4Vy3OBLF5vw4zUK2cnmOl81IjKppBAKJd1b2brHuJBHJYjyBYzvt7xlXYJKCumEkuKtrMKvNxCHaAPkqresSGRKBmGnKmJZhx/vIGIK7IB24JK3WdWIyz6oe7CI8R6CrG2uuQfIHsZFD+p+KOkOu358RDcazAbGR8fs3NGlEgyREWPs+i+M95GQfYwv2WmMDBkSYDFwI/w3rFCsBPMYsi3CbQzJV4A5GIYUipUweq4yzGUfglurAhS2enh9ehHAxxtnu4UqW0UH2M2/u72b4upa8Wb/4JBd2kcPQ1ay8wc/mL08e6wE6D3I62e7fwNm8+D69If3IAuwB/nz82Tn7Cr3++r0cn0rsNAjwFFUU0YFON9Zi+ucZ5D/J9dmv1Z3X2Y1R5eCj5WWmeuv9gnon8to7LHiD7KVepmc6K7kuieSL1NW4EGmQ2qZdMIc+fgY+zhiJtIZzR2x3veyopUJs23w3UPs3WCbmaCFvpcV2WvEgMpXRNqtveKnvLfVJu4VFLLXI38hGyqTpj3WEXQdY7bJHJmQ7RSgO6VOZTrRYNr28AfOPgzbttmQSFOnS+wTwD86VFqpTDpJaNtA6Q+kgTbCkulMyqKOfXQ8GQZpKI4FpS5t7XuP0Pu+VspIneUoRsDxTpC0lFF7aspmjNaJDiBImDMu6YoT0xhtMM0GxrSYo+hSHOYD50EuSamuOpZLk4lE0mWWo+qkjjuRxul+KaUB5u4yXsbE0hLVUhCtxMQ6kdJRVScWs6xYzFEVXWSi5K26oqiqoui8TXBCqUslyTDgQBmvq07lCI0ssMoUNswSDVVi4qhgQ1AFig9XE6WYIM5qxgw9d/4Dz1TO4z+oFCsAAAAASUVORK5CYII='>" +
                  "<div class='mapCity'><div class='city_address'>" + feature.properties.name + "</div><div class= 'colon'>:</div><div class='sbsl'>" + dataItems.length + "</div></div>"
              });
            } else {
              $(dataItems).each(function (n: any, e: any) {
                pgMarkers.push(setMakers(e.dataItem, map, infoWindow, feature.properties.acroutes));
              });
            }
          }
        },
      },
    });
    distCluster.setData(data);
    return distCluster;
  }

  const changeStringToArr = (str: any, s: any) => ((str === null || str === undefined || str.length < 1) ? [] : str.split(s))

// 创建地图
  const createMap = (target: any, zoom: any) => {
    return new AMap.Map(target, {
      zoom: zoom,
      expandZoomRange: true,
      showLabel: false,
      mapStyle: 'amap://styles/d6bf59b1a217f6321840253f9021df05',//样式URL
      zooms: [4, 20],
      center: [110.397428, 36.90923],
      layers: [
        new AMap.TileLayer.Satellite(),
        new AMap.TileLayer.RoadNet()
      ]
    });
  }

  const initInfoWindow = (info: any) => {
    const pjImg = changeStringToArr(info.strImg, ',')[0] === undefined ? noImageIcon : `${OSS_PJ}/${info.intProjectId}/${changeStringToArr(info.strImg, ',')[0]}`;
    const con = `<div class='big_box'><div class='box_left' style='background: url("${pjImg}") no-repeat center center;background-size: 100% 100%;'><div class='big_text'><div class='lay_tit'><a class='hrefto' href='${`#/pj/${hashids.encode(info.intProjectId)}/base`}' data-id='${info.intProjectId}' id='pjName'>${info.strPjName}</a></div>
                <div class='lay_adress'>${info.strPositionForShow}</div></div></div><div class='ldt_R'><div class='ldt_state'><span style='font-size: 0.24rem;line-height: 0.50rem;'>
                ${getDateDiff(info?.strActiveTime ?? '')}天</span><p class='yxqk'>运行时间</p></div><div class='ldt_state'><span>${info?.alarmToday ?? '--'}</span><p class='yxqk'>本日报警
                </p></div><div class='ldt_state'><span>${info?.eventToday ?? '--'}</span><p class='yxqk'>本日事件</p></div></div></div>`;
    return new AMap.InfoWindow({content: con, offset: new AMap.Pixel(0, -20)});
  }

  useEffect(() => {
    const map = createMap('daqo_amap', 5);
    setMap(map);
    AMapUI.load(['ui/geo/DistrictCluster', 'lib/$', 'ui/overlay/SimpleInfoWindow'], (DistrictCluster: any, $: any) => {
      sendRequest<ProjectInfo[]>({
        url: `${RequestPath.GET_DEVICE_STRUCTURE}?type=display`,
      }).then(result => {
        if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
          setDistCluste(setProvinceMarkers(DistrictCluster, $, result?.resBody ?? [], map, [], []))
        } else {
          message.error('无法获取到工程设备列表，请联系系统管理员');
        }
      }).catch(err => {
        error(err);
      })
    });
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.leftTop}/>
      <div className={styles.rightTop}/>
      <div className={styles.left}/>
      <div className={styles.right}/>
      <div className={styles.leftBottom}/>
      <div className={styles.rightBottom}/>
      <div className={styles.map} id={'daqo_amap'}/>
      <div className={styles.layer}>
        <div className={styles.layer_panel}>
          <div className={styles.layer02_data}>
            <h4>设备总数</h4>
            <div className={styles.device_count}>
              <div className={styles.hbls_number}>{deviceCount ?? '--'}</div>
              <span style={{fontSize: '0.18rem'}}>台</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.project_type}>
        <div className={`${styles.type_gczs} ${styles.hbls_sizemin}`}>
          <div className={styles.title}>工程总数</div>
          <div className={styles.gczs_panel}>
            <span className={styles.hbls_number}>{projectCount ?? '--'}</span>
            <span className={styles.project_mun}>个</span>
          </div>
        </div>

        <div className={`${styles.type_zcywry} ${styles.hbls_sizemin}`}>
          <div className={styles.title}>注册操作员</div>
          <div className={styles.gczs_panel}>
            <span className={styles.hbls_number}>{userDistribute?.strWokerCount ?? '--'}</span>
            <span className={styles.project_mun}>人</span>
          </div>
        </div>

        <div className={`${styles.type_zcywry} ${styles.type_zcsgry} ${styles.hbls_sizemin}`}>
          <div className={styles.title}>注册施工人员</div>
          <div className={styles.gczs_panel}>
            <span className={styles.hbls_number}>{userDistribute?.strConstructorCount ?? '--'}</span>
            <span className={styles.project_mun}>人</span>
          </div>
        </div>

        <div className={`${styles.type_zcywry} ${styles.type_zczj} ${styles.hbls_sizemin}`}>
          <div className={styles.title}>注册专家</div>
          <div className={styles.gczs_panel}>
            <span className={styles.hbls_number}>{userDistribute?.strExpertCount ?? '--'}</span>
            <span className={styles.project_mun}>人</span>
          </div>
        </div>

        <div className={`${styles.type_zcywry} ${styles.type_zcyz} ${styles.hbls_sizemin}`}>
          <div className={styles.title}>注册业主</div>
          <div className={styles.gczs_panel}>
            <span className={styles.hbls_number}>{userDistribute?.strOwnerCount ?? '--'}</span>
            <span className={styles.project_mun}>人</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AMapTool;
