import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import styles from './modules.module.less';
import {ConstantKey, pwdPattern} from "../../utils/constant";
import {Button, message, Result, Menu, Modal, Form, Input, Avatar} from 'antd';
import {UserOutlined} from "@ant-design/icons";
import {isUserToken, LoginParamInfo} from "../../common/entity/user";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {RequestPath, sendRequest} from "../../api/http";
import {ResultCode} from "../../common/entity/result";

const {SubMenu} = Menu;

interface ModulesProps {
  setLoading: (showLoading: boolean) => void,
}

const Modules: FC<ModulesProps> = (props) => {
  const energyRef = useRef<HTMLIFrameElement>(null);
  const monitorRef = useRef<HTMLIFrameElement>(null);
  const configRef = useRef<HTMLIFrameElement>(null);
  const miniRef = useRef<HTMLIFrameElement>(null);
  const svgRef = useRef<HTMLIFrameElement>(null);
  const pwdRef = useRef<FormInstance>(null);
  const history = useHistory();
  const [moduleReady, setModuleReady] = useState<boolean>();
  const [pwdEditor, setPwdEditor] = useState<boolean>(false);

  const {setLoading} = props;

  useEffect(() => {
    const user = localStorage.getItem(ConstantKey.USER_PWD_STORE);
    if (user !== null) {
      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve('ok')
        }, 1200);
      })
      const energyPromise = new Promise(((resolve, reject) => {
        if (energyRef.current !== null) {
          energyRef.current.onload = () => {
            if (energyRef.current !== null)
              energyRef?.current?.contentWindow?.postMessage(user, '*');
            resolve('energy ok...');
          }
        } else {
          reject('energy error');
        }
      }));
      // const monitorPromise = new Promise((resolve, reject) => {
      //   if (monitorRef.current !== null) {
      //     monitorRef.current.onload = () => {
      //       if (monitorRef.current !== null)
      //         monitorRef?.current?.contentWindow?.postMessage(user, '*');
      //       resolve('monitor ok...');
      //     }
      //   } else {
      //     reject('monitor error');
      //   }
      // });
      const configPromise = new Promise((resolve, reject) => {
        if (configRef.current !== null) {
          configRef.current.onload = () => {
            if (configRef.current !== null)
              configRef?.current?.contentWindow?.postMessage(user, '*');
            resolve('monitor ok...');
          }
        } else {
          reject('monitor error');
        }
      });
      const miniPromise = new Promise((resolve, reject) => {
        if (miniRef.current !== null) {
          miniRef.current.onload = () => {
            if (miniRef.current !== null)
              miniRef?.current?.contentWindow?.postMessage(user, '*');
            resolve('monitor ok...');
          }
        } else {
          reject('monitor error');
        }
      });
      const svgPromise = new Promise((resolve, reject) => {
        if (svgRef.current !== null) {
          svgRef.current.onload = () => {
            if (svgRef.current !== null)
              svgRef?.current?.contentWindow?.postMessage(user, '*');
            resolve('monitor ok...');
          }
        } else {
          reject('monitor error');
        }
      });
      // Promise.all([timeoutPromise, energyPromise, configPromise, miniPromise, svgPromise]).then(() => {
      Promise.all([timeoutPromise]).then(() => {
        setModuleReady(true);
      }).catch(() => {
        message.error('');
      });
    } else {
      message.error('登录态失效，请重新登录');
      setTimeout(() => {
        history.push('/login')
      }, 1000);
    }
  }, []);

  const user = localStorage.getItem(ConstantKey.USER);
  if (user === null) {
    message.error('登录态失效，请重新登录');
    setTimeout(() => {
      history.push('/login')
    }, 1000);
  }

  const currentUser: LoginParamInfo | null = useMemo(() => {
    if (user !== null) {
      const userToken = JSON.parse(user);
      if (isUserToken(userToken)) {
        return userToken;
      }
      return null;
    }
    return null;
  }, [user]);

  const editPwd = () => {
    const pwdInstance = pwdRef.current;
    if (pwdInstance !== null) {
      pwdInstance.validateFields().then(() => {
        const oldPwd = pwdInstance.getFieldValue('oldPwd');
        const newPwd = pwdInstance.getFieldValue('newPwd');
        const confirmNewPwd = pwdInstance.getFieldValue('confirmNewPwd');
        if (oldPwd === '') {
          message.warn('原密码不能为空');
          return;
        } else if (!pwdPattern.test(newPwd)) {
          message.warn('新密码长度必须大于8位且必须包含字母和数字');
          return;
        } else if (newPwd !== confirmNewPwd) {
          message.warn('两次输入的密码不一致');
          return;
        }
        setLoading(true);
        sendRequest<string>({
          url: `${RequestPath.UPDATE_USER_PASSWORD}?newpwd=${newPwd}&oldPwd=${oldPwd}`,
          method: 'POST',
        }).then(result => {
          setLoading(false);
          if (result.resCode === ResultCode.OK) {
            exit('密码修改成功，即将重新登录...');
          } else {
            message.error('无法修改密码，请检查原密码是否正确');
          }
        }).catch(() => {
          setLoading(false);
        });
      }).catch();
    }
  }

  const exit = (msg: string) => {
    const modal = Modal.success({
      content: msg,
    });
    localStorage.removeItem(ConstantKey.USER);
    localStorage.removeItem(ConstantKey.USER_PWD_STORE);
    const timeoutPromise = new Promise(resolve => {
      setTimeout(() => {
        resolve('ok')
      }, 1200);
    })
    Promise.all([
      timeoutPromise,
      // moduleInit('https://cckchuankai-cloud.com/energy/remove-user-auth.html', ''),
      // // moduleInit('https://cckchuankai-cloud.com/dq-monitor/remove-user-auth.html', ''),
      // moduleInit('https://cckchuankai-cloud.com/dq-config/remove-user-auth.html', ''),
      // moduleInit('https://cckchuankai-cloud.com/digital-admin/remove-user-auth.html', ''),
      // moduleInit('https://cckchuankai-cloud.com/svg/remove-user-auth.html', '')
    ]).then(() => {
      if (modal !== null) {
        modal.destroy();
      }
      history.push('/login');
    }).catch(() => {
      if (modal !== null) {
        modal.destroy();
      }
      history.push('/login');
    })
  }

  const moduleInit = (url: string, message: string) => {
    return new Promise(((resolve, reject) => {
      const module = document.createElement('iframe');
      module.style.display = 'none';
      module.src = url;
      module.onload = () => {
        if (module.contentWindow !== null) {
          module.contentWindow.postMessage(message, '*');
          resolve('ok');
        } else {
          reject('error');
        }
      }
      document.body.appendChild(module);
    }))
  }

  const checkAdmin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (currentUser?.userInfo?.strUsername === 'admin') {
      e.preventDefault();
      e.stopPropagation();
      message.warn('管理员账号不能登录到SVG配置工具中，请切换登录账号');
      return false;
    }
  }

  return <div className={styles.modules}>
    <div className={styles.modules_banner}>
      {/*<span className={styles.title}>川开电气</span>*/}
      <span className={styles.logo}/>
      <Menu mode="horizontal">
        <SubMenu className={styles.menu_styles} key={'user'} title={<><Avatar icon={<UserOutlined/>} className={styles.account_avatar}/>{currentUser?.userInfo?.strName ?? '--'}</>}>
          <Menu.Item key="edit" onClick={() => setPwdEditor(true)}>修改密码</Menu.Item>
          <Menu.Item key="exit" onClick={() => exit('退出成功，即将重新登录...')}>退出</Menu.Item>
        </SubMenu>
      </Menu>
    </div>
    <div className={styles.modules_content}>
      {
        moduleReady === undefined ? <div className={styles.spinner_ellipsis}>
          <div className={styles.ldio}>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>
          </div>
        </div> : (moduleReady ? <>
            <a href={'/dq-config/index.html'} target="_blank" className={styles.moduleMargin}
               rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-peizhiguanli`}/>
                </div>
                <div className={styles.module_name}>工程配置</div>
              </div>
            </a>
            <a href={'/brand/admin?username=cck&password=cck'} target="_blank" className={styles.moduleMargin}
               rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-jieshao`}/>
                </div>
                <div className={styles.module_name}>数字厂牌</div>
              </div>
            </a>
            <a href={'/svg/#/login'} target="_blank" className={styles.moduleMargin} onClick={(e) => checkAdmin(e)}
               rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-jiexiantu`}/>
                </div>
                <div className={styles.module_name}>SVG工具</div>
              </div>
            </a>
            <a href={'/digital-admin'} target="_blank" className={styles.moduleMargin}
               rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-xiaochengxu`}/>
                </div>
                <div className={styles.module_name}>数字川开</div>
              </div>
            </a>
            <Link to={'/monitor'} target={'_blank'} className={styles.moduleMargin}>
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-jiancedating`}/>
                </div>
                <div className={styles.module_name}>配电监测</div>
              </div>
            </Link>
            {/*<a href={'https://cckchuankai-cloud.com/dq-monitor'} target="_blank" className={styles.moduleMargin} rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-jiancedating`}/>
                </div>
                <div className={styles.module_name}>配电监测</div>
              </div>
            </a>*/}
            <a href={'/energy/main'} target="_blank" className={styles.moduleMargin}
               rel="noreferrer">
              <div className={styles.module}>
                <div className={styles.module_icon}>
                  <i className={`daqo-iconfont daqo-icon-nengyuannenghaoguanli`}/>
                </div>
                <div className={styles.module_name}>能耗管理</div>
              </div>
            </a>
          </> : <Result
            status="warning"
            title="账号或模块权限出现问题，请联系系统管理员"
            extra={
              <Button type="primary" key="console" onClick={() => history.push('/login')}>
                重新登录
              </Button>
            }
          />
        )
      }
    </div>
    <Modal title="修改密码" visible={pwdEditor} onOk={editPwd} onCancel={() => setPwdEditor(false)}>
      <Form layout={'vertical'} onValuesChange={() => {
        return false;
      }} ref={pwdRef}>
        <Form.Item label={'旧密码'} name={'oldPwd'} rules={[{required: true, message: '旧密码不能为空'}]}>
          <Input/>
        </Form.Item>
        <Form.Item label={'新密码'} name={'newPwd'} rules={[{required: true, message: '新密码不能为空'}, {pattern: pwdPattern, message: '密码长度必须大于8位且必须包含字母和数字'}]}>
          <Input.Password/>
        </Form.Item>
        <Form.Item dependencies={['newPwd']} label={'确认新密码'} rules={[{required: true, message: '新密码需要确认'}, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPwd') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('两次输入的密码不一致!'));
          },
        })]} name={'confirmNewPwd'} >
          <Input.Password/>
        </Form.Item>
      </Form>
    </Modal>
    {/*<iframe src={'https://cckchuankai-cloud.com/energy/user-auth.html'} className={styles.modules_hidden}*/}
    {/*        ref={energyRef}/>*/}
    {/*/!*<iframe src={'https://cckchuankai-cloud.com/dq-monitor/user-auth.html'} className={styles.modules_hidden}*!/*/}
    {/*/!*        ref={monitorRef}/>*!/*/}
    {/*<iframe src={'https://cckchuankai-cloud.com/dq-config/user-auth.html'} className={styles.modules_hidden}*/}
    {/*        ref={configRef}/>*/}
    {/*<iframe src={'https://cckchuankai-cloud.com/digital-admin/user-auth.html'} className={styles.modules_hidden}*/}
    {/*        ref={miniRef}/>*/}
    {/*<iframe src={'https://cckchuankai-cloud.com/svg/user-auth.html'} className={styles.modules_hidden}*/}
    {/*        ref={svgRef}/>*/}
  </div>
}

export default Modules;
