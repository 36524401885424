import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import RealtimeReportDevice from "../../pages/report/realtime-report/realtime-report.device";

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RealtimeReportDevice);
