export interface Result<T> {
  resCode: string,
  resBody?: T,
  resMessage?: string,
  code?: number,
  body?: T,
}

export enum ResultCode {
  OK = 'ok',
  AUTH = 'auth',
  ERROR = 'err',
}

export interface PageData<T> {
  totalCount?: number,
  pageNum?: number,
  pageCount?: number,
  resultData?: T[],
}