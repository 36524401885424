import LoadingTypes from './loading.types';
import {Dispatch} from "redux";
import {AppAction} from "../app/app.reducer";

export const setLoading = (showLoading: boolean) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: LoadingTypes.SHOW_LOADING,
    data: showLoading,
  })
}
