import axios from "axios";
import {Result, ResultCode} from "../common/entity/result";
import {isUserToken, LoginParamInfo} from "../common/entity/user";
import {ConstantKey} from "../utils/constant";
import store from '../redux/store/store-creator';
import LoginTypes from "../redux/login/login.types";
import {message} from "antd";

// https://dqcloud-apis.daqo-cloud.com/dqcloud-venus/release/
// http://124.70.202.153:30080/dqcloud-venus/baseline/

const baseUrl = process.env.REACT_APP_API_URL ?? '';
const dictUrl = process.env.REACT_APP_DICT_API_URL ?? '';

const urlMapper: {[key: string]: string} = {
  base: baseUrl,
  dict: dictUrl,
}

export interface RequestParam {
  url: RequestPath | string,
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH' | 'OPTIONS',
  body?: FormData | string | ArrayBuffer | Blob,
  contentType?: 'application/json' | 'application/octet-stream' | 'text/plain' | 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'application/json;charset=utf-8',
  timeout?: number,
  resultType?: 'json' | 'blob' | 'text' | 'arraybuffer' | 'stream' | 'document',
  needToken?: boolean,
  onUploadProgress?: (progressEvent: unknown) => void,
  onDownloadProgress?: (progressEvent: unknown) => void,
  urlType?: 'base' | 'dict',
}

export const sendRequest = <T>(
  {
    url,
    method = 'GET',
    body,
    contentType = undefined,
    timeout = 120000,
    needToken = true,
    onUploadProgress,
    onDownloadProgress,
    resultType = 'json',
    urlType = 'base',
  }: RequestParam
): Promise<Result<T>> => {
  let loginUser: LoginParamInfo | null = null;
  const localToken = localStorage.getItem(ConstantKey.USER);
  if (needToken && localToken === null) {
      store.dispatch({
        type: LoginTypes.SHOW_LOGIN,
        data: true,
      });
    return new Promise((resolve, reject) => {
      reject('no token');
    })
  } else if (needToken && localToken !== null) {
    try {
      const json = JSON.parse(localToken);
      if (isUserToken(json)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        loginUser = json;
      }
    } catch (err) {
      message.warn('非法的登录票据，请您重新登录');
      store.dispatch({
        type: LoginTypes.SHOW_LOGIN,
        data: true,
      });
      return new Promise((resolve, reject) => {
        reject('invalid token');
      })
    }
  }
  return axios({
    url: `${urlMapper[urlType] ?? baseUrl}${url}`,
    method,
    responseType: resultType,
    onUploadProgress,
    onDownloadProgress,
    timeout,
    headers: {
      ...contentType !== undefined ? {'Content-Type': contentType} : {},
      ...(needToken) ? {
        'Authorization': loginUser?.strToken,
      } : {},
    },
    data: body,
  }).then((result) => result.data).then((result: Result<T>) => {
    if (result.resCode === ResultCode.AUTH) {
      message.warn('登录票据已过期，请您重新登录');
      store.dispatch({
        type: LoginTypes.SHOW_LOGIN,
        data: true,
      });
      throw new Error('auth');
    }
    return result;
  }).catch((err) => {
    throw new Error(err);
  });
}

export enum RequestPath {
  LOGIN = 'be/entry/login',
  SEND_V_CODE = 'be/entry/verification',
  PHONE_LOGIN = 'be/entry/phone',
  RETRIEVE_ACCOUNT = 'be/entry/retrieveAccount',
  GET_DEVICE_STRUCTURE = 'devInfo/getDeviceStructure',
  LIST_NODES_BY_PARENT = 'devInfo/listNodesByParent',
  GET_REAL_DATA_FORMS = 'devInfo/getRealDataForms',
  FLESH_REAL_DATA_FORMS = 'devInfo/fleshRealDataForm',
  GET_PROJECT_INFO = 'devInfo/getProjectInfo',
  DOWNLOAD_PROJECT_FILE = 'devInfo/downProjectDocs',
  GET_DYNAMIC_PARAMS = 'devInfo/getDynamicParams',
  DELETE_DYNAMIC_PARAMS = 'devInfo/deleteDynamicParams',
  GET_DEVICES_BY_PROJECT = 'device/getDevicesByProject/ORIGIN',
  GET_DEVICE_MONITOR_PARAMS = 'devInfo/getDeviceMonitorParams',
  ADD_DYNAMIC_PARAMS = 'devInfo/addDynamicParams',
  UPDATE_DYNAMIC_PARAMS = 'devInfo/updateDynamicParams',
  GET_CAMERA_INFO = 'devInfo/getCameraInfo',
  GET_REPORT_INFO = 'devInfo/getReportInfo',
  FRESH_REPORT_DATA = 'devInfo/freshReportData',
  INSERT_REPORT_INFO = 'devInfo/insertReportInfo',
  UPDATE_DEVICE_REPORT = 'devInfo/updateDeviceReport',
  DELETE_REPORT_INFO = 'devInfo/deleteReportInfo',
  UPDATE_REALDATA_FORM = 'devInfo/updateRealDataForm',
  INSERT_REALDATA_FORM = 'devInfo/insertRealDataForm',
  DELETE_REALDATA_FORM = 'devInfo/deleteRealDataForm',
  GET_DEVICE_EVENT_REPORT = 'devInfo/getDeviceEventReport',
  GET_EVENT_COUNT_FOT_PROJECT = 'devInfo/getEventCountForProject',
  GET_EVENT_COUNT_FOT_DEVICE = 'devInfo/getEventCountForDevice',
  GET_HISTORY_REPORT_LIST = 'devInfo/getHistoryReportList',
  GET_EVENT_DATASET = 'devInfo/getEventDataset',
  CONFIRM_ONE_EVENT = 'devInfo/confirmOneEvent',
  GET_SCADA_LIST = 'devInfo/getScadaList',
  GET_ONE_SCADA_VIEW = 'devInfo/getOneScadaView',
  GET_MAINT_ANALYSIS = 'devInfo/getMaintAnalysis',
  DO_MAINT_ANALYSIS = 'devInfo/doMaintAnalysis',
  IGNOREMAINT = 'devInfo/ignoreMaint',
  MAINTLOGINFO = 'devInfo/maintLogInfo',
  ADD_MAINT_LOG_COMMENT = 'devInfo/addMaintLogComment',
  ADD_HISTORY_COMPARE_REPORT = 'devInfo/addHistoryCompareReport',
  UPDATE_USER_PASSWORD = 'admin/user/updateUserPwd',
  DELETE_HISTORY_COMPARE_REPORT = 'devInfo/deleteHistoryCompareReport',
  GET_DEVICE_INFO = 'devInfo/getDeviceInfo',
  GET_NAME_PLATE_LIST = 'devInfo/getNamePlateList',
  GET_TECHNICAL_FILES = 'devInfo/getTechnicalFiles',
  DOWNLOAD_FILE = 'devInfo/downloadFile',
  MAINT_PUTH_TO_APNS = 'devInfo/maintPushToApns',
  GET_ANALYSIS_BOARD = 'devInfo/getAnalysisBoard',
  GET_VIRTUAL_LIST_FOR_PROJECT = 'devInfo/getVirtualListForProject',
  UPDATE_ANALYSIS_BOARD = 'devInfo/updateAnalysisBoard',
  GET_DEVICE_RUN_STATISTIC = 'module/dashboard/getDeviceRunStatis',
  GET_MODEL_RELIABILITY = 'module/dashboard/getModelReliability',
  GET_DEVICE_STATUS = 'module/dashboard/getDevStatus',
  GET_ALARM_STATISTIC = 'module/dashboard/getAlertStatis',
  DASHBOARD_STATISTIC = 'module/dashboard/showTheStatis',
  GET_DANGER_DEVICE = 'module/dashboard/getDangerDevice',
  GET_MAINT_COSTS_COUNT = 'module/dashboard/getMaintCostsCount',
  GET_DETAILS = 'detail/getDetail/ORIGIN',
  ADD_CAMERA_INFO = 'devInfo/addCameraInfo',
  GET_BREAK_LIST = 'devInfo/getBreakList',
  EXPORT_DEVICE_EVENT = 'devInfo/exportDeviceEvent',
  HISTORY_COMPARE = 'devInfo/dohistoryCompare',
}
