import {ProjectInfo} from "./project";

export interface UserInfo {
  intUserId?: string,
  strAuthority?: string,
  strUsername?: string,
  strPassword?: string,
  strName?: string,
  strJob?: string,
  strAddress?: string,
  strTelephone?: string,
  strEmail?: string,
  pjList?: ProjectInfo[],
  imeiList?: string[],
  strClientIP?: string,
  currentEventId?: number,
  intTimeout?: string,
  strCockpitLogo?: string,
  strBackLogo?: string,
  strCollapseLogo?: string,
}

export interface LoginParamInfo {
  userInfo?: UserInfo,
  strToken?: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUserToken = (obj: any): obj is LoginParamInfo => {
  return 'userInfo' in obj && 'strToken' in obj;
}