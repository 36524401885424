import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import Login from "../../component/relogin/login";
import {setLoading} from "../../redux/loading/loading.action";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading: setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
