import {FC, useEffect, useRef} from 'react';
import videojs from "video.js";
import "video.js/dist/video-js.css";

import styles from './video-js.module.less';

export interface VideoJsProps {
  onReady: (video: videojs.Player) => void,
  options: videojs.PlayerOptions,
}

const VideoJs: FC<VideoJsProps> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const {options, onReady} = props;

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      console.log(options);
      const player = playerRef.current = videojs(videoElement, options, () => {
        console.log("player is ready");
        onReady && onReady(player);
      });
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player className={styles.main}>
      <video ref={videoRef} width={500} height={300} className="video-js vjs-big-play-centered vjs-fluid"/>
    </div>
  );
}

export default VideoJs;
