import AppTypes from './app.types';
import {Route} from "antd/es/breadcrumb/Breadcrumb";

interface AppReducer {
  collapsed: boolean,
  title: Route[],
}

export interface AppAction {
  type: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
}

const initialState: AppReducer = {
  collapsed: false,
  title: [],
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case AppTypes.SET_COLLAPSED:
      return {
        ...state,
        collapsed: action.data,
      };
    case AppTypes.SET_TITLE:
      return {
        ...state,
        title: action.data,
      }
    default:
      return state;
  }
}

export default reducer;
