import {FC, useCallback, useEffect, useRef, useState} from 'react';

import styles from './video.module.less';
import {useParams} from "react-router-dom";
import {RouterParams} from "../app/app.routers";
import {CameraInfo} from "../../common/entity/video";
import Hashids from "hashids";
import {RequestPath, sendRequest} from "../../api/http";
import {Button, Drawer, Empty, Form, Input, message, Space, Tooltip} from "antd";
import {ResultCode} from "../../common/entity/result";
import {error} from "@daqo/log4js";

import VideoJs from "../../component/video/video-js/video-js";
import {FormInstance} from "antd/lib/form/hooks/useForm";

const hashids = new Hashids();

export interface VideoProps {
  setLoading: (showLoading: boolean) => void,
}

const VideoPanel: FC<VideoProps> = (props) => {
  const routerParams = useParams<RouterParams>();
  const formRef = useRef<FormInstance>(null);
  const [cameras, setCameras] = useState<CameraInfo[]>([]);
  const [cameraPanel, setCameraPanel] = useState<boolean>(false);
  const [searchCamera, setSearchCamera] = useState<string>('');

  const {setLoading} = props;

  const getCameraInfo = useCallback(() => {
    if (routerParams?.projectId !== undefined) {
      const projectId = hashids.decode(routerParams?.projectId ?? '').toString();
      setLoading(true);
      sendRequest<CameraInfo[]>({
        url: `${RequestPath.GET_CAMERA_INFO}?pjId=${projectId}`,
      }).then(result => {
        setLoading(false);
        if (result?.resCode === ResultCode.OK && result?.resBody !== undefined) {
          setCameras(result.resBody);
        } else {
          setCameras([]);
          message.error('无法获取工程下的摄像头，请联系系统管理员');
        }
      }).catch(err => {
        error(err);
        message.error('无法获取工程下的摄像头，请联系系统管理员');
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerParams.projectId]);

  const addCamera = () => {
    if (formRef?.current !== null) {
      formRef?.current?.validateFields().then(() => {
        const targetId = hashids.decode(routerParams?.projectId ?? '').toString();
        const name = formRef?.current?.getFieldValue('name');
        const serialCode = formRef?.current?.getFieldValue('serialCode');
        const verifyCode = formRef?.current?.getFieldValue('verifyCode');
        const camera = {
          strDeviceName: name,
          strDeviceSerial: serialCode,
          strValidateCode: verifyCode,
          intProjectId: targetId,
        }
        setLoading(true);
        sendRequest<string>({
          url: RequestPath.ADD_CAMERA_INFO,
          method: 'POST',
          contentType: 'application/json;charset=utf-8',
          body: JSON.stringify(camera),
        }).then(result => {
          setLoading(false);
          if (result?.resCode === ResultCode.OK) {
            message.success('摄像头保存成功');
            setCameraPanel(false);
            getCameraInfo();
          } else {
            message.success('摄像头保存失败，请联系系统管理员');
          }
        }).catch(() => {
          setLoading(false);
          message.success('摄像头保存失败，请联系系统管理员');
        });
      }).catch(() => {})
    }
  }

  useEffect(() => {
    getCameraInfo();
  }, [getCameraInfo]);

  return <div className={styles.main}>
    <div className={`${styles.main_cameras} ${styles.panel}`}>
      <div className={styles.panel_title}>视频画面</div>
      <div className={styles.content}>
        {
          cameras?.length > 0 ? cameras?.filter(camera => searchCamera === '' || camera?.strDeviceName === searchCamera)?.map((camera, index) => (
            <div className={styles.content_video} key={`${camera?.intDeviceId}_${index}`}>
              <VideoJs onReady={() => {
              }} options={{
                aspectRatio: '16:9',
                autoplay: true,
                controls: true,
                controlBar: {
                  'liveDisplay': true,
                  'pictureInPictureToggle': false
                },
                responsive: true,
                fill: false,
                techOrder: ['html5'],
                fluid: true,
                sources: [{
                  src: camera?.hlsnormalPath ?? '',
                  type: 'application/x-mpegURL'
                }]
              }}/>
            </div>
          )) : <div className={styles.main_empty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        }
      </div>
    </div>
    <div className={`${styles.main_cameraPicker} ${styles.panel}`}>
      <div className={styles.panel_title}>
        摄像机列表
        <i className={`daqo-iconfont daqo-icon-tianjia ${styles.add_param}`} onClick={() => setCameraPanel(true)}/>
      </div>
      <div className={styles.content}>
        {
          cameras?.length > 0 ? <>
            <div className={`${styles.content_item} ${searchCamera === '' ? styles.content_active : ''}`}
                 onClick={() => setSearchCamera('')}>全部
            </div>
            {
              cameras?.map((camera, index) => (
                <div onClick={() => setSearchCamera(camera?.strDeviceName ?? '--')}
                     className={`${styles.content_item} ${searchCamera === (camera?.strDeviceName ?? '--') ? styles.content_active : ''}`}
                     key={`${camera.intDeviceId}_${index}`}><Tooltip
                  title={camera?.strDeviceName ?? '--'} mouseEnterDelay={1}>{camera?.strDeviceName ?? '--'}</Tooltip>
                </div>
              ))
            }
          </> : <div className={styles.main_empty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></div>
        }
      </div>
    </div>
    <Drawer visible={cameraPanel} title={'新增摄像头'} placement={'right'} destroyOnClose={true}
            onClose={() => setCameraPanel(false)}>
      <Form ref={formRef} layout={'vertical'} autoComplete="off" initialValues={{
        name: '',
        serialCode: '',
        verifyCode: '',
      }} className={styles.add_camera}>
        <div className={styles.add_camera_params}>
          <Form.Item label={'摄像头名称'} name={'name'} rules={[{required: true, message: '摄像头名称不能为空'}]}>
            <Input/>
          </Form.Item>
          <Form.Item label={'摄像头序列号'} name={'serialCode'} rules={[{required: true, message: '摄像头序列号不能为空'}]}>
            <Input/>
          </Form.Item>
          <Form.Item label={'摄像头验证码'} name={'verifyCode'} rules={[{required: true, message: '摄像头验证码不能为空'}]}>
            <Input/>
          </Form.Item>
        </div>
        <Form.Item>
          <Space>
            <Button type={'primary'} htmlType="submit" onClick={addCamera}>保存</Button>
            <Button onClick={() => setCameraPanel(false)}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  </div>
}

export default VideoPanel;
