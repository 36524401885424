import {FC, useEffect, useState} from "react";

import styles from './cockpit.module.less';
import ChartTitle from "../../component/cockpit/chart-title/chart-title";
import ScrollNum from "../../component/cockpit/scroll-num/scroll-num";
import {RequestPath, sendRequest} from "../../api/http";
import {
  BaseStatis, DangerDevice,
  DeviceAlertState, DeviceCount,
  DeviceRun,
  DeviceState,
  DeviceStateDistribute,
  isDeviceCount, isDeviceStateDistribute, isEventCount, MaintCosts, ReliabilityInfo
} from "../../common/entity/device";
import {ResultCode} from "../../common/entity/result";
import _ from 'lodash';
import DeviceRunPanel from "../../component/cockpit/charts/device-run/device-run";
import PiePanel from '../../component/cockpit/charts/pie/pie';
import {message} from "antd";
import ReliabilityPanel, {ReliabilityData} from "../../component/cockpit/charts/reliability/reliability";
import AMapTool from "../../component/cockpit/AMap/amap";
import Marquee from "react-fast-marquee";
import {isUserDistribute, UserDistribute} from "../../common/entity/project";

interface CockpitProps {
}

const Cockpit: FC<CockpitProps> = () => {
  const [deviceRun, setDeviceRun] = useState<{
    xAxis: string[],
    invalidData: number[],
    validData: number[],
    newData: number[],
  }>();
  const [deviceState, setDeviceState] = useState<DeviceState>();
  const [alarmState, setAlarmState] = useState<DeviceAlertState>();
  const [deviceCount, setDeviceCount] = useState<DeviceCount>();
  const [projectCount, setProjectCount] = useState<DeviceCount>();
  const [userDistribute, setUserDistribute] = useState<UserDistribute>();
  const [eventCount, setEventCount] = useState<string>('00000000');
  const [deviceDistribute, setDeviceDistribute] = useState<DeviceStateDistribute>();
  const [dangerDevices, setDangetDevices] = useState<DangerDevice[]>([]);
  const [reliability, setReliabilities] = useState<ReliabilityData[]>();
  const [maint, setMaint] = useState<MaintCosts>();

  const getDeviceRunStatistic = () => {
    sendRequest<DeviceRun[]>({
      url: RequestPath.GET_DEVICE_RUN_STATISTIC,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        const deviceRun = result?.resBody ?? [];
        const invalidData = [], validData = [], newData = [], xAxis = [];
        for (let i = 0; i < (_.min([5, deviceRun?.length ?? 0]) ?? 0); i++) {
          if (i < 5) {
            xAxis.push(deviceRun[i]?.strModelName ?? '--');
            invalidData.push(0 - parseInt(deviceRun[i]?.invalidCounts ?? '0'));
            validData.push(parseInt(deviceRun[i]?.validCounts ?? '0') - parseInt(deviceRun[i]?.newCounts ?? '0'));
            newData.push(parseInt(deviceRun[i]?.newCounts ?? '0'));
            continue;
          }
          break;
        }
        setDeviceRun({
          xAxis,
          invalidData,
          validData,
          newData,
        })
      } else {
        message.error('无法获取设备接入情况数据，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取设备接入情况数据，请联系系统管理员');
    });
  }

  const getDeviceStatus = () => {
    sendRequest<DeviceState[]>({
      url: RequestPath.GET_DEVICE_STATUS,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        const deviceState = _.head(result?.resBody) ?? {
          strStatisName: '运行情况',
          strErrorCount: '0',
          strNormalCount: '0',
        };
        let startAngle = 45;      //控制饼图起始角度，使label在大致固定的位置
        if (deviceState.strNormalCount != "" && deviceState.strNormalCount != "0" && deviceState.strErrorCount != "" && deviceState.strErrorCount != "0") {
          try {
            if (parseInt(deviceState?.strNormalCount ?? '0') + parseInt(deviceState?.strErrorCount ?? '0') > 0)
              startAngle = 200 * (parseInt(deviceState?.strErrorCount ?? '0') / (parseInt(deviceState?.strNormalCount ?? '0') + parseInt(deviceState?.strErrorCount ?? '0'))) + 20;
            else
              startAngle = 135;
          } catch (e) {
            startAngle = 45;
          }
        }
        deviceState.startAngle = startAngle;
        setDeviceState(deviceState);
      } else {
        message.error('无法获取设备运行情况数据，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取设备运行情况数据，请联系系统管理员');
    })
  }

  const getAlarmStatistic = () => {
    sendRequest<DeviceAlertState[]>({
      url: RequestPath.GET_ALARM_STATISTIC,
    }).then(result => {
      if (result?.resCode === ResultCode?.OK) {
        const alarmState = _.head(result?.resBody) ?? {
          strUnsolveCount: '0',
          strSolvedCount: '0',
        };
        let startAngle = 45;      //控制饼图起始角度，使label在大致固定的位置
        if (alarmState?.strUnsolveCount != "" && alarmState?.strUnsolveCount != "") {
          try {
            if (parseInt(alarmState?.strSolvedCount ?? '0') + parseInt(alarmState?.strUnsolveCount ?? '0') > 0)
              startAngle = 200 * (parseInt(alarmState?.strUnsolveCount ?? '0') / (parseInt(alarmState?.strSolvedCount ?? '0') + parseInt(alarmState?.strUnsolveCount ?? '0'))) + 20;
            else
              startAngle = 135;
          } catch (e) {
            startAngle = 45;
          }
        }
        alarmState.startAngle = startAngle;
        setAlarmState(alarmState);
      } else {
        message.error('无法获取告警统计数据，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取告警统计数据，请联系系统管理员');
    });
  }

  const getDashboardStatistic = () => {
    sendRequest<BaseStatis[]>({
      url: RequestPath.DASHBOARD_STATISTIC,
    }).then(result => {
      let _deviceCount = 0;
      if (result?.resCode === ResultCode.OK) {
        for (let i = 0; i < (result?.resBody?.length ?? 0); i++) {
          const detail = (result?.resBody ?? [])[i];
          switch (detail?.strStatisName) {
            case '设备数':
              if (isDeviceCount(detail)) {
                if (/^\d+$/.test(detail?.strCount ?? '')) {
                  _deviceCount = Number(detail?.strCount);
                }
                setDeviceCount(detail);
              }
              break;
            case '工程总数':
              if (isDeviceCount(detail)) {
                setProjectCount(detail);
              }
              break;
            case '用户分布':
              if (isUserDistribute(detail)) {
                setUserDistribute(detail);
              }
              break;
            case '设备分布状态':
              if (isDeviceStateDistribute(detail)) {
                setDeviceDistribute({
                  strSafeCount: (Number(_deviceCount) >= 24 ? Number(_deviceCount ?? '0') - 24 : (detail?.strSafeCount ?? '--')) + "台",
                  strLowWarningCount: (Number(_deviceCount ?? '0') >= 24 ? "24" : (detail?.strLowWarningCount ?? '--')) + '台',
                  strMidWarningCount: (detail?.strMidWarningCount) != "0" ? (detail?.strMidWarningCount ?? '--') + "台" : "无",
                  strHighWarningCount: (detail?.strHighWarningCount) != "0" ? (detail?.strHighWarningCount ?? '--') + "台" : "无",
                  strDangerCount: (detail?.strDangerCount) != "0" ? (detail?.strDangerCount ?? '--') + "台" : "无",
                  strUnknowCount: (detail?.strUnknowCount) != "0" ? (detail.strUnknowCount ?? '--') + "台" : "无",
                });
              }
              break;
            case '系统记录事件数':
              if (isEventCount(detail)) {
                setEventCount('00000000'.substring(0, 8 - (detail?.strCount?.length ?? 0)) + (detail?.strCount ?? ''))
              }
              break;
          }
        }
      } else {
        message.error('无法获取工程统计数据，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取工程统计数据，请联系系统管理员');
    })
  }

  const getDangerDevice = () => {
    sendRequest<DangerDevice[]>({
      url: `${RequestPath.GET_DANGER_DEVICE}?maintLevel=4,5`,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        setDangetDevices(result?.resBody ?? []);
      } else {
        message.error('无法获取高危设备列表，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取高危设备列表，请联系系统管理员');
    })
  }

  const getReliability = () => {
    sendRequest<ReliabilityInfo[]>({
      url: RequestPath.GET_MODEL_RELIABILITY,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        const data: ReliabilityData[] = [];
        for (let i = 0; i < (_.min([5, result?.resBody ?? []]) ?? 0); i++) {
          if (i < 5 && result?.resBody !== undefined) {
            data.push({
              "name": result?.resBody[i]?.MODEL_NAME ?? '--',
              "value": parseInt(result?.resBody[i]?.reliability ?? '0')
            });
            continue;
          }
          break;
        }
        setReliabilities(data?.sort((a, b) => a.value - b.value));
      } else {
        message.error('无法获取可靠性分布数据，请联系系统管理员');
      }
    }).catch(() => {
      message.error('无法获取可靠性分布数据，请联系系统管理员');
    })
  }

  const getMaintCosts = () => {
    sendRequest<MaintCosts[]>({
      url: RequestPath.GET_MAINT_COSTS_COUNT,
    }).then(result => {
      if (result?.resCode === ResultCode.OK) {
        const maint = _.head(result?.resBody);
        setMaint({
          remindCount: (maint?.remindCount ?? 0) * 10,
          logCount: (maint?.logCount ?? 0) * 10,
          expertCount: (maint?.expertCount ?? 0) * 20,
          totalCounts: (200 * ((maint?.remindCount ?? 0) * 10) + 2000 * ((maint?.expertCount ?? 0) * 20)) / 10000,
        });
      } else {
        message.error('无法获取运维分析数据，请联系系统管理员');
      }
    }).catch()
  }

  useEffect(() => {
    getDeviceRunStatistic();
    getDeviceStatus();
    getAlarmStatistic();
    getDashboardStatistic();
    getDangerDevice();
    getReliability();
    getMaintCosts();
    const i = setInterval(() => {
      getMaintCosts();
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(i);
    }
  }, [])

  return <div className={styles.main}>
    <div className={styles.title}/>
    <div className={styles.content}>
      <div className={styles.left}>
        <div className={styles.leftItem}>
          <ChartTitle linePosition="1" title="设备接入情况 (Top 5)"/>
          <div style={{width: '3.3rem', height: '2.7rem'}}>
            <DeviceRunPanel xAxis={deviceRun?.xAxis ?? []} newData={deviceRun?.newData ?? []}
                            invalidData={deviceRun?.invalidData ?? []} validData={deviceRun?.validData ?? []}/>
          </div>
        </div>
        <div className={styles.leftItem}>
          <ChartTitle linePosition="1" title="系统记录事件数"/>
          <div className={styles.sysEventNum}>
            <ScrollNum number={eventCount}/>
          </div>
        </div>
        <div className={styles.leftItem}>
          <ChartTitle linePosition="1" title="设备实况"/>
          <div style={{width: '3.3rem', height: '1.75rem'}}>
            {
              deviceState !== undefined && <PiePanel errorCount={Number(deviceState?.strErrorCount ?? '0')} type={0}
                                                     normalCount={Number(deviceState?.strNormalCount ?? '0')}
                                                     startAngle={deviceState?.startAngle ?? 45}/>
            }
          </div>
        </div>
        <div className={styles.leftItem}>
          <ChartTitle linePosition="1" title="报警统计"/>
          <div style={{width: '3.3rem', height: '1.75rem'}}>
            {
              alarmState !== undefined && <PiePanel errorCount={Number(alarmState?.strUnsolveCount ?? '0')} type={1}
                                                    normalCount={Number(alarmState?.strSolvedCount ?? '0')}
                                                    startAngle={alarmState?.startAngle ?? 45}/>
            }
          </div>
        </div>
      </div>
      <div className={styles.center}>
        <AMapTool deviceCount={deviceCount?.strCount ?? '--'} projectCount={projectCount?.strCount ?? '--'}
                  userDistribute={userDistribute}/>
      </div>
      <div className={styles.right}>
        <div className={styles.rightItem}>
          <ChartTitle linePosition="0" title="设备状态分布"/>
          <div className={styles.devStatusDistribution}>
            <div className={styles.devStatusItem1}>
              <div className={styles.devStatusLvl}>稳定</div>
              <div className={styles.devStatusNum}>{deviceDistribute?.strSafeCount ?? '--'}</div>
            </div>
            <div className={styles.devStatusItem2}>
              <div className={styles.devStatusLvl}>低风险</div>
              <div className={styles.devStatusNum}>{deviceDistribute?.strLowWarningCount ?? '--'}</div>
            </div>
            <div className={styles.devStatusItem3}>
              <div className={styles.devStatusLvl}>中风险</div>
              <div className={styles.devStatusNum}>{deviceDistribute?.strMidWarningCount ?? '--'}</div>
            </div>
            <div className={styles.devStatusItem4}>
              <div className={styles.devStatusLvl}>高风险</div>
              <div className={styles.devStatusNum}>{deviceDistribute?.strHighWarningCount ?? '--'}</div>
            </div>
            <div className={styles.devStatusItem5}>
              <div className={styles.devStatusLvl}>危险</div>
              <div className={styles.devStatusNum}>{deviceDistribute?.strDangerCount ?? '--'}</div>
            </div>
          </div>
          <div className={styles.dangerDevList}>
            <div className={styles.dangerDevTitle}>高危设备：</div>
            <div className={styles.dangerDevs}>
              <Marquee className={styles.marquee} pauseOnHover={true} gradient={false}>{
                dangerDevices?.map(device => `${device?.strPjName ?? '--'}-${device?.strDeviceName ?? '--'}`).join(' , ')
              }
              </Marquee>
            </div>
          </div>
        </div>
        <div className={styles.rightItem}>
          <ChartTitle linePosition="0" title="可靠性分布"/>
          <div style={{width: '3.3rem', height: '3rem'}}>
            <ReliabilityPanel data={reliability ?? []}/>
          </div>
        </div>
        <div className={styles.rightItem}>
          <ChartTitle linePosition="0" title="运维分析"/>
          <div className={styles.runAnalysis}>
            <div className={styles.runAnalysisTop}>
              <div className={styles.runAnalysisTopItem}>
                <i className={styles.topItemIcon}/>
                <div id="testFont" className={styles.topItemText}>系统提醒</div>
                <div className={styles.topItemValue}>
                  <div className={styles.value}>{maint?.remindCount ?? 0}</div>
                  <div className={styles.dimen}>次</div>
                </div>
              </div>
              <div className={styles.runAnalysisTopItem}>
                <i className={styles.topItemIcon}/>
                <div className={styles.topItemText}>检修日志</div>
                <div className={styles.topItemValue}>
                  <div className={styles.value}>{maint?.logCount ?? 0}</div>
                  <div className={styles.dimen}>条</div>
                </div>
              </div>
              <div className={styles.runAnalysisTopItem}>
                <i className={styles.topItemIcon}/>
                <div className={styles.topItemText}>专家指导</div>
                <div className={styles.topItemValue}>
                  <div className={styles.value}>{maint?.expertCount ?? 0}</div>
                  <div className={styles.dimen}>次</div>
                </div>
              </div>
            </div>
            <div className={styles.runAnalysisCont}>
              <i className={styles.topItemIcon}/>
              <div className={styles.runAnalysisContText}>节省运维费用</div>
              <div className={styles.runAnalysisContValue}>
                <div className={styles.value}>{maint?.totalCounts ?? 0}</div>
                <div className={styles.dimen}>万</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.foot}/>
  </div>
};

export default Cockpit;
