import React, {FC, useEffect, useRef, useState} from 'react';

import styles from './login.module.less';
import {RequestPath, sendRequest} from "../../api/http";
import {Button, Divider, Form, FormInstance, Input, message, Select, Tabs} from 'antd';
import {Result, ResultCode} from "../../common/entity/result";
import {ConstantKey, phonePattern, pwdPattern} from "../../utils/constant";
import {LoginParamInfo} from "../../common/entity/user";
import logger, {error} from "@daqo/log4js";
import {enc, MD5, AES} from "crypto-js";
import areaList from "../../component/relogin/area.json";
import {Area} from "../../common/entity/area";
import {useHistory} from "react-router-dom";
import socket from "../../component/socket/socket";

interface LoginProps {
  setLoading: (showLoading: boolean) => void,
}

const {TabPane} = Tabs;

enum ShowType {
  LOGIN,
  FORGET,
}

interface LoginValue {
  phone: string,
  code: string,
  account: string,
  password: string,
  reset_phone: string,
  reset_code: string,
  new_password: string,
}

const Login: FC<LoginProps> = (props: LoginProps) => {
  const [showType, setShowType] = useState<ShowType>(ShowType.LOGIN);
  const [tab, setTab] = useState<'1' | '2'>('2');
  const [codeSendTimeout, setCodeSendTimeout] = useState(0);
  const [resetCodeSendTimeout, setResetCodeSendTimeout] = useState(0);
  const formRef = useRef<FormInstance<LoginValue>>(null);
  const resetFormRef = useRef<FormInstance<LoginValue>>(null);
  const codeTimeoutInterval = useRef<NodeJS.Timeout>();
  const resetCodeTimeoutInterval = useRef<NodeJS.Timeout>();
  const history = useHistory();

  const {setLoading} = props;

  const login = () => {
    const form = formRef.current;
    if (form === null) {
      message.error('form 表单未初始化');
      return;
    }
    form.validateFields().then(() => {
      const loginValue = form.getFieldsValue();
      if (tab === '1') {
        const formData = new FormData();
        formData.set('phoneNumber', loginValue.phone);
        formData.set('vcode', loginValue.code);
        setLoading(true);
        sendRequest<LoginParamInfo>({
          url: RequestPath.PHONE_LOGIN,
          method: 'POST',
          body: formData,
          needToken: false,
        }).then((result) => {
          setLoading(false);
          if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
            localStorage.setItem(ConstantKey.USER, JSON.stringify(result.resBody));
            localStorage.removeItem(ConstantKey.LOGIN_TIMEOUT_NAME);
            history.push('/modules');
          } else {
            message.error('用户不存在或验证码错误，请您重新登录');
          }
        }).catch(err => {
          setLoading(false);
          logger.error(err);
          message.error('登录失败，请您联系系统管理员');
        });
      } else if (tab === '2') {
        const time = new Date().getTime();
        const pwd = enc.Base64.stringify(MD5(enc.Base64.stringify(MD5(loginValue.password)) + time));
        const formData = new FormData();
        formData.set('username', loginValue.account);
        formData.set('password', pwd);
        formData.set('speed', time + '');
        setLoading(true);
        sendRequest<LoginParamInfo>({
          url: RequestPath.LOGIN,
          method: 'POST',
          body: formData,
          needToken: false,
        }).then((result) => {
          setLoading(false);
          if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
            localStorage.setItem(ConstantKey.USER, JSON.stringify(result.resBody));
            const userPwd = JSON.stringify({
              username: loginValue.account,
              password: loginValue.password,
            });
            if (socket !== null && socket?.connected && (result.resBody?.userInfo?.pjList?.length ?? 0) > 0) {
              socket?.emit('projects', result.resBody?.userInfo?.pjList?.map(pj => pj?.intProjectId).join(','));
            }
            localStorage.setItem(ConstantKey.USER_PWD_STORE, AES.encrypt(userPwd, ConstantKey.USER_PWD_STORE_AES_KEY).toString());
            history.push('/modules');
          } else {
            message.error('用户不存在或密码错误，请您重新登录');
          }
        }).catch(err => {
          setLoading(false);
          logger.error(err);
          message.error('登录失败，请您联系系统管理员');
        });
      }
    }).catch(() => {

    });
  }

  const setActiveTab = (activeKey: string) => {
    if (['1', '2'].indexOf(activeKey) > -1) {
      // @ts-ignore
      setTab(activeKey);
    }
  }

  const sendCode = (type: ShowType) => {
    const form = resetFormRef.current;
    if (form === null) {
      message.error('form 表单未初始化');
      return;
    }
    const loginValue = form.getFieldsValue();
    if (loginValue.reset_phone === '' || !phonePattern.test(loginValue.reset_phone)) {
      message.warn('请输入正确格式的手机号');
      return;
    }
    const now = new Date().getTime();
    localStorage.setItem(type === ShowType.LOGIN ? ConstantKey.LOGIN_TIMEOUT_NAME : ConstantKey.FORGET_TIMEOUT_NAME, now + '');
    setLoading(true);
    sendRequest<boolean>({
      url: `${RequestPath.SEND_V_CODE}?number=${loginValue.reset_phone}`,
      method: 'GET',
      needToken: false,
    }).then((result: Result<boolean>) => {
      setLoading(false);
      if (result.resCode === ResultCode.OK) {
        message.success('验证码已发送，请您查收');
        if (type === ShowType.LOGIN) {
          setCodeSendTimeout(120);
          codeSending();
        } else if (type === ShowType.FORGET) {
          setResetCodeSendTimeout(120);
          forgetCodeSending();
        }
      } else {
        message.error('验证码发送失败，请您联系系统管理员');
      }
    }).catch(err => {
      logger.error(err);
      setLoading(false);
      message.error('验证码发送失败，请您联系系统管理员');
    });
  }

  const codeSending = () => {
    if (codeTimeoutInterval.current !== undefined) {
      clearInterval(codeTimeoutInterval.current);
    }
    codeTimeoutInterval.current = setInterval(() => {
      setCodeSendTimeout((prev) => {
        return prev > 0 ? prev - 1 : 0;
      });
    }, 1000);
  }

  const forgetCodeSending = () => {
    if (resetCodeTimeoutInterval.current !== undefined) {
      clearInterval(resetCodeTimeoutInterval.current);
    }
    resetCodeTimeoutInterval.current = setInterval(() => {
      setResetCodeSendTimeout((prev) => {
        return prev > 0 ? prev - 1 : 0;
      });
    }, 1000);
  }

  const resetPassword = () => {
    const form = resetFormRef.current;
    if (form === null) {
      message.error('form 表单未初始化');
      return;
    }
    form.validateFields().then(() => {
      const loginValue = form.getFieldsValue();
      if (!pwdPattern.test(loginValue.new_password)) {
        message.warn('新密码长度必须大于8位且必须包含字母和数字');
        return;
      }
      const formData = new FormData();
      formData.set('number', loginValue.reset_phone);
      formData.set('code', loginValue.reset_code);
      formData.set('password', loginValue.new_password);
      sendRequest<string>({
        url: RequestPath.RETRIEVE_ACCOUNT,
        method: 'POST',
        body: formData,
      }).then(result => {
        if (result.resCode === ResultCode.OK) {
          localStorage.removeItem(ConstantKey.FORGET_TIMEOUT_NAME);
          message.success('密码重置成功，请您登录');
          setShowType(ShowType.LOGIN);
        } else {
          message.error('密码重置失败，请联系系统管理员');
        }
      }).catch(err => {
        error(err);
      })
    });
  }

  return <div className={styles.login}>
    <div className={styles.login_banner}>
      <div className={styles.title}><span/></div>
      <div className={styles.content}>
        <div className={styles.content_topic}/>
        <div className={styles.content_loginPanel}>
          {
            showType === ShowType.LOGIN ? <Form layout={'vertical'} onValuesChange={() => {
              return false;
            }} ref={formRef}>
              <div className={styles.panel}>
                <Tabs defaultActiveKey={tab} size={'large'} onChange={setActiveTab}>
                  {/*<TabPane tab="手机登录" key="1">*/}
                  {/*  <div className={styles.loginInput}>*/}
                  {/*    <div className={styles.inputItem}>*/}
                  {/*      {*/}
                  {/*        areaList.length > 0 &&*/}
                  {/*        <Select className={styles.select} bordered={false} defaultValue={areaList[0].code}*/}
                  {/*                style={{width: 120}} options={areaList.map((area: Area) => ({*/}
                  {/*          label: area.code,*/}
                  {/*          value: area.code,*/}
                  {/*        }))}>*/}
                  {/*        </Select>*/}
                  {/*      }*/}
                  {/*      <Form.Item*/}
                  {/*        rules={tab === '1' && showType === ShowType.LOGIN ? [{required: true, message: '请输入手机号'}, {message: '请输入正确格式的手机号', pattern: phonePattern}] : []}*/}
                  {/*        name={'phone'}>*/}
                  {/*        <Input className={styles.input} bordered={false} type={'text'}*/}
                  {/*               placeholder={'请输入手机号'} name={'phone'} autoComplete={'off'}/>*/}
                  {/*      </Form.Item>*/}
                  {/*    </div>*/}
                  {/*    <div className={styles.inputItem}>*/}
                  {/*      <div className={styles.inputDesc}>验证码</div>*/}
                  {/*      <Form.Item rules={[{required: tab === '1' && showType === ShowType.LOGIN, message: '请输入验证码'}]} name={'code'}>*/}
                  {/*        <Input className={styles.input} bordered={false} type={'text'} placeholder={'请输入验证码'}*/}
                  {/*               name={'code'} autoComplete={'new-password'}/>*/}
                  {/*      </Form.Item>*/}
                  {/*      <div className={`${styles.sendCode} ${codeSendTimeout > 0 ? styles.sending : ''}`}*/}
                  {/*           onClick={codeSendTimeout > 0 ? undefined : () => sendCode(ShowType.LOGIN)}>{*/}
                  {/*        codeSendTimeout > 0 ? '已发送' : '获取验证码'*/}
                  {/*      }</div>*/}
                  {/*    </div>*/}
                  {/*    <div className={styles.tips} style={{*/}
                  {/*      visibility: codeSendTimeout > 0 ? 'visible' : 'hidden'*/}
                  {/*    }}>{`${codeSendTimeout}后重新发送`}</div>*/}
                  {/*  </div>*/}
                  {/*</TabPane>*/}
                  <TabPane tab="账号登录" key="2">
                    <div className={styles.loginInput}>
                      <Form.Item rules={[{required: tab === '2' && showType === ShowType.LOGIN, message: '请输入账号/手机号'}]} name={'account'}>
                        <Input className={styles.input} bordered={false} type={'text'} autoComplete={'off'} placeholder={'请输入账号/手机号'}
                               name={'account'}/>
                      </Form.Item>
                      <Form.Item rules={[{required: tab === '2' && showType === ShowType.LOGIN, message: '请输入密码'}]} name={'password'}>
                        <Input.Password className={styles.input} autoComplete={'off'} bordered={false} type={'password'} placeholder={'请输入密码'}
                                        name={'password'}/>
                      </Form.Item>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
              <Form.Item>
                <div className={styles.btnPanel}>
                  <Button className={styles.btnPanel_btn} htmlType={'submit'} onClick={login}>登录</Button>
                </div>
              </Form.Item>
            </Form> : <Form ref={resetFormRef} layout={'vertical'} onValuesChange={() => {
              return false;
            }}>
              <div className={styles.panel}>
                <div className={styles.loginInput}>
                  <div className={styles.inputItem}>
                    {
                      areaList.length > 0 &&
                      <Select className={styles.select} bordered={false} defaultValue={areaList[0].code}
                              style={{width: 120}} options={areaList.map((area: Area) => ({
                        label: area.code,
                        value: area.code,
                      }))}>
                      </Select>
                    }
                    <Form.Item
                      rules={showType === ShowType.FORGET ? [{required: true, message: '请输入手机号'}, {message: '请输入正确格式的手机号', pattern: phonePattern}] : []}
                      name={'reset_phone'}>
                      <Input className={styles.input} bordered={false} type={'text'}
                             placeholder={'请输入手机号'} autoComplete={'off'} name={'reset_phone'}/>
                    </Form.Item>
                  </div>
                  <div className={styles.inputItem}>
                    <div className={styles.inputDesc}>验证码</div>
                    <Form.Item rules={[{required: showType === ShowType.FORGET, message: '请输入验证码'}]} name={'reset_code'}>
                      <Input className={styles.input} bordered={false} type={'text'} placeholder={'请输入验证码'}
                             name={'reset_code'} autoComplete={'off'}/>
                    </Form.Item>
                    <div className={`${styles.sendCode} ${codeSendTimeout > 0 ? styles.sending : ''}`}
                         onClick={codeSendTimeout > 0 ? undefined : () => sendCode(ShowType.FORGET)}>{
                      resetCodeSendTimeout > 0 ? '已发送' : '获取验证码'
                    }</div>
                  </div>
                  <div className={styles.inputItem}>
                    <div className={styles.inputDesc}>新密码</div>
                    <Form.Item rules={[{required: showType === ShowType.FORGET, message: '请输入新密码'}]} name={'new_password'}>
                      <Input.Password className={styles.input} autoComplete={'new-password'} bordered={false} type={'password'} placeholder={'请输入新密码'}
                                      name={'new_password'}/>
                    </Form.Item>
                  </div>
                  <div className={styles.tips} style={{
                    visibility: codeSendTimeout > 0 ? 'visible' : 'hidden'
                  }}>{`${codeSendTimeout}后重新发送`}</div>
                </div>
              </div>
              <Form.Item>
                <div className={styles.btnPanel}>
                  <Button className={styles.btnPanel_btn} htmlType={'submit'} onClick={resetPassword}>重置</Button>
                </div>
              </Form.Item>
            </Form>
          }
          {/*<div className={styles.forget}>*/}
          {/*  {*/}
          {/*    showType === ShowType.LOGIN ? <Button type="link" onClick={() => setShowType(ShowType.FORGET)}>忘记密码</Button> : <Button type="link" onClick={() => setShowType(ShowType.LOGIN)}>立即登录</Button>*/}
          {/*  }*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
    <div className={styles.login_footer}>
      {/*<div className={styles.item}>*/}
      {/*  <Button type="link">联系我们</Button>*/}
      {/*  <Divider type="vertical" />*/}
      {/*  <Button type="link">法律声明</Button>*/}
      {/*</div>*/}
      <div className={styles.item}>
        <a href="https://beian.miit.gov.cn" target="_blank" style={{color: '#464646'}} rel="noreferrer">网站备案号: 蜀ICP备2022017637号</a>
      </div>
      <div className={styles.item}>© 2022 川开集团 版权所有</div>
    </div>
  </div>
}

export default Login;
