import LoginTypes from './login.types';
import {Dispatch} from "redux";
import {AppAction} from "../app/app.reducer";

const setLogin = (showLogin: boolean) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: LoginTypes.SHOW_LOGIN,
    data: showLogin,
  })
}

const actions = {
  setLogin,
};

export default actions;
