import LoadingTypes from './loading.types';
import {AppAction} from "../app/app.reducer";

interface AppReducer {
  showLoading: boolean,
}

const initialState: AppReducer = {
  showLoading: false,
};

const reducer = (state = initialState, action: AppAction) => {
  switch (action.type) {
    case LoadingTypes.SHOW_LOADING:
      return {
        ...state,
        showLoading: action.data,
      };
    default:
      return state;
  }
}

export default reducer;
