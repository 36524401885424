import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import styles from './general-info.project.module.less';
import {useParams} from "react-router-dom";
import {RouterParams} from "../app/app.routers";
import {RequestPath, sendRequest} from "../../api/http";
import {ProjectInfo} from "../../common/entity/project";
import Hashids from 'hashids';
import {error} from "@daqo/log4js";
import {ResultCode} from "../../common/entity/result";
import {message, Image, Carousel, Tooltip, Empty, Space, Popconfirm, Drawer, Spin} from "antd";
import {LeftOutlined, RightOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';

import NoImg from '../../common/images/no-img.png';
import {OSS_PJ} from "../../utils/constant";
import {CarouselRef} from "antd/lib/carousel";
import mime from 'mime-types';
import {AnalysisBoard, DynamicParams} from "../../common/entity/detail";
import LinePanel from "../../component/general-info/charts/line/line";
import Tools from "../../utils/tools";
import KeyParams from "../../container/general-info/key-params.container";
import SystemParamPanel from "../../container/general-info/system-param.container";
import {SystemParam} from "./general-info.device";
import ResizeObserver from 'resize-observer-polyfill';

// @ts-ignore
import SelfAdaptFontSize from 'self-adapt-fontsize';

const hashids = new Hashids();
const safs = SelfAdaptFontSize.getInstance();

export interface GeneralInfoProps {
  setLoading: (showLoading: boolean) => void,
}

export interface KeyParamStatus {
  show: boolean,
  current?: DynamicParams,
}

const GeneralInfoProject: FC<GeneralInfoProps> = (props: GeneralInfoProps) => {
  const analysisRef = useRef<HTMLDivElement>(null);
  const routerParams = useParams<RouterParams>();
  const [project, setProject] = useState<ProjectInfo>();
  const [analysisBoard, setAnalysisBoard] = useState<AnalysisBoard[]>([]);
  const [currentImg, setCurrentImg] = useState(0);
  const carouselRef = useRef<CarouselRef>(null);
  const [dynamicParams, setDynamicParams] = useState<DynamicParams[]>([]);
  const [paramPanel, setParamPanel] = useState<KeyParamStatus>({
    show: false,
  });
  const [systemParam, setSystemParam] = useState<SystemParam>({
    show: false,
  });

  const [dynamicLoading, setDynamicLoading] = useState<boolean>(false);
  const [infoLoading, setInfoLoading] = useState<boolean>(false);
  const [boardLoading, setBoardLoading] = useState<boolean>(false);
  const resizeObserver = useRef<ResizeObserver | null>(null);

  const {setLoading} = props;

  const getProjectInfo = useCallback(() => {
    if (routerParams.projectId !== undefined) {
      const projectId = hashids.decode(routerParams.projectId);
      setLoading(true);
      setInfoLoading(true);
      sendRequest<ProjectInfo>({
        url: `${RequestPath.GET_PROJECT_INFO}?projectId=${projectId}`
      }).then(result => {
        setLoading(false);
        setInfoLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          const project = result.resBody;
          project.pjImgList = (project?.strImg ?? '') !== '' ? (project?.strImg ?? '').split(',').map(img => `${OSS_PJ}${projectId}/${img}`) : [];
          setProject(project);
        } else {
          setProject(undefined);
          message.error('无法获取工程信息，请联系系统管理员');
        }
      }).catch(err => {
        setInfoLoading(false);
        setLoading(false);
        message.error('无法获取工程信息，请联系系统管理员');
        error(err);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    routerParams.projectId
  ]);

  const formatBoardSize = () => {
    for (let i = 0; i < 5; i++) {
      const valueDom = document.getElementById(`board_value_${i}`);
      const unitDom = document.getElementById(`board_unit_${i}`);
      if (valueDom !== null) {
        safs.fontSize(valueDom, Tools.remRadio(150), Tools.remRadio(80));
      }
      if (unitDom !== null) {
        safs.fontSize(unitDom, Tools.remRadio(35), Tools.remRadio(80));
      }
    }
  }

  useEffect(() => {
    const analysisPanel = analysisRef.current
    if (analysisPanel !== null) {
      resizeObserver.current = new ResizeObserver(() => {
        formatBoardSize();
      });
      resizeObserver.current?.observe(analysisPanel);
    }
    return () => {
      if (analysisPanel !== null && resizeObserver.current !== null) {
        resizeObserver.current?.unobserve(analysisPanel);
      }
    }
  }, [])

  const getAnalysisBoard = useCallback(() => {
    if (routerParams.projectId !== undefined) {
      const projectId = hashids.decode(routerParams.projectId);
      setBoardLoading(true);
      sendRequest<AnalysisBoard[]>({
        url: `${RequestPath.GET_ANALYSIS_BOARD}?targetId=${projectId}&targetType=pj`
      }).then(result => {
        setBoardLoading(false);
        if (result.resCode === ResultCode.OK) {
          setAnalysisBoard(result?.resBody ?? []);
          setTimeout(() => {
            formatBoardSize();
          });
        } else {
          message.error('无法获取到工程概况数据，请联系系统管理员');
        }
      }).catch(() => {
        setBoardLoading(false);
        message.error('无法获取到工程概况数据，请联系系统管理员');
      });
    }
  }, [
    routerParams.projectId
  ]);

  useEffect(() => {
    getAnalysisBoard();
  }, [getAnalysisBoard])

  const getDynamicParams = useCallback(() => {
    if (routerParams.projectId !== undefined) {
      setDynamicLoading(true);
      sendRequest<DynamicParams[]>({
        url: `${RequestPath.GET_DYNAMIC_PARAMS}?targetid=${hashids.decode(routerParams.projectId)}&type=pj`,
      }).then(result => {
        setDynamicLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          setDynamicParams(result.resBody);
        } else {
          setDynamicParams([]);
          message.error('无法获取关键参数，请联系系统管理员');
        }
      }).catch(err => {
        setDynamicLoading(false);
        message.error('无法获取关键参数，请联系系统管理员');
        error(err);
      })
    }
  }, [routerParams.projectId])

  useEffect(() => {
    getProjectInfo();
  }, [getProjectInfo]);

  useEffect(() => {
    getDynamicParams();
  }, [getDynamicParams])

  const prevImg = () => {
    if (carouselRef !== null && carouselRef.current !== null) {
      carouselRef.current.prev();
    }
  }

  const nextImg = () => {
    if (carouselRef !== null && carouselRef.current !== null) {
      carouselRef.current.next();
    }
  }

  const downloadProjectFile = (projectId: string, filename: string) => {
    setLoading(true);
    sendRequest({
      url: `${RequestPath.DOWNLOAD_PROJECT_FILE}?targetId=${projectId}&fileName=${filename}`,
      resultType: 'arraybuffer',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).then((result: any) => {
      setLoading(false);
      const filetype = filename.indexOf('.') > -1 ? filename.substring(filename.lastIndexOf('.')).toLowerCase() : '';
      const mimeType = mime.lookup(filetype);
      const file = new Blob([result], {
        ...mimeType ? {
          type: mimeType,
        } : {}
      });
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }).catch(err => {
      setLoading(false);
      error(err);
    });
  }

  const removeParam = (paramId?: string) => {
    if (paramId !== undefined) {
      setLoading(true);
      sendRequest({
        url: `${RequestPath.DELETE_DYNAMIC_PARAMS}?paramId=${paramId}`,
        method: 'POST',
      }).then(result => {
        setLoading(false);
        if (result.resCode === ResultCode.OK) {
          message.success('成功删除关键参数');
          getDynamicParams();
        } else {
          message.error('无法删除关键参数，请联系系统管理员');
        }
      }).catch(err => {
        setLoading(false);
        error(err);
      })
    }
  }

  const currentProjectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams.projectId]);

  return <div className={styles.main}>
    <div className={styles.main_basic}>
      <div className={`${styles.info} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>基本信息</div>
        <Spin spinning={infoLoading}>
          <div className={styles.info_img_container}>
            {
              (project?.pjImgList ?? []).length > 1 && <LeftOutlined className={styles.left} onClick={prevImg}/>
            }
            {
              (project?.pjImgList ?? []).length > 0 ?
                <Carousel style={{width: '2rem'}} autoplay={true} dots={false} ref={carouselRef}
                          beforeChange={(from, to) => setCurrentImg(to)}>
                  {
                    project?.pjImgList?.map((img, index) => <Image
                      width={'2rem'}
                      height={'1.2rem'}
                      src={`${img}`}
                      className={styles.img}
                      fallback={NoImg}
                      key={`${img}_${index}`}
                    />)
                  }
                </Carousel> : <Image
                  width={'2rem'}
                  height={'1.2rem'}
                  src={NoImg}
                  preview={false}
                  className={styles.img}
                  fallback={NoImg}
                />
            }
            {
              (project?.pjImgList ?? []).length > 1 && <RightOutlined className={styles.right} onClick={nextImg}/>
            }
          </div>
          {
            (project?.pjImgList ?? []).length > 1 && <div className={styles.info_img_pointer}>
              {
                project?.pjImgList?.map((img, index) => <div key={`${img}_${index}`}
                                                             className={`${styles.pointer} ${currentImg === index ? styles.active : ''}`}/>)
              }
            </div>
          }
          <div className={styles.info_infos_item}>
            <div className={styles.name}>工程名：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip title={`工程名：${project?.strPjName ?? '--'}`}
                                                                           mouseEnterDelay={0.5}>{project?.strPjName ?? '--'}</Tooltip>
            </div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>工程地址：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`工程地址：${project?.strPositionForShow ?? '--'}`}
              mouseEnterDelay={0.5}>{project?.strPositionForShow ?? '--'}</Tooltip></div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>工程类型：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip title={`工程类型：${project?.strType ?? '--'}`}
                                                                           mouseEnterDelay={0.5}>{project?.strType ?? '--'}</Tooltip>
            </div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>工程单位：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`工程单位：${project?.strOwnerAddress ?? '--'}`}
              mouseEnterDelay={0.5}>{project?.strOwnerAddress ?? '--'}</Tooltip></div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>联系人：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip title={`联系人：${project?.strContacts ?? '--'}`}
                                                                           mouseEnterDelay={0.5}>{project?.strContacts ?? '--'}</Tooltip>
            </div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>联系电话：</div>
            <div className={`${styles.value} ${styles.one_line}`}><Tooltip
              title={`联系电话：${project?.strContactWay ?? '--'}`}
              mouseEnterDelay={0.5}>{project?.strContactWay ?? '--'}</Tooltip></div>
          </div>
          <div className={styles.info_infos_item}>
            <div className={styles.name}>备注：</div>
            <div className={styles.value}><Tooltip title={`备注：${project?.strInfoNote ?? '--'}`}
                                                   mouseEnterDelay={0.5}>{project?.strInfoNote ?? '--'}</Tooltip></div>
          </div>
        </Spin>
      </div>
      <div className={`${styles.archives} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>工程档案</div>
        <Spin spinning={infoLoading}>
          <div className={styles.archives_wrap}>
            {
              (project?.docsList ?? '') !== '' ? project?.docsList?.split(',')?.map((doc, index) => (
                <div key={`${doc}_${index}`} className={styles.archives_archive}
                     onClick={() => downloadProjectFile(currentProjectId, doc)}>{
                  <Tooltip title={`${doc}`} mouseEnterDelay={0.5}>{
                    doc
                  }</Tooltip>
                }</div>
              )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
            }
          </div>
        </Spin>
      </div>
    </div>
    <div className={styles.main_monitor_panel}>
      <div className={`${styles.main_system_monitor} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>
          工程概况
        </div>
        <Spin spinning={boardLoading}>
          <div className={`${styles.params_panel} ${styles.general_situation}`} ref={analysisRef}>
            {
              (analysisBoard?.length ?? 0) > 0 ? analysisBoard?.map((board, index) => (
                <div className={styles.params_panel_item} key={`${board?.intBoardId}_${index}`}>
                  <div className={styles.title_panel}>
                    <Tooltip title={board?.strTitle ?? '--'} mouseEnterDelay={0.4}>
                      <div className={styles.title}>{board?.strTitle ?? '--'}</div>
                    </Tooltip>
                    <i className={`daqo-iconfont daqo-icon-xiugai ${styles.add_param}`} onClick={() => setSystemParam({
                      show: true,
                      current: board,
                    })}/>
                  </div>
                  <Tooltip title={`${Tools.renderConstValue(board?.strValue) ?? '--'}${board?.strDimension ?? ''}`}
                           mouseEnterDelay={0.4}>
                    <div className={styles.value}>
                      <span id={Tools.renderConstValue(board?.strValue) !== '--' ? `board_value_${index}` : `useless_value_${index}`}>{Tools.renderConstValue(board?.strValue) ?? '--'}</span>
                      <span id={(board?.strDimension ?? '') !== '' ? `board_unit_${index}` : `useless_unit_${index}`}>{board?.strDimension ?? ''}</span>
                    </div>
                  </Tooltip>
                </div>
              )) : <div className={styles.center_wrap}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              className={styles.font_size_14}/></div>
            }
          </div>
        </Spin>
      </div>
      <div className={`${styles.main_monitors} ${styles.panel}`}>
        <div className={`${styles.panel_title}`}>
          关键数据
          <i className={`daqo-iconfont daqo-icon-tianjia ${styles.add_param}`} onClick={() => setParamPanel({
            show: true,
          })}/>
        </div>
        <Spin spinning={dynamicLoading}>
          <div className={styles.params_panel}>
            {
              dynamicParams?.length > 0 ? dynamicParams?.map((param, index) => (
                <div key={`${param.intParamId}_${index}`} className={styles.params_panel_item}>
                  <div className={styles.param_title}>
                    <Tooltip
                      title={`${param?.strTitle ?? '--'}: ${Tools.renderConstValue(param?.strValue ?? '--')} ${param?.strDimension ?? ''}`}
                      mouseEnterDelay={0.5}>
                      <div className={styles.param_title_value}>
                        <span className={styles.name}>{param?.strTitle ?? '--'}</span>
                        <span
                          className={styles.value}>{`${Tools.renderConstValue(param?.strValue ?? '--')} ${param?.strDimension ?? ''}`}</span>
                      </div>
                    </Tooltip>
                    <div className={styles.param_title_manager}>
                      <Space>
                        <EditOutlined className={styles.btn} onClick={() => setParamPanel({
                          show: true,
                          current: param,
                        })}/>
                        <Popconfirm
                          title="确认要删除此项吗?"
                          onConfirm={() => removeParam(param?.intParamId)}
                          okText="是"
                          cancelText="否"
                        >
                          <DeleteOutlined className={styles.btn}/>
                        </Popconfirm>
                      </Space>
                    </div>
                  </div>
                  <div className={styles.param_chart}>
                    <LinePanel lineData={[
                      {
                        name: '',
                        data: param?.dataList?.map((d, index) => ({
                          value: d,
                          time: `${index + 1}`,
                        })) ?? [],
                      }
                    ]}/>
                  </div>
                </div>
              )) : <div className={styles.center_wrap}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                              className={styles.font_size_14}/></div>
            }
          </div>
        </Spin>
      </div>
    </div>
    <Drawer title="关键数据" placement="right" visible={paramPanel?.show ?? false} destroyOnClose={true}
            onClose={() => setParamPanel({
              show: false,
            })} width={600}>
      <KeyParams type={'pj'} activeDetail={dynamicParams?.map(param => ({
        detailId: param?.intDetailId,
        category: param?.strCategory,
        deviceId: param?.intSrcId,
      })) ?? []} current={paramPanel?.current} exitDraw={() => {
        setParamPanel({
          show: false,
        });
        getDynamicParams();
      }
      } cancelDraw={() => {
        setParamPanel({
          show: false,
        });
      }}/>
    </Drawer>
    <Drawer title="编辑设备概况" placement="right" visible={systemParam?.show ?? false} destroyOnClose={true}
            onClose={() => setSystemParam({
              show: false,
            })} width={360}>
      <SystemParamPanel total={analysisBoard} type={'pj'} current={systemParam?.current} exitDraw={() => {
        setSystemParam({
          show: false,
        });
        getAnalysisBoard();
      }} cancelDraw={() => {
        setSystemParam({
          show: false,
        });
      }}/>
    </Drawer>
  </div>
}

export default GeneralInfoProject;
