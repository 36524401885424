import React from "react";
import {Result} from "antd";

interface NoAuthProps {}

export const NoAuth: React.FC<NoAuthProps> = () => {
  return <Result
    status="403"
    title="403"
    subTitle="对不起，您没有访问此模块的权限，请联系系统管理员，或退出并重新登录"
  />
}
