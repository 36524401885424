import {FC} from 'react';

import styles from './chart-title.module.less';

interface ChartTitleProps {
  title: string,
  linePosition: string,
}

const ChartTitle: FC<ChartTitleProps> = (props) => {
  const {linePosition, title} = props;

  return (
    <div className={styles.title} style={{backgroundPosition: linePosition === '0' ? 'right center' : 'left center'}}>
      <div className={styles.text} style={{order: 1 - Number(linePosition)}}>
        {title}
      </div>
      <div className={styles.line} style={{order: Number(linePosition)}}/>
    </div>
  );
}

export default ChartTitle;
