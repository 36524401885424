import React, { FC, useEffect, useState, useMemo, useCallback, ReactNode, useRef } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { RequestPath, sendRequest, RequestParam } from "../../api/http";
import { message, Empty } from 'antd';
import {RouterParams, Routers} from '../app/app.routers';
import {Result,PageData} from "../../common/entity/result";
import { ScadaInfo } from '../../common/entity/scada';
import styles from './index.module.less';

import Hashids from 'hashids';
import LZString from 'lz-string';

const hashids = new Hashids();


interface ScadaProps {
}

const Scada: React.FC<ScadaProps> = (props: ScadaProps) => {
  // const history = useHistory();

  // 全局状态依赖
  // const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const routerParams = useParams<RouterParams>();
  const projectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams]);
  const [scadas, setScadas] = useState<ScadaInfo[]>([]);
  const [currentScada, setCurrentScada] = useState<ScadaInfo>();
  const [scadaDetail, setScadaDetail] = useState<ScadaInfo>();
  const [htmlStr, setHtmlStr] = useState<string | null>();


  useEffect(() => {
    getList();
  }, [projectId]);

  useEffect(() => {
    if (scadas.length > 0) {
      setCurrentScada(scadas[0]);
    } else {
      setHtmlStr(undefined);
    }
  }, [scadas]);

  useEffect(() => {
    if (currentScada) {
      getScadaInfo();
    }
  }, [currentScada]);

  useEffect(() => {
    if (scadaDetail && scadaDetail.strContent) {
      //解码
      const decodeResult = LZString.decompressFromBase64(scadaDetail.strContent);
      setHtmlStr(decodeResult);
    }
  }, [scadaDetail]);
  /**
  * 获取scada列表
  */
  const getList = () => {
    dispatch({type: 'show_loading', data: true});
    sendRequest<ScadaInfo[]>({
      url: `${RequestPath.GET_SCADA_LIST}?pjInfoId=${projectId}`,
      method: 'GET',
    }).then((result: Result<ScadaInfo[]>) => {
      dispatch({type: 'show_loading', data: false});

      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setScadas(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
      dispatch({type: 'show_loading', data: false});

    });
  }
  /**
  * 获取scada详情
  */
   const getScadaInfo = () => {
    sendRequest<ScadaInfo>({
      url: `${RequestPath.GET_ONE_SCADA_VIEW}?scadaId=${currentScada?.intScadaId}`,
      method: 'GET',
    }).then((result: Result<ScadaInfo>) => {
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setScadaDetail(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }
  return (
    <div className={styles.root}>
      {
        htmlStr ? (
          <div className={styles.content} dangerouslySetInnerHTML={{__html: htmlStr ?? ''}} />
        ) : (
            <div className={styles.empty}>
              <Empty description="此工程无组态图" />
            </div>
        )
      }
    </div>
  );
};

export default Scada;
