import React, {RefObject} from "react";
import * as echarts from 'echarts/core';
import {
  LineChart,
  LineSeriesOption,
} from 'echarts/charts';
import {
  TooltipComponent,
  TooltipComponentOption,
  TitleComponent,
  TitleComponentOption,
  AxisPointerComponent,
  AxisPointerComponentOption,
  LegendComponent,
  LegendComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  GridComponent,
  GridComponentOption,
  LegendScrollComponent,
  MarkPointComponent,
  MarkPointComponentOption,
} from 'echarts/components';
import {
  CanvasRenderer
} from 'echarts/renderers';

import {getOption} from './line.option';
import styles from './line.module.less';
import {LinePanelProps, LinePanelState} from "./line.types";
import {Empty} from "antd";
import ResizeObserver from 'resize-observer-polyfill';

export type ECOption = echarts.ComposeOption<
  TooltipComponentOption | LineSeriesOption | TitleComponentOption | GridComponentOption | AxisPointerComponentOption | LegendComponentOption | ToolboxComponentOption | MarkPointComponentOption
  >;

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, AxisPointerComponent, LegendComponent, ToolboxComponent, LegendScrollComponent, MarkPointComponent]
);

class LinePanel extends React.Component<LinePanelProps, LinePanelState> {
  chart: RefObject<HTMLDivElement>;
  resizeTimeout?: NodeJS.Timeout;
  resizeObserver?: ResizeObserver;

  constructor(props: LinePanelProps) {
    super(props);
    this.state = {};
    this.chart = React.createRef();
  }

  componentDidMount() {
    this.drawChart();
    if (this.chart.current !== null) {
      this.resizeObserver = new ResizeObserver(() => {
        this.chartResize();
      });
      this.resizeObserver?.observe(this.chart.current);
    }
  }

  componentDidUpdate(prevProps: Readonly<LinePanelProps>) {
    if (prevProps.lineData !== this.props.lineData) {
      this.drawChart();
    }
  }

  componentWillUnmount() {
    if (this.resizeTimeout !== undefined && this.resizeTimeout !== null) {
      clearTimeout(this.resizeTimeout);
    }
    if (this.chart.current !== null && this.resizeObserver !== undefined) {
      this.resizeObserver?.unobserve(this.chart.current);
    }
    // window.removeEventListener('resize', this.chartResize);
  }

  chartResize = () => {
    const {myChart} = this.state;
    if (this.resizeTimeout !== undefined) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.drawChart();
      const $chart = this.chart.current;
      if (myChart !== undefined && $chart !== null) {
        myChart.resize();
      }
    }, 200);
  }

  drawChart() {
    const {lineData} = this.props;
    const $chart = this.chart.current;
    if ($chart !== null) {
      let myChart = echarts.getInstanceByDom($chart);
      if (myChart === undefined) {
        echarts.dispose($chart);
        myChart = echarts.init($chart, undefined, {});
      }

      this.setState({
        myChart,
      });

      const option = getOption(lineData);
      myChart.setOption(option, true);
    }
  }

  render() {
    const {lineData} = this.props;
    return (lineData?.length ?? 0) > 0 && lineData?.every(data => (data?.data?.length ?? 0) > 0) ? <div className={styles.main} id={'line-chart'} ref={this.chart}/> : <div className={styles.empty}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14} description={'暂无数据'}/></div>;
  }
}

export default LinePanel;
