import { FC, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button, Empty, message, Space, Tooltip, Table, Divider, Spin
} from 'antd';
import { RequestPath, sendRequest } from "../../api/http";
import { LineChartOutlined, TableOutlined, PlusSquareOutlined} from '@ant-design/icons';
import moment from 'moment';
import {RouterParams} from '../app/app.routers';
import {Result} from "../../common/entity/result";

import styles from './index.module.less';
import { DataForm, DataParam } from './entitys'
import MenuCard from './MenuCard';
import DataCard from './dataCard';
import DrawPage from './drawPage';
import Hashids from 'hashids';
const hashids = new Hashids();
interface DataBoardProps {
}

const DataBoard: FC<DataBoardProps> = (props: DataBoardProps) => {
  const history = useHistory();
  // 全局状态依赖
  // const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const routerParams = useParams<RouterParams>();
  const location = useLocation();
  const type = location.pathname.startsWith('/pj')? 'pro' : 'dev';
  const projectId = useMemo(() => {
    return hashids.decode(routerParams?.projectId ?? '').toString();
  }, [routerParams]);

  const deviceId = useMemo(() => {
    return hashids.decode(routerParams?.deviceId ?? '').toString();
  }, [routerParams]);
  const [displayType, setDisplayType] = useState<'chart' | 'table'>('chart');

  const [menuCards, setMenuCards] = useState<DataForm[]>([]);
  const [currentDataForm, setCurrentDataForm] = useState<DataForm>();
  const [curDataFormDetail, setCurDataFormDetail] = useState<DataForm>();
  const [editDataForm, setEditDataForm] = useState<DataForm>();
  const [loadingCard, setLoadingCard] = useState<boolean>(false);

  
  // 新增 编辑
  const [visible, setVisible] = useState<boolean>(false);


  useEffect(() => {
    getMenuCards();
  }, [routerParams]);

  useEffect(() => {
    if (!currentDataForm && menuCards.length > 0) {
      setCurrentDataForm(menuCards[0]);
    } else if (currentDataForm &&
      menuCards.length > 0 &&
      !menuCards.some(m => m.intRealTimeFormId === currentDataForm.intRealTimeFormId)) {
        // 当前选择form已经不存在
        setCurrentDataForm(menuCards[0]);
    } else if (menuCards.length === 0) {
      setCurrentDataForm(undefined);
    }
  }, [menuCards]);

  useEffect(() => {
    if (currentDataForm) {
      getDataCards();
    } else {
      setCurDataFormDetail(undefined);
    }
  }, [currentDataForm]);

  /**
  * 定时刷新
  */
  useEffect(() => {
    const timerId = setInterval(() => {
      getDataCards();
    }, 1000 * 60);
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    }
  }, [currentDataForm,type])

  /**
  * 获取菜单
  */
  const getMenuCards = () => {
    dispatch({type: 'show_loading', data: true});
    sendRequest<DataForm[]>({
      url: `${RequestPath.GET_REAL_DATA_FORMS}?targetid=${type === 'pro' ? projectId : deviceId }&type=${type === 'pro' ? 'pro' : 'show'}`,
      method: 'GET',
    }).then((result: Result<DataForm[]>) => {
      dispatch({type: 'show_loading', data: false});
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setMenuCards(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      dispatch({type: 'show_loading', data: false});

      message.error('系统出现异常，请联系系统管理员');
    });
  }
  /**
  * 获取数据
  */
  const getDataCards = () => {
    if (!currentDataForm) {
      return;
    }
    setLoadingCard(true);
    sendRequest<DataForm>({
      url: `${RequestPath.FLESH_REAL_DATA_FORMS}?formId=${currentDataForm?.intRealTimeFormId}&formType=${type === 'pro' ? 'pro' : 'show'}`,
      method: 'POST',
    }).then((result: Result<DataForm>) => {
      setLoadingCard(false);
      if (result.resCode === "ok" && result.resBody !== undefined && result.resBody !== null) {
        setCurDataFormDetail(result.resBody);
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      setLoadingCard(false);
      setCurDataFormDetail(currentDataForm);
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  const onAddAction = () => {
    setVisible(true);
  }
  const onEditAction = (dataForm: DataForm) => {
    setVisible(true);
    setEditDataForm({...dataForm});
  }


  const onClose = () => {
    setEditDataForm(undefined);
    setVisible(false);
  }

  const onSuccess = () => {
    setEditDataForm(undefined);
    setVisible(false);
    getMenuCards();
    getDataCards();
  }

  // 删除看板
  const onDeleteForm = (dataForm: DataForm) => {
    sendRequest<DataForm>({
      url: `${RequestPath.DELETE_REALDATA_FORM}?formId=${dataForm?.intRealTimeFormId}&formType=${dataForm.strType}`,
      method: 'POST',
    }).then((result: Result<DataForm>) => {
      if (result.resCode === "ok") {
        message.success('删除成功');
        getMenuCards();
      } else {
        message.error('系统出现异常，请联系系统管理员');
      }
    }).catch((error) => {
      message.error('系统出现异常，请联系系统管理员');
    });
  }

  const tryToParse = (jsonStr?: string) => {
    if (!jsonStr) {
      return undefined;
    }
    try {
      return JSON.parse(jsonStr);
    } catch (error) {
      return undefined;
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.content_header}>
          <div className={styles.titleWrap}>
            <Tooltip mouseEnterDelay={1} overlay={currentDataForm?.strViewName}>
              <div className={styles.title}>{currentDataForm?.strViewName}</div>
            </Tooltip>
          </div>
          <Space split={<Divider type="vertical" />}>
            <LineChartOutlined onClick={() => setDisplayType('chart')} className={displayType === 'chart' ?  styles.iconSel : styles.icon} />
            <TableOutlined onClick={() => setDisplayType('table')} className={displayType === 'table' ?  styles.iconSel : styles.icon} />
          </Space>
        </div>
        {displayType === "chart" ? (
          <Spin spinning={loadingCard}>
            {
              curDataFormDetail && curDataFormDetail.lsParamsInfo && curDataFormDetail.lsParamsInfo.length > 0 ? (
                <div className={styles.content_datas}>
                  {
                    curDataFormDetail?.lsParamsInfo?.map((param: DataParam) => (
                      <DataCard key={param.intRealDataFormParamsId} param={param} />
                    ))
                  }
                </div>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
            }

          </Spin>
        ) : (
          <div className={styles.content_table}>
            <Table<DataParam>
              bordered
                dataSource={curDataFormDetail?.lsParamsInfo ?? []}
                pagination={false}
            >
                <Table.Column key="no" title="NO." dataIndex='no' render={(_, record: any, index: number) => index + 1} />
                <Table.Column key="strDeviceName" title="来源设备" dataIndex='strDeviceName' />
                <Table.Column key="strParamName" title="监测点" dataIndex='strParamName' render={(_, record: DataParam) => (
                  <Tooltip mouseEnterDelay={1} overlay={record.strDescinfo}>{record.strParamName}</Tooltip>
                )} />
                <Table.Column key="strValue" title="测量值" dataIndex='strValue' render={(_, record: DataParam) => {
                  if (record.strDateTime === '0') {
                    return '--';
                  }
                  const jsonObj = tryToParse(record.strDescinfo);
                  if (jsonObj) {
                    return record.strValue ? (jsonObj[record.strValue] ?? record.strValue) : '--';
                  } else {
                    return record.strValue ? (Math.floor(Number(record.strValue) * 1000) / 1000).toString().replace(/(\.0+)$/, '') : '--';
                  }
                }} />
                <Table.Column key="strDimension" title="单位" dataIndex='strDimension'  render={(text: string) => text ?? '--'} />
                <Table.Column key="strDateTime" title="采集时间" dataIndex='strDateTime'
                  render={(text: string) => text && text !== '0' ? moment(Number(text)).format('YYYY.MM.DD HH:mm:ss') : '--'} />
            </Table>
          </div>
        )}
      </div>
      <div className={styles.menu}>
        <div className={styles.menu_list}>
          {menuCards.length > 0 ?menuCards.map((item: DataForm) => <MenuCard
            curFormId={currentDataForm?.intRealTimeFormId}
            key={item.intRealTimeFormId}
            dataForm={item}
            onClick={(dataForm: DataForm) => { setCurrentDataForm(dataForm) }}
            onEdit={onEditAction}
            onDelete={onDeleteForm}
          />) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}


        </div>
        <div className={styles.menu_bottom}>
          <Button onClick={onAddAction} icon={<PlusSquareOutlined />} type="primary">新增看板</Button>
        </div>
      </div>
      <DrawPage
        visible={visible}
        onClose={onClose}
        onSuccess={onSuccess}
        dataForm={editDataForm}
      />
    </div>
  );
};

export default DataBoard;
