import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import styles from './realtime-report.project.module.less';
import Search from "antd/es/input/Search";
import {Button, Drawer, Empty, message, Popconfirm, Spin, Table, Tabs, Tooltip} from "antd";
import {AreaChartOutlined, FileAddOutlined, TableOutlined} from '@ant-design/icons';
import {useHistory, useParams} from "react-router-dom";
import {RouterParams, Routers} from "../../app/app.routers";
import Hashids from "hashids";
import {RequestPath, sendRequest} from "../../../api/http";
import {error} from "@daqo/log4js";
import {DeviceReportForm, TimeDataSet} from "../../../common/entity/report";
import {ResultCode} from "../../../common/entity/result";
import _ from 'lodash';
import {ColumnsType} from "antd/lib/table/interface";
import Tools from "../../../utils/tools";
import ChartPanel from "../../../component/report/realtime-report/chart-panel";
import ReportSetter from "../../../container/report/realtime-report-setter.container";
import {SplitType} from "../../../component/report/realtime-report/report-setter";
import {DeviceInfo} from "../../../common/entity/device";

const hashids = new Hashids();

const {TabPane} = Tabs;

const basicColumns: ColumnsType<TimeDataSet> = [
  {
    title: '时间',
    dataIndex: 'time',
    fixed: 'left',
    width: 200,
    render: (value, record) => {
      return `${record?.dateTime ?? '--'}${/^\d{4}-\d{2}-\d{2} \d{2}$/.test(record?.dateTime ?? '') ? '时' : ''}`;
    }
  }
]

const dateTypeMapper: { [key: string]: string } = {
  'year': '年',
  'month': '月',
  'day': '日',
  'diydate': '自',
}

export interface RealtimeReportParams extends RouterParams {
  reportId?: string,
}

export interface RealtimeReportProps {
  setLoading: (showLoading: boolean) => void,
  device?: DeviceInfo,
}

interface ReportSetterStatus {
  show: boolean,
  current?: DeviceReportForm,
}

const RealtimeReportDevice: FC<RealtimeReportProps> = (props) => {
  const routerParams = useParams<RealtimeReportParams>();
  const history = useHistory();
  const [reportForms, setReportForms] = useState<DeviceReportForm[]>([]);
  const [formSearch, setFormSearch] = useState<string>('');
  const [showType, setShowType] = useState<string>('table');
  const [reportData, setReportData] = useState<TimeDataSet[]>([]);
  const dataInterval = useRef<NodeJS.Timeout>();
  const [columns, setColumns] = useState<ColumnsType<TimeDataSet>>(basicColumns);
  const [reportSetter, setReportSetter] = useState<ReportSetterStatus>({
    show: false,
  });
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const {setLoading, device} = props;

  const getReportInfo = useCallback(() => {
    if (routerParams.deviceId !== undefined) {
      const deviceId = hashids.decode(routerParams.deviceId);
      setLoading(true);
      sendRequest<DeviceReportForm[]>({
        url: `${RequestPath.GET_REPORT_INFO}?targetid=${deviceId}&type=show`,
      }).then(result => {
        setLoading(false);
        if (result.resCode === ResultCode.OK && result.resBody !== undefined) {
          setReportForms(result.resBody);
        } else {
          setReportForms([]);
          message.error('无法获取报表数据，请联系系统管理员');
        }
      }).catch(err => {
        setLoading(false);
        message.error('无法获取报表数据，请联系系统管理员');
        error(err);
      })
    }
  }, [routerParams.deviceId]);

  useEffect(() => {
    getReportInfo();
  }, [getReportInfo]);

  useEffect(() => {
    if (routerParams?.reportId === undefined && reportForms?.length > 0 && routerParams?.projectId !== undefined && routerParams?.nodeId !== undefined && routerParams?.deviceId !== undefined) {
      history.push(`/dev/${routerParams?.projectId}/${routerParams?.nodeId}/${routerParams?.deviceId}/${Routers.REAL_REPORT}/${hashids.encode(_.head(reportForms)?.intReportId ?? '')}`);
    }
  }, [reportForms])

  const getReportData = useCallback(() => {
    if (routerParams?.reportId !== undefined) {
      const reportId = hashids.decode(routerParams?.reportId ?? '')
      setLoading(true);
      setDataLoading(true);
      sendRequest<TimeDataSet[]>({
        url: `${RequestPath.FRESH_REPORT_DATA}?reportId=${reportId}&reportType=show`,
      }).then(result => {
        setLoading(false);
        setDataLoading(false);
        if (result.resCode === ResultCode.OK && result?.resBody !== undefined) {
          setReportData(result?.resBody);
        } else {
          setReportData([]);
          message.error('无法获取报表数据，请联系系统管理员');
        }
      }).catch(err => {
        setLoading(false);
        setDataLoading(false);
        message.error('无法获取报表数据，请联系系统管理员');
        error(err);
      })
    }
  }, [routerParams?.reportId])

  useEffect(() => {
    if (dataInterval?.current !== undefined) {
      clearInterval(dataInterval?.current);
    }
    setReportData([]);
    getReportData();
    dataInterval.current = setInterval(() => {
    }, 300_000);
    return () => {
      if (dataInterval?.current !== undefined) {
        clearInterval(dataInterval?.current);
      }
    }
  }, [getReportData]);

  useEffect(() => {
    if (reportForms?.length > 0 && routerParams?.reportId !== undefined) {
      const reportId = hashids.decode(routerParams?.reportId ?? '').toString();
      const report = _.head(reportForms?.filter(form => form?.intReportId === reportId));
      const columns: ColumnsType<TimeDataSet> = [...basicColumns];
      report?.lstDeviceParams?.forEach(param => {
        const dataId = `${param?.strMongoDbOffset}_${param?.intKindId}_${param?.intDeviceid}`;
        columns.push({
          title: param?.strCustomName,
          dataIndex: dataId,
          onHeaderCell: () => {
            return {
              style: {
                whiteSpace: 'nowrap',
              }
            };
          },
          render: (value, record) => {
            const data = _.head(record?.lstValue?.filter(value => `${value?.strDetailId}_${value?.strEventTypeName}_${value?.strDeviceId}` === dataId));
            const val = Tools.renderConstValue(data?.strValue ?? '--', data?.strDimension);
            return <Tooltip title={val} mouseEnterDelay={0.5}>{val}</Tooltip>;
          }
        })
      })
      setColumns(columns);
    }
  }, [routerParams?.reportId, reportForms]);

  const deleteReport = (formId?: string) => {
    if (formId !== undefined) {
      props.setLoading(true);
      sendRequest<string>({
        url: `${RequestPath.DELETE_REPORT_INFO}?formId=${formId}`,
        method: 'POST',
      }).then(result => {
        props.setLoading(false);
        if (result?.resCode === ResultCode.OK) {
          message.success('成功删除一条实时报表');
          if (hashids.decode(routerParams?.reportId ?? '').toString() === formId) {
            history.push(`/dev/${routerParams?.projectId}/${routerParams?.nodeId}/${routerParams?.deviceId ?? ''}/${Routers.REAL_REPORT}/`);
            getReportInfo();
          } else {
            getReportInfo();
          }
        } else {
          message.error('无法删除此实时报表，请联系系统管理员');
        }
      }).catch(err => {
        props.setLoading(false);
        message.error('无法删除此实时报表，请联系系统管理员');
        error(err);
      });
    }
  }

  const exportReport = (event: React.MouseEvent<HTMLSpanElement>, form: DeviceReportForm) => {
    event.preventDefault();
    event.stopPropagation();
    if (form?.intReportId === undefined) {
      return;
    }
    const a = document.createElement('a');
    a.download = `报表_${form?.strReportName ?? '--'}.csv`;
    const title = ['时间间隔'];
    form?.lstDeviceParams?.forEach(param => {
      title.push(param?.strCustomName ?? '--');
    })
    let dlText = title.join(',') + '\n';
    let tempContent = [];
    setLoading(true);
    sendRequest<TimeDataSet[]>({
      url: `${RequestPath.FRESH_REPORT_DATA}?reportId=${form?.intReportId}&reportType=show`,
    }).then(result => {
      setLoading(false);
      if (result.resCode === ResultCode.OK && result?.resBody !== undefined) {
        const reportData = result?.resBody;
        if (reportData?.length > 0) {
          reportData?.forEach(data => {
            tempContent = [data?.dateTime ?? '--'];
            data?.lstValue?.forEach(d => {
              tempContent.push(d?.strValue ?? '--');
            });
            dlText = dlText + tempContent.join(',') + '\n';
          })
          dlText = encodeURIComponent(dlText);
          a.href = `data:text/csv;charset=utf-8,\ufeff${dlText}`;
          a.click();
        } else {
          message.warn('未找到此报表包含的数据');
        }
      } else {
        message.error('无法获取报表数据，请联系系统管理员');
      }
    }).catch(err => {
      setLoading(false);
      message.error('无法获取报表数据，请联系系统管理员');
      error(err);
    });
  }

  const currentReport = useMemo(() => {
    const reportId = hashids.decode(routerParams?.reportId ?? '').toString();
    return _.head(reportForms?.filter(form => form?.intReportId === reportId));
  }, [routerParams.reportId, reportForms]);

  const cancelDraw = () => {
    const currentId = reportSetter?.current?.intReportId;
    setReportSetter({
      show: false,
    });
    getReportInfo();
    const reportId = hashids.decode(routerParams?.reportId ?? '').toString();
    if (currentId === reportId) {
      getReportData();
    }
  }

  return <div className={styles.main}>
    <div className={`${styles.panel} ${styles.main_data_panel}`}>
      <div className={`${styles.panel_title} ${styles.panel_title}`}>
        <div className={styles.panel_title_text}><Tooltip
          title={currentReport?.strReportName ?? ''} placement={'topLeft'}
          mouseEnterDelay={0.5}>{currentReport?.strReportName ?? ''}</Tooltip></div>
        <Tabs activeKey={showType} onChange={(key) => setShowType(key)}>
          <TabPane tab={<AreaChartOutlined className={styles.panel_title_icon}/>} key="chart"/>
          <TabPane tab={<TableOutlined className={styles.panel_title_icon}/>} key="table"/>
        </Tabs>
      </div>
      <div className={styles.data_content}>
        {
          (currentReport?.lstDeviceParams?.length ?? 0) > 0 ?
            (showType === 'table' ? <Table columns={columns} dataSource={reportData} pagination={false} loading={dataLoading}/> :
              (dataLoading ? <div className={styles.center_wrap}><Spin/></div> : <ChartPanel reportData={reportData} deviceParams={currentReport?.lstDeviceParams ?? []}/>)) :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
        }
      </div>
    </div>
    <div className={`${styles.panel} ${styles.main_data_item}`}>
      <div className={styles.panel_title}><Search placeholder={'请输入报表名称'} onSearch={(value) => setFormSearch(value)}/>
      </div>
      <div className={styles.content}>
        {
          reportForms !== undefined && reportForms.length > 0 ? reportForms?.filter(form => formSearch === '' || (form?.strReportName?.indexOf(formSearch) ?? -1) > -1)?.map((form, index) => (
            <div
              onClick={() => history.push(`/dev/${routerParams?.projectId}/${routerParams?.nodeId}/${routerParams?.deviceId}/${Routers.REAL_REPORT}/${hashids.encode(form?.intReportId ?? '')}`)}
              className={`${styles.content_item} ${currentReport?.intReportId === form?.intReportId ? styles.active : ''}`}
              key={`${form?.intReportId}_${index}`}>
              <div className={styles.title}>
                <div className={styles.title_date_type}>{dateTypeMapper[form?.strReportType ?? ''] ?? '--'}</div>
                <div className={styles.title_report_title}><Tooltip title={form?.strReportName ?? '--'}
                                                                    mouseEnterDelay={0.6}>{form?.strReportName ?? '--'}</Tooltip>
                </div>
              </div>
              <div className={styles.menu_group}>
                <span className={styles.menu_group_menu} onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setReportSetter({
                    show: true,
                    current: form,
                  });
                }}>编辑</span>
                <span className={styles.menu_group_menu} onClick={(event) => exportReport(event, form)}>导出</span>
                <Popconfirm
                  title="确定要删除此报表吗?"
                  onConfirm={(event) => {
                    if (event !== undefined) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                    deleteReport(form.intReportId);
                  }}
                  onCancel={(event) => {
                    if (event !== undefined) {
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  okText="是"
                  cancelText="否"
                >
                <span className={`${styles.menu_group_menu} ${styles.danger}`} onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}>删除</span>
                </Popconfirm>
              </div>
            </div>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={styles.font_size_14}/>
        }
      </div>
      <div className={styles.btn}>
        <Button onClick={() => setReportSetter({
          show: true,
        })} type="primary" icon={<FileAddOutlined/>}>新增报表</Button>
      </div>
    </div>
    <Drawer visible={reportSetter.show} title={'实时报表'} onClose={() => setReportSetter({
      show: false,
    })} width={600} destroyOnClose={true}>
      <ReportSetter type={'dev'} dev={device} current={reportSetter.current} exitDraw={cancelDraw} cancelDraw={() => {
        setReportSetter({
          show: false,
        })
      }}/>
    </Drawer>
  </div>
}

export default RealtimeReportDevice;
