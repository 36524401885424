import {bindActionCreators, Dispatch} from 'redux';
import {connect, RootStateOrAny} from 'react-redux';
import {setLoading} from "../../redux/loading/loading.action";
import RealtimeReportProject from "../../pages/report/realtime-report/realtime-report.project";

const mapStateToProps = (state: RootStateOrAny) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setLoading,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RealtimeReportProject);
