import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';
import reduxThunk from 'redux-thunk';

import appReducer from '../app/app.reducer';
import loginReducer from '../login/login.reducer';
import loadingReducer from '../loading/loading.reducer';

const reducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  loading: loadingReducer,
});

const middleWares = [
  reduxThunk,
];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// eslint-disable-next-line no-underscore-dangle
// @ts-ignore
const composeEnhancers = process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

export default createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleWares)),
);
