import { FC } from 'react';
import {Tooltip, Space, Divider, Typography, Popconfirm} from 'antd';
import { DataForm } from '../entitys'

import styles from './index.module.less';
interface MenuCardProps {
  dataForm: DataForm;
  curFormId?: number;
  onClick?: (dataForm: DataForm) => void;
  onDelete?: (dataForm: DataForm) => void;
  onEdit?: (dataForm: DataForm) => void;
}

const MenuCard: FC<MenuCardProps> = (props: MenuCardProps) => {
  const selected = props.dataForm.intRealTimeFormId === props.curFormId;
  return (
    <div onClick={() => props.onClick?.(props.dataForm)} className={styles.root}>
      <div className={selected ? styles.markSel : styles.mark} />
      <div className={styles.titleWrap}>
        <Tooltip mouseEnterDelay={1} overlay={props.dataForm.strViewName}>
          <div className={selected ? styles.titleSel : styles.title}>{props.dataForm.strViewName}</div>
        </Tooltip>
      </div>
      <div className={styles.actions}>
        <Space split={<Divider style={{margin: 0}} />} direction="vertical">
          <Typography.Link onClick={(e) => {
            e.stopPropagation();
            props.onEdit?.(props.dataForm);
          }} >编辑</Typography.Link>
          <Popconfirm
            title="删除确认"
            onConfirm={(e) => {
              props.onDelete?.(props.dataForm);
              e?.stopPropagation();
            }}
            onCancel={(e) => e?.stopPropagation()}
            okText="确认"
            cancelText="取消"
          >
            <Typography.Link onClick={(e) => e.stopPropagation()} type="danger">删除</Typography.Link>
          </Popconfirm>
        </Space>
      </div>
    </div>
  );
};

export default MenuCard;
