import {ECOption, ReliabilityData} from "./reliability";
import * as echarts from 'echarts/core';
import Tools from "../../../../utils/tools";

export const getOptions = (data: ReliabilityData[]): ECOption => {
  return {
    legend: {
      show: false
    },
    toolbox: {
      show: false
    },
    tooltip: {
      show: false,
      trigger: 'item',
      formatter: "{b} {c}"
    },
    grid: {
      top: 25,
      left: 5
    },
    calculable: true,
    xAxis: [
      {
        splitLine: {show: false},
        splitArea: {show: false},
        axisTick: {show: false},
        type: 'value',
        min: "0",
        max: "100",
        axisLabel: {
          show: true,
          color: '#999',
          fontSize: Tools.remRadio(16),
        },
        axisLine: {
          lineStyle: {
            color: '#37393a'
          }
        }
      }
    ],
    yAxis: [
      {
        axisLine: {show: false},
        splitArea: {show: false},
        axisTick: {show: false},
        type: 'category',
        data: []
      }
    ],
    series: [
      {
        name: '',
        type: 'bar',
        color: 'rgba(0,26,77,0.5)',
        barWidth: Tools.remRadio(6),
        barGap: '-100%',
        barCategoryGap: '40%',
        data: [100, 100, 100, 100, 100],
        animation: false,
        silent: true,
      },
      {
        name: '',
        type: 'bar',
        barWidth: Tools.remRadio(6),
        data: data.map((d, i) => ({
          ...d,
          value: (parseFloat(d.value + '') * (0.995 - i * 0.005 * Math.random())).toFixed(1)
        })).sort((d1, d2) => parseFloat(d1.value) - parseFloat(d2.value)),
        label: {
          show: true,
          padding: [-25, 0, 0, 0],
          position: 'insideTopLeft',
          formatter: '{text|{b}}',
          fontWeight: "bold",
          rich: {
            text: {
              fontSize: Tools.remRadio(16),
              color: "#fff"
            }
          }
        },
        itemStyle: {
          borderRadius: [0, 15, 15, 0],
          color: new echarts.graphic.LinearGradient(
            0, 0, 1, 1,
            [
              {offset: 0, color: '#0070ff'},
              {offset: 1, color: '#00e4ff'}
            ]),
          shadowOffsetY: -1,
          shadowColor: '#d3fffc',
        }
      },
      {
        name: '',
        type: 'bar',
        barWidth: Tools.remRadio(6),
        // data:[50, 90, 78, 60, 98],
        data: data.map((d, i) => ({
          ...d,
          value: (parseFloat(d.value + '') * (0.995 - i * 0.005 * Math.random())).toFixed(1)
        })).sort((d1, d2) => parseFloat(d1.value) - parseFloat(d2.value)),
        silent: true,
        label: {
          show: true,
          position: 'right',
          padding: [-2, 0, 0, 0],
          formatter: '{c}',
          color: '#dcdddc',
          fontSize: Tools.remRadio(14),
          fontWeight: "bold"
        },
        itemStyle: {
          borderRadius: [0, 15, 15, 0],
          color: new echarts.graphic.LinearGradient(
            0, 0, 1, 1,
            [
              {offset: 0, color: '#0070ff'},
              {offset: 1, color: '#00e4ff'}
            ])
        }
      }
    ]
  };
}
